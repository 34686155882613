import styled from 'styled-components';

interface CompletedProgressBarProps {
  completed: string;
}

export const ProgressBarContainer = styled.div`
  height: 2px;
  width: 100%;
`;

export const ProgressBarCompleted = styled.div<CompletedProgressBarProps>`
  height: 100%;
  background-color: ${({ color, theme }) => color || theme.brandTheme.brand100};
  width: ${({ completed }) => completed};
`;
