import styled from 'styled-components';
import Image from 'next/image';

import { Paragraph } from '@/UI/Atoms/Paragraph';
import { fontWeight, devices, applyEllipsisByNumOfLines } from '@/UI/Styles';
import { fadeIn, keyFramesFadeIn, slideIn } from '@/utils';
import { hideScrollBar } from '@/styles';
import { InitialsAvatar } from '@/UI/Molecules/InitialsAvatar';
import { Drawer } from '@/UI/Atoms/Drawer';
import { Button } from '@/UI/Atoms/Button';

export const MobileDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const ServiceText = styled(Paragraph)`
  color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
  ${({ clamp }) => applyEllipsisByNumOfLines(clamp || 2)}
`;

export const DateText = styled(Paragraph)`
  color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
  font-size: 14px;
  font-weight: ${fontWeight.light};
`;

export const ConfirmBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
`;

export const DrawerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  width: 100%;
`;

export const DrawerTextContainer = styled.div`
  width: 60%;
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const WrapperTest = styled.div<{ showBundledServices?: boolean }>`
  ${hideScrollBar()}
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  margin-bottom: 94px;
  @media ${devices['no-mobile']} {
    overflow-y: hidden;
    margin-bottom: 0px;
    min-width: 100%;
  }
  @media ${devices.XLarge} {
    width: auto;
    max-width: none;
  }
`;

export const Wrapper = styled.div<{ showBundledServices?: boolean }>`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .servicesToReserve {
    font-weight: ${fontWeight.light};
  }
  .sessionDisclaimer {
    margin-bottom: 4px;
  }
  .serviceBottomArrow {
    transition: 200ms;
    transform: ${({ showBundledServices }) => (showBundledServices ? 'rotate(180deg)' : '')};
    path {
      fill: ${({ theme }) => theme.brandTheme.brand80};
    }
    cursor: pointer;
  }
`;

export const ServicesDateSelectorContainer = styled.div<{ showBundledServices?: boolean }>`
  ${hideScrollBar()}
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  -ms-overflow-style: none;
  width: 100%;
  min-height: calc(100vh - 120px);

  @media ${devices['no-mobile']} {
    border-radius: 0 0 8px 8px;
  }

  @media ${devices.XLarge} {
    border-radius: 8px;
    min-height: auto;
  }
  .dateSelectorTitle {
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
    padding: ${({ theme }) => theme.spacing[16]};
    border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  }
`;

export const Spacer = styled.div`
  height: 94px;
  @media ${devices['no-mobile']} {
    display: none;
  }
`;

export const SeeAllDatesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  .removeProviderText {
    width: 343px;
    text-align: center;
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
  }
  .removeProvider {
    padding-top: 16px;
    color: ${({ theme }) => theme.brandTheme.brand100};
    text-decoration: underline;
  }
  @media ${devices['no-mobile']} {
    display: none;
  }
`;

export const ServiceReminder = styled.div<{ showBundledServices?: boolean }>`
  .servicesToReserveContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .servicesToReserve {
      font-weight: ${fontWeight.light};
    }

    .serviceBottomArrow {
      transition: 200ms;
      transform: ${({ showBundledServices }) => (showBundledServices ? 'rotate(180deg)' : '')};
      path {
        fill: ${({ theme }) => theme.brandTheme.brand80};
      }
      cursor: pointer;
    }
  }
  @media ${devices.XLarge} {
    padding: 0 15px;
  }

  .nextDate {
    margin-top: 4px;
    border-bottom: none;
  }
`;

export const DateSelectorContainer = styled.div`
  box-sizing: border-box;
  padding: 0px 16px;
`;

export const BundledServicesWrapper = styled.div`
  padding-top: 10px;
  padding-left: 17px;
  ${fadeIn(200)}
  ${keyFramesFadeIn}
`;

export const AccordionTitle = styled.div`
  padding-left: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[12]};
  align-items: center;
  column-gap: 15px;
  cursor: pointer;
  display: flex;
`;

export const BundledServicesTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
`;

export const BundledServiceList = styled.ul`
  p {
    margin-left: 20px;
    li {
      list-style: outside;
      display: list-item;
    }
  }
`;

export const ProviderAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const CustomInitialsAvatar = styled(InitialsAvatar)`
  height: 100%;
  width: 100%;
  border-radius: 50%;
`;

export const ImgAvatar = styled(Image)`
  border-radius: 50%;
`;

export const ServiceProviderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 16px 0px;

  @media ${devices.XLarge} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const ServiceProviderData = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  .providerSchedule {
    display: none;
  }
  @media ${devices.XLarge} {
    margin-top: 0;
    margin-left: 25px;
    .providerSchedule {
      display: flex;
    }
  }
`;

export const ButtonContainer = styled.div`
  .button_mobile_date_selection {
    background-color: ${({ theme }) => theme.brandTheme.brand100};
  }
`;
