import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AgendaProLogo from '../../../../public/assets/agendapro-logo.png';

import { GlobalContentLayout } from '@/components/';

import * as St from './AppFooter.styles';
import { Link } from '../../Atoms';

export const AppFooter: FC<{ className?: string }> = ({ className = '' }) => {
  const { t } = useTranslation();
  const companyLinks = [
    {
      text: t('FOOTER.LINK_1'),
      href: 'https://hubs.li/Q02zmmvP0',
      target: '_blank',
    },
    {
      text: t('FOOTER.LINK_2'),
      href: 'https://hubs.li/Q02zmmLG0',
      target: '_blank',
    },
    {
      text: t('FOOTER.LINK_3'),
      href: 'https://agendapro.com/cl/terminos-y-condiciones',
      target: '_blank',
    },
  ];

  return (
    <St.Container className={className}>
      <GlobalContentLayout>
        <div className="flex w-full gap-6 md:gap-20 align-top flex-wrap md:flex-nowrap">
          <a href="https://hubs.li/Q02zmh0N0">
            <img src={AgendaProLogo} alt="" className="agendaproLogo" />
          </a>

          <St.LinksWrapper>
            {companyLinks.map((_) => (
              <Link key={_.href} className="companyLink" href={_.href} size="small">
                {_.text}
              </Link>
            ))}
          </St.LinksWrapper>
        </div>
      </GlobalContentLayout>
    </St.Container>
  );
};
