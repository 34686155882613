import { FC } from 'react';
import { Check, CircleCheck } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { HeaderNotification } from '@/UI/Molecules';

import { usePageContext } from '@/hooks';
import { Paragraph, Icon } from '@/UI';
import * as St from './PostPay.styles';

export const PostPay: FC = () => {
  const { t } = useTranslation();
  const { companyOverview } = usePageContext();
  const checkList = [
    `${t('ONCE_PAYMENT_IS_FINALIZED')}.`,
    `${t('IF_PAYMENT_IS_NOT_COMPLETED')}.`,
    <div key={t('IT_IS_IMPORTANT_THAT_YOU_WAIT')}>
      <St.BoldSpan>{`${t('IT_IS_IMPORTANT_THAT_YOU_WAIT')}. `}</St.BoldSpan>
      {`${t('OUR_PROVIDER_CAN_TAKE_UP_TO')}.`}
    </div>,
    <div key={t('ANY_DOUBTS_CONTACT_US_AT')}>
      {`${t('ANY_DOUBTS_CONTACT_US_AT')}`}
      <St.BoldSpan>{companyOverview?.email}</St.BoldSpan>
      {`${t('OR_BY_PHONE_AT')}`}
      <St.BoldSpan>{companyOverview?.phone}</St.BoldSpan>
    </div>,
  ];

  return (
    <St.Wrapper>
      <HeaderNotification icon={<CircleCheck size={130} />} className="notificationBackground">
        <Paragraph size="sectionHeadline" className="title">
          TODOGETUSER, {`${t('COMPLETE_THE_PAYMENT_IN_THE_TAB')}`}
        </Paragraph>
      </HeaderNotification>
      <St.CheckListContainer>
        <Paragraph size="sectionHeadline" className="checkListTitle">{`${t('REMEMBER_THAT')}:`}</Paragraph>
        {checkList.map((item) => (
          <St.Item className="item" key={item.toString()}>
            <div>
              <Icon icon={<Check />} size="small" />
            </div>
            <Paragraph weight="light" className="itemText">
              {item}
            </Paragraph>
          </St.Item>
        ))}
      </St.CheckListContainer>
    </St.Wrapper>
  );
};
