import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock } from '@agendapro/emerald-icons';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { Paragraph } from '@/UI';

import * as St from './CharlyBanner.styles';
import { useCharlyInfo } from '@/services/services';

export const CharlyBanner: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { token } = router.query as { token: string | null };

  const { data, isLoading, isError } = useCharlyInfo(token);

  const getText = (promotionType) => {
    if (['low_demand', 'last_hour'].includes(promotionType)) {
      return (
        <St.Flex>
          <Clock size={20} />
          <Paragraph>{t('CHARLY.TAKE_THIS_OFFERS')}</Paragraph>
        </St.Flex>
      );
    }
    if (promotionType === 'lost_client') {
      return <St.SubHeadline>{t('CHARLY.MISS_YOU')}</St.SubHeadline>;
    }
    if (promotionType === 'reminder') {
      return <St.SubHeadline>{t('CHARLY.REMEMBER_YOUR_RESERVATION')}</St.SubHeadline>;
    }
    return <St.SubHeadline>{t('CHARLY.EXPIRED_TEXT')}</St.SubHeadline>;
  };

  if (!token || isError) {
    return null;
  }

  if (isLoading) {
    return <St.CharlySkeleton />;
  }

  return (
    <St.Wrapper>
      <St.CharlyContainer>
        <St.CharlyWink />
        <St.CharlyIcon size={130} />
      </St.CharlyContainer>
      <St.TextBanner>
        {data?.fullName && <St.Headline size="headline">{`${t('CHARLY.GREETING')} ${data.fullName}`}</St.Headline>}
        {getText(data?.promotionType)}
        {data?.endDate && data.promotionType !== 'expired' && (
          <Paragraph size="xsmall" weight="light">
            {`${t('CHARLY.DISCOUNT_TEXT')} ${dayjs(data?.endDate).format('DD/MM/YYYY')}`}
          </Paragraph>
        )}
        {data?.promotionType === 'expired' && <Paragraph weight="light">{t('CHARLY.SCHEDULE_APPOINTMENT')}</Paragraph>}
      </St.TextBanner>
    </St.Wrapper>
  );
};
