import React from 'react';
import { useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Skeleton } from '@/UI/Atoms';
import { SocialIconsSkeletonContainer } from './SocialIconsSkeleton.styles';

const SocialIconsSkeleton = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.xl });

  const IconSize = isDesktop ? 38 : 34;

  return (
    <SocialIconsSkeletonContainer>
      <Skeleton style={{ borderRadius: '100%', height: IconSize, width: IconSize, marginLeft: 0 }} />
      <Skeleton style={{ borderRadius: '100%', height: IconSize, width: IconSize, marginLeft: 12 }} />
      <Skeleton style={{ borderRadius: '100%', height: IconSize, width: IconSize, marginLeft: 12 }} />
    </SocialIconsSkeletonContainer>
  );
};

export default SocialIconsSkeleton;
