import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import * as Repo from './bookings.repo';
import * as Types from './bookings.types';

export const useCancelAllBookings = (opts?) => useMutation<any, AxiosError, string>(Repo.cancelAllBookings, opts);

export const useCancelBooking = (opts?) => useMutation(Repo.cancelBooking, opts);

export const useEditBooking = (opts?) =>
  useMutation<any, AxiosError, Types.EditBookingRequest>(
    ({ bookingId, booking }) => Repo.editBooking(bookingId, booking),
    opts,
  );

export const useGetReservation = (req: Types.GetBookingRequest, opts?) =>
  useQuery(['getBooking', req], () => Repo.getBooking(req), opts);

export const useBookReservation = (opts?) =>
  useMutation<Types.DoBookingResponse, AxiosError, Types.DoBookingRequest>(
    ({
      locationId,
      payment,
      hasSessions,
      trxId,
      paymentPagoFacil,
      paymentDLocal,
      bookings,
      client,
      creativeSource,
      rwgToken,
    }) =>
      Repo.bookReservation(
        locationId,
        payment,
        hasSessions,
        trxId,
        paymentPagoFacil,
        paymentDLocal,
        bookings,
        client,
        creativeSource,
        rwgToken,
      ),
    opts,
  );

export const useCheckBookingPlans = (opts?) =>
  useMutation<any, AxiosError<Types.ErrorResponse>, Types.CheckBookingRequest>(
    ({ locationId, bookings, client }) => Repo.checkBookingPlans(locationId, { bookings }, client),
    opts,
  );

export const useConfirmBooking = (confirmationCode) =>
  useQuery(['confirmBooking', confirmationCode], () => Repo.confirmBooking(confirmationCode));

export const useConfirmAllBookings = (confirmationCode) =>
  useQuery(['confirmAllBookings', { confirmationCode }], () => Repo.confirmAllBookings(confirmationCode));

export const useBookingData = (accessToken, bookingId, onSuccess?, onError?) =>
  useQuery(['getBooking', { accessToken, bookingId }], () => Repo.getBooking({ accessToken, bookingId }), {
    enabled: !!accessToken && !!bookingId,
    onSuccess,
    onError,
  });

export const useGetEditBookingData = (confirmationCode, onSuccess?, onError?) =>
  useQuery(['getEditBookingData', { confirmationCode }], () => Repo.getEditBookingData(confirmationCode), {
    onSuccess,
    onError,
  });

export const useGetReviewSummary = (locationId) =>
  useQuery(['getReviewSummary', { locationId }], () => Repo.getReviewSummary(locationId));

export const useGetCanReview = (confirmationCode) =>
  useQuery(['getCanReview', { confirmationCode }], () => Repo.getCanReview(confirmationCode));

export const usePatchReview = (opts?) =>
  useMutation<any, AxiosError, Types.PatchReviewRequest>(
    ({ stars, anonymous, reviewerName, confirmationCode, serviceName, comment }) =>
      Repo.patchReview({ stars, anonymous, reviewerName, confirmationCode, serviceName, comment }),
    opts,
  );
