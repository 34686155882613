import { FC } from 'react';

import { Paragraph } from '@/UI';
import { Step } from '../Step';
import * as St from './StepButton.styles';
import { StepButtonProps } from './types';

export const StepButton: FC<StepButtonProps> = ({ number, whiteOverlay, selected, completed, children, onClick }) => (
  <St.Wrapper selected={selected} onClick={onClick} completed={completed} disabled={whiteOverlay}>
    <St.Cover whiteOverlay={whiteOverlay} />
    <Step completed={completed} number={number} selected={selected} />
    <Paragraph weight="light">{children}</Paragraph>
  </St.Wrapper>
);
