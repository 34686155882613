/* eslint-disable no-console */
import { API_V1, API_V2 } from '@/infra/http';
import { bookingsEditAdapter } from './bookings.adapter';

import * as Types from './bookings.types';

export const bookReservation = (
  locationId: number,
  payment = null,
  hasSessions = false,
  trxId = '',
  paymentPagoFacil = '',
  paymentDLocal = '',
  bookings,
  client,
  creativeSource,
  rwgToken,
) =>
  API_V2.post(`/bookings`, {
    expiredConfirm: false,
    mp: '',
    locationId,
    payment,
    maxDiscount: 0,
    hasSessions,
    trxId,
    paymentPagoFacil,
    paymentDlocal: paymentDLocal,
    bookings,
    client,
    creativeSource,
    rwgToken,
  }).then((res) => res.data);

// no se usa el confirmationCode
export const getBooking = ({ bookingId, accessToken }: Types.GetBookingRequest) =>
  API_V2.get<Types.BookingResponse>(`/bookings/${bookingId}/${accessToken}`).then((res) => res.data);

export const getEditBookingData = (confirmationCode: string) =>
  API_V2.get<Types.EditBookingAdapterResponse>(`/bookings/edit`, { params: { confirmationCode } }).then((res) =>
    bookingsEditAdapter(res.data),
  );

export const editBooking = (bookingId, booking) =>
  API_V2.post(`/bookings/edit_booking`, {
    id: bookingId,
    booking,
  }).then((res) => res.data);

export const cancelBooking = (confirmationCode: string) =>
  API_V2.post(`/bookings/cancel_booking`, {
    confirmationCode,
  }).then((res) => res.data);

export const cancelAllBookings = (confirmationCode: string) =>
  API_V2.post(`/bookings/cancel_all_bookings`, {
    confirmationCode,
  }).then((res) => res.data);

export const confirmBooking = (confirmationCode: string) =>
  API_V2.post<Types.ConfirmBookingResponse>(`/bookings/confirm_booking`, {
    confirmationCode,
  }).then((res) => res.data);

export const confirmAllBookings = (confirmationCode: string) =>
  API_V2.post<Types.ConfirmAllBookingsResponse>(`/bookings/confirm_all_bookings`, {
    confirmationCode,
  }).then((res) => res.data);

export const createBooking = ({
  locationId,
  payment = null,
  hasSessions = false,
  trxId = '',
  paymentPagoFacil = '',
  paymentDlocal,
  bookings,
  client,
}) =>
  API_V2.post(`/bookings`, {
    expiredConfirm: false,
    mp: '',
    locationId,
    payment,
    maxDiscount: 0,
    hasSessions,
    trxId,
    paymentPagoFacil,
    paymentDlocal,
    bookings,
    client,
  }).then((res) => res.data);

export const checkBookingPlans = (locationId, bookings, client) =>
  API_V2.post(`/bookings/check_bookings`, {
    expiredConfirm: false,
    bookingGroup: null,
    mp: '',
    locationId,
    payment: 0,
    maxDiscount: '0',
    hasSessions: false,
    trxId: '',
    paymentPagoFacil: '',
    paymentDlocal: '',
    bookings,
    client,
  }).then((res) => res.data);

export const getReviewSummary = (locationId) =>
  API_V1.get(`/reviews`, { params: { location_id: locationId } }).then((res) => res.data);

export const getReviews = (locationId, page, perPage?) =>
  API_V1.get(`/reviews/detail`, { params: { location_id: locationId, per_page: perPage, page } }).then(
    (res) => res.data,
  );

export const getCanReview = (confirmationCode) =>
  API_V1.get(`/reviews/check_review/${encodeURIComponent(confirmationCode)}`).then((res) => res.data);

export const patchReview = ({ stars, anonymous, reviewerName, confirmationCode, serviceName, comment }) =>
  API_V1.patch(`/reviews/fill_review/${encodeURIComponent(confirmationCode)}`, {
    stars,
    anonymous,
    reviewer_name: reviewerName,
    service_name: serviceName,
    comment,
  }).then((res) => res.data);
