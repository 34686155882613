import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useReservation } from '@/context/ReservationsContext/ReservationsProvider';
import { usePageContext } from '@/hooks';

import { StepButton } from './StepButton/StepButton';
import { StepTrackerContainer } from './StepTracker.styles';
import { StepTrackerProps } from './StepTracker.types';
import { Step } from '@/context/ReservationsContext/ReservationContext.types';

export const StepTracker: FC<StepTrackerProps> = ({ className, isEditing }) => {
  const { t } = useTranslation();
  const { reservations, dispatch } = useReservation();
  const { isSalfa } = usePageContext();
  const {
    bookingMode,
    canChooseProvider,
    clientValidation,
    currentIndex,
    daySelected,
    hourSelected,
    isSession,
    serviceBeingReserved,
    servicesToReserve,
    servicesWithProviders,
    companyBookingInfo,
  } = reservations;
  const bundled = reservations.serviceBeingReserved?.bundled;
  const nonStandardBooking = bundled || isSession || bookingMode === 'consecutive';

  const checkSelected = (buttonStep: string) => !!(reservations.step === buttonStep);

  const dispatchStep = (step: Step) => dispatch({ type: 'SET_STEP', payload: step });

  const bundlesHaveSingleProvider = bundled
    ? servicesToReserve[currentIndex].bundle.every((e) => e.serviceProviders.length === 1)
    : servicesToReserve[currentIndex].serviceProviders.length === 1;

  const getIsStepOneCompleted = () =>
    !!(
      (bundled && Object.entries(servicesWithProviders).length === serviceBeingReserved?.bundle?.length) ||
      (isSession && (servicesToReserve[currentIndex]?.serviceProvider || canChooseProvider === 'CANT')) ||
      bookingMode === 'consecutive' ||
      !!servicesToReserve[currentIndex]?.hourSelected
    );

  const getIsStepTwoCompleted = () =>
    !!(
      (bundled && hourSelected) ||
      (isSession && servicesToReserve[currentIndex]?.hourSelected) ||
      (bookingMode === 'consecutive' && hourSelected && daySelected) ||
      (!isSession && servicesToReserve[currentIndex]?.serviceProvider) ||
      ((!isSession || bundled) && canChooseProvider === 'CANT')
    );

  const handleFirstStep = () =>
    bookingMode === 'consecutive' || isSession || bundled
      ? dispatchStep('providerSelection')
      : dispatchStep('dateSelection');

  const handleSecondStep = () => {
    if (getIsStepOneCompleted()) {
      if (nonStandardBooking) {
        dispatchStep('dateSelection');
        return;
      }
      dispatchStep('providerSelection');
    }
  };

  const handleThirdStep = () =>
    (bookingMode === 'consecutive' && hourSelected && daySelected) ||
    (servicesToReserve.every((service) => service.hourSelected && service.serviceProvider) &&
      dispatchStep('clientForm'));

  return (
    <StepTrackerContainer className={className}>
      <StepButton
        selected={nonStandardBooking ? checkSelected('providerSelection') : checkSelected('dateSelection')}
        onClick={() => handleFirstStep()}
        number="1"
        completed={getIsStepOneCompleted()}
        whiteOverlay={(canChooseProvider === 'CANT' || bundlesHaveSingleProvider) && nonStandardBooking}
      >
        {nonStandardBooking ? `${t('TAB_PROFESSIONAL')}` : `${t('TAB_DATE_AND_HOUR')}`}
      </StepButton>
      {!isSalfa && (
        <StepButton
          selected={nonStandardBooking ? checkSelected('dateSelection') : checkSelected('providerSelection')}
          onClick={() => handleSecondStep()}
          number="2"
          whiteOverlay={!nonStandardBooking && canChooseProvider === 'CANT'}
          completed={getIsStepTwoCompleted()}
        >
          {nonStandardBooking ? `${t('TAB_DATE_AND_HOUR')}` : `${t('TAB_PROFESSIONAL')}`}
        </StepButton>
      )}
      {!companyBookingInfo.clientExclusive && (
        <StepButton
          selected={checkSelected('clientForm')}
          onClick={() => handleThirdStep()}
          completed={isEditing || (clientValidation.acceptedTerms && clientValidation.isValid)}
          whiteOverlay={isEditing}
          number={isSalfa ? '2' : '3'}
        >
          {`${t('TAB_RESERVATION_DATA')}`}
        </StepButton>
      )}
    </StepTrackerContainer>
  );
};
