import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div`
  .header {
    margin: -17px -15px 0px;
  }
  @media ${devices['no-mobile']} {
    .header {
      margin: 0;
    }
  }
`;

export const HeaderContent = styled.div`
  @media ${devices.XLarge} {
    padding-right: 390px;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReadyText = styled(Paragraph)`
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.grays80};
  @media ${devices['no-mobile']} {
    margin-top: 12px;
  }
`;

export const ClientContainer = styled.div<{ isMultiple?: boolean }>`
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  border-bottom: ${({ theme, isMultiple }) => (isMultiple ? `1px solid ${theme.palette.grays20}` : null)};
  @media ${devices['no-mobile']} {
    width: 800px;
  }
`;

export const PromotionContainer = styled.div`
  background-color: ${({ theme }) => theme.tokens.color.background.brandSecondary};
  padding: ${({ theme }) => theme.spacing[24]} ${({ theme }) => theme.spacing[32]};
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme }) => `1px solid ${theme.tokens.color.border.brandSecondary}`};
  gap: 12px;
  margin-top: ${({ theme }) => theme.spacing[48]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  @media ${devices.Medium} {
    width: 800px;
  }
`;
