import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Spinner } from '..';
import { Paragraph } from '../Paragraph';
import { ButtonProps } from './types';
import { ButtonContainer } from './Button.styles';

export const Button: FC<ButtonProps> = ({
  children,
  color,
  disabledColor,
  disabled,
  className,
  onClick,
  loading,
  spinnerColor,
  fullWidth,
  type = 'button',
  id,
  isGtm,
  outline,
  ...props
}) => {
  const theme = useTheme();

  return (
    <ButtonContainer
      onClick={onClick}
      color={color}
      className={className}
      disabled={disabled}
      disabledColor={disabledColor}
      fullWidth={fullWidth}
      type={type}
      outline={outline}
      {...props}
    >
      {loading ? (
        <Spinner desktopSize="20px" size="20px" color={spinnerColor || theme.palette.white} />
      ) : (
        <Paragraph align="center" weight="light" id={id} className={isGtm ? 'click-gtm' : ''}>
          {children}
        </Paragraph>
      )}
    </ButtonContainer>
  );
};
