import { useRouter } from 'next/router';
import { notEmpty } from '@/UI';

export const useIframeRouter = (): {
  routerPush: (url: string) => void;
  routerReplace: (url: string) => void;
  formatUrl: (url: string) => string;
} => {
  const router = useRouter();
  const formatUrl = (url: string) => {
    const arr = url.split('/').filter(notEmpty);
    const newUrl = router.asPath.includes('iframe')
      ? `/${[...arr.slice(0, 1), 'iframe', ...arr.slice(1)].join('/')}`
      : url;

    return newUrl;
  };

  const routerPush = (url: string) => {
    const newUrl = formatUrl(url);

    router.push(newUrl);
  };

  const routerReplace = (url: string) => {
    const newUrl = formatUrl(url);

    router.push(newUrl);
  };

  return { routerPush, routerReplace, formatUrl };
};
