import { FC } from 'react';

import * as St from './LoadingScreen.styles';

export const LoadingScreen: FC = () => (
  <St.Wrapper>
    <St.LoaderContainer>
      <St.LoaderWrapper data-testid="agendaproLoader-primary">
        <div className="animation-wrapper">
          <div className="square transparent-square" />
          <div className="square small-red-gradient-square" />
          <div className="square small-red-gradient-square-2" />

          <svg
            className="blue-gradient-hexagon"
            width="100"
            height="100"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.654297 2.07862V9.86723C0.654297 10.532 1.19316 11.0708 1.85791 11.0708H11.2376C11.9023 11.0708 12.4412 11.6097 12.4412 12.2745V21.453C12.4412 22.1178 12.9801 22.6567 13.6448 22.6567H21.4334C22.0981 22.6567 22.637 22.1178 22.637 21.453V2.07862C22.637 1.41388 22.0981 0.87501 21.4334 0.87501H1.85791C1.19316 0.87501 0.654297 1.41388 0.654297 2.07862Z"
              fill="url(#paint0_linear_1832_11805)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1832_11805"
                x1="6.1947"
                y1="17.217"
                x2="22.104"
                y2="1.30771"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.09" stopColor="#812DF2" />
                <stop offset="0.21" stopColor="#6E3AF0" />
                <stop offset="0.72" stopColor="#2572EB" />
                <stop offset="0.95" stopColor="#0989E9" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </St.LoaderWrapper>
    </St.LoaderContainer>
  </St.Wrapper>
);
