import styled from 'styled-components';

import { NotificationModal } from '@/UI/Molecules/NotificationModal';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const ModalPlansContainer = styled(NotificationModal)`
  z-index: 10;
`;
export const ModalPlansContent = styled.div<{ initial?: boolean }>`
  text-align: ${({ initial }) => (initial ? 'initial' : 'center')};
  font-size: 13px;
  margin: 1rem 0;
`;

export const ModalPlansSection = styled.section`
  margin-bottom: 1.3rem;

  :last-child {
    margin-bottom: 0;
  }
`;

export const ParagraphContent = styled(Paragraph)<{ marginBottom?: boolean }>`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grays80};
  line-height: 17.77px;
  text-align: justify;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '1.2rem' : '0')};
`;

export const Content = styled.span``;

export const ListContainer = styled.ul`
  padding-left: 1.5rem;
`;

export const ListItem = styled.li`
  list-style-type: disc;
  font-weight: 700;

  :nth-child(even) {
    margin: 0.5rem 0;
  }
`;

export const ServicesList = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;
`;

export const DeleteElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary100};
  font-weight: 400;
  cursor: pointer;

  .deleteIcon {
    margin-right: 0.75rem;
    path {
      fill: ${({ theme }) => theme.palette.primary100};
    }
  }
`;

export const DeleteText = styled.span`
  text-decoration: underline;
`;
