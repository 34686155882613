import styled from 'styled-components';

import { devices, fontSize } from '@/UI/Styles';

export const ClientDateSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  height: fit-content;

  .labelContainer {
    display: flex;
  }

  .required-span {
    margin-left: 4px;
    color: ${({ theme }) => theme.migrateColors('icon.danger', 'alert')};
  }

  .label {
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
    font-weight: 500;
  }
  .inputWrap {
    display: flex;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.migrateColors('border.inputDefault', 'gray')};

    border-radius: ${({ theme }) => theme.spacing[8]};
    &:focus-visible {
      border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
    }
    svg {
      display: none;
    }
    > div {
      border: unset;
    }
    .calendar {
      display: flex;
      margin-left: ${({ theme }) => theme.spacing[8]};

      path {
        fill: ${({ theme }) => theme.brandTheme.brand100};
      }
    }
    input {
      border-radius: 0;
      border: 0px;
      padding: 8px 0px;
      margin-left: ${({ theme }) => theme.spacing[8]};

      ::placeholder {
        color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')};
      }

      @media ${devices['no-mobile']} {
        font-size: ${fontSize['inputs-text-desktop']};
      }
    }
  }
  .flatpickr-calendar.static {
    position: absolute !important;
  }
`;
