import { Facebook, Instagram, Website } from '@agendapro/emerald-icons';
import { FC } from 'react';
import { SocialIconsContainer } from './SocialIcons.styles';
import SocialIconsSkeleton from './SocialIconsSkeleton';
import { SocialIconProps } from './types';

export const SocialIcons: FC<SocialIconProps> = ({ className, rrss, isLoading }) => {
  const types = {
    'web alternativa': <Website className="socialIcon" size={26} />,
    instagram: <Instagram className="socialIcon" size={26} />,
    facebook: <Facebook className="socialIcon" size={26} />,
  };

  const formatHref = (href: string, name: string) => {
    if (name.toLowerCase() === 'web alternativa' && href.includes('http')) {
      return href;
    }
    if (href.toLowerCase().includes(name.toLowerCase()) && href.includes('http')) {
      return href;
    }
    return `http://${href}`;
  };

  if (isLoading) {
    return <SocialIconsSkeleton />;
  }

  if (!rrss || rrss.length === 0) {
    return null;
  }

  return (
    <SocialIconsContainer className={className} data-testid="socialIconsContainer">
      {rrss.map(
        (item) =>
          !!item.webAddress &&
          types[item.name.toLowerCase() as keyof typeof types] && (
            <a
              key={item.socialNetworkId}
              target="_blank"
              href={formatHref(item.webAddress, item.name)}
              rel="nofollow noreferrer"
              data-testid="socialNetworkHref"
            >
              {types[item.name.toLowerCase() as keyof typeof types]}
            </a>
          ),
      )}
    </SocialIconsContainer>
  );
};
