import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MobileArrowBottom, Check } from '@agendapro/emerald-icons';
import { Paragraph, Icon } from '@/UI';

import * as St from './ProviderBundleSelector.styles';
import { ServiceProviderButton } from '../ServiceProviderButton';
import { useReservation } from '@/context/ReservationsContext';
import { bundledServiceProps } from '@/services/services';

export const ProviderBundleSelector: FC<{ bundledService: bundledServiceProps; index: number; length: number }> = ({
  bundledService,
  index,
  length,
}) => {
  const { reservations, dispatch } = useReservation();
  const [selectedProvider, setSelectedProvider] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleSelectBundleProvider = (provider) => {
    const newServicesWithProviders = {
      ...reservations.servicesWithProviders,
      [bundledService.internalId as string]: { providerId: provider.id, publicName: provider.publicName },
    };

    dispatch({
      type: 'SET_SERVICES_WITH_PROVIDERS',
      payload: newServicesWithProviders,
    });
    setSelectedProvider(provider.id);
    setIsOpen(false);
  };

  return (
    <St.Wrapper>
      <St.MainButtonContainer
        isLast={!!(index + 1 === length)}
        onClick={() => setIsOpen(!isOpen)}
        data-cy="bundle_selector_button"
      >
        <St.ServiceInfoContainer>
          <St.ServiceNumber weight="light">{`${t('SERVICE')} ${index + 1} ${t('OF')} ${length}`}</St.ServiceNumber>
          <Paragraph weight="bold" size="subHeadline">
            {bundledService.name}
          </Paragraph>
        </St.ServiceInfoContainer>
        {selectedProvider ? (
          <St.Checked>
            <Icon icon={<Check size={20} />} size="xxSmall" />
          </St.Checked>
        ) : (
          <MobileArrowBottom size={20} className={isOpen ? 'rotate' : ''} />
        )}
      </St.MainButtonContainer>
      <St.ProviderButtonContainer>
        {isOpen && (
          <>
            {reservations.canChooseProvider !== 'MUST' && bundledService.serviceProviders.length > 1 && (
              <ServiceProviderButton
                name={t('FIRST_PROVIDER_AVAILABLE')}
                onClick={() => handleSelectBundleProvider({ id: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') })}
                noPreference
                isSelected={!!(selectedProvider === 0)}
                image={null}
              />
            )}
            {bundledService.serviceProviders.map((provider) => (
              <ServiceProviderButton
                key={provider.id}
                schedule=""
                name={provider.publicName}
                image={provider.image || null}
                onClick={() => handleSelectBundleProvider(provider)}
                isSelected={!!(provider.id === selectedProvider)}
              />
            ))}
          </>
        )}
      </St.ProviderButtonContainer>
    </St.Wrapper>
  );
};
