import { FC, TouchEvent, useState } from 'react';
import { Close } from '@agendapro/emerald-icons';
import { useMediaQuery } from 'react-responsive';
import { devices, Icon, Paragraph } from '../..';
import * as St from './CarouselModal.styles';
import { useLockBodyScroll } from '../../../hooks';
import { CarouselModalProps } from './types';

export const CarouselModal: FC<CarouselModalProps> = ({ handleClose, images, selectedIndex = 0 }) => {
  useLockBodyScroll();
  const [imageIndex, setImageIndex] = useState(selectedIndex);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState<'left' | 'right' | ''>('');
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (imageIndex < images.length - 1) {
      if (touchStart - touchEnd > 75) {
        handleSelect('next');
      }
    }
    if (imageIndex !== 0) {
      if (touchStart - touchEnd < 75) {
        handleSelect('previous');
      }
    }
  };
  const handleSelect = (type: 'next' | 'previous') => {
    if (type === 'next') {
      if (imageIndex < images.length - 1) {
        setImageIndex((index) => index + 1);
      }
      setSwipeDirection('right');
    }
    if (type === 'previous') {
      if (imageIndex !== 0) {
        setImageIndex((index) => index - 1);
      }
      setSwipeDirection('left');
    }
  };

  return (
    <St.Wrapper
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
    >
      <St.Header onClick={(e) => e.stopPropagation()}>
        <Paragraph>{`${imageIndex + 1}/${images.length}`}</Paragraph>
        <Icon
          data-testid="closeIcon"
          icon={<Close />}
          onClick={() => handleClose()}
          size="medium"
          className="closeIcon"
        />
      </St.Header>
      <St.ImageContainer onClick={(e) => e.stopPropagation()}>
        <button type="button" data-testid="leftButton" onClick={() => handleSelect('previous')}>
          <St.LeftArrow $start={!!(imageIndex === 0)} />
        </button>
        <St.SwipeContainer
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={() => handleTouchEnd()}
          onTouchMove={(e) => handleTouchMove(e)}
        >
          {images.map((image, index) =>
            imageIndex === index ? (
              <St.CustomNextImage
                data-testid="nextImage"
                $swipeDirection={swipeDirection}
                src={image}
                key={image}
                alt={image}
                width={isXLarge ? 500 : 350}
                height={isXLarge ? 438 : 400}
                objectFit="contain"
                quality={100}
              />
            ) : null,
          )}
        </St.SwipeContainer>
        <button type="button" data-testid="rightButton" onClick={() => handleSelect('next')}>
          <St.RightArrow $end={!!(imageIndex === images.length - 1)} />
        </button>
      </St.ImageContainer>
    </St.Wrapper>
  );
};
