import styled from 'styled-components';
import { applyEllipsisByNumOfLines } from '@/styles';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[8]};
  height: 100%;
  display: inline;
  @media ${devices['no-mobile']} {
    margin-top: ${({ theme }) => theme.spacing[12]};
  }
`;

export const DescriptionText = styled.p<{ isDescriptionShown; hasImages }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  font-size: 12px;
  line-height: 1.5;
  ${({ isDescriptionShown }) => !isDescriptionShown && '-webkit-line-clamp: 2;'};
`;

export const MoreInformation = styled.button`
  display: flex;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette.grays80};
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing[16]};
  cursor: pointer;
  p {
    font-size: 12px;
    color: ${({ theme }) => theme.palette.grays80};
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScheduleTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  font-size: 12px;
  padding: 1rem 0;
`;

export const ScheduleDateWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
`;

export const ServiceDate = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 0.2rem;
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DateSection = styled.div`
  :first-child {
    display: flex;
    flex: 0 0 5.5rem;
  }

  :last-child {
    display: flex;
    justify-content: flex-start;
  }
`;

export const Hour = styled.span`
  letter-spacing: -0.4px;

  :after {
    content: '/';
    margin: 0 4px;
  }

  :last-child {
    :after {
      content: '';
      margin: 0;
    }
  }
`;

export const BundleServiceContainer = styled.div`
  margin-top: 15px;
`;

export const BundleTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  font-size: 12px;
`;

export const ListContainer = styled.ul`
  list-style-position: inside;
  font-size: 12px;
  margin: 0px;
  margin-top: 10px;
  padding-left: 0;
  line-height: 22px;
`;

export const ListItem = styled.li`
  list-style-type: disc;
  display: list-item;
  text-align: start;
`;
