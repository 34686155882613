import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseLock, Email } from '@agendapro/emerald-icons';

import { useMutation } from 'react-query';
import { useAuthContext } from '@/hooks';
import { Button, Checkbox, Input, Modal, Paragraph, Link } from '@/UI';
import { SocialLogin } from './SocialLogin';
import { routes, validationByField } from '@/utils';
import { Content } from './LoginModal.styles';
import { login } from '@/services/user/user.repo';

export const LoginModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const { dispatch } = useAuthContext();
  const [isChecked, setIsChecked] = useState(false);
  const onSubmit = async (credentials: { email: string; password: string }) => mutation.mutate({ ...credentials });
  const mutation = useMutation(login, {
    onSuccess: (data) => {
      if (isChecked) {
        localStorage.setItem('isChecked', 'true');
        localStorage.setItem('credentials', JSON.stringify(getValues()));
      }
      if (!isChecked) {
        localStorage.removeItem('isChecked');
        localStorage.removeItem('credentials');
      }
      dispatch({ type: 'SET_USER_DATA', payload: data });
      handleClose();
    },
    onError: (_) => setErrorMessage(t('ALERT_BAD_CREDENTIALS')),
  });

  useEffect(() => {
    const credentials: {
      email: string;
      password: string;
    } = localStorage.getItem('credentials') ? JSON.parse(localStorage.getItem('credentials') || '') : null;

    if (credentials) {
      setValue('email', credentials.email, { shouldValidate: true });
      setValue('password', credentials.password, { shouldValidate: true });
      setIsChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal alertMessage={errorMessage} handleClose={handleClose}>
      <Content>
        <div className="headerText">
          <Paragraph size="headline" className="title">
            {t('LINK_TO_ACCOUNT')}
          </Paragraph>
          <Paragraph weight="light">{t('ENTER_EMAIL_AND_PASSWORD')}</Paragraph>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t('LABEL_EMAIL')}
            icon={<Email />}
            placeholder={t('PLACEHOLDER_EMAIL')}
            register={register('email', validationByField('email', t))}
            errorMsg={errors.email?.message}
            className="emailField"
            type="text"
          />
          <Input
            label={t('LABEL_PASSWORD')}
            icon={<CloseLock />}
            type="password"
            register={register('password', validationByField('password', t))}
            errorMsg={errors.password?.message}
            placeholder={t('PLACEHOLDER_PASSWORD')}
          />

          <Link className="link" href={routes.forgotPassword}>
            {t('LINK_FORGOT_YOUR_PASSWORD')}
          </Link>

          <Checkbox
            label={`${t('CHECKBOX_REMEMBER_ME')}`}
            checked={isChecked}
            onChange={() => setIsChecked((state) => !state)}
            className="rememberField"
          />

          <Button
            fullWidth
            disabled={!isValid || isSubmitting}
            loading={mutation.isLoading}
            type="submit"
            aria-label="submitButton"
            data-testid="submitButton"
          >
            {t('LOGIN')}
          </Button>
        </form>
        <SocialLogin />
        {/* <div className="registerContainer">
          <Paragraph weight="light">{t('LINK_NO_ACCOUNT_YET')}</Paragraph>
          <Link href={routes.register}>{t('NAV.REGISTER')}</Link>
        </div> */}
      </Content>
    </Modal>
  );
};
