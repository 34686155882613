import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import { Menu, MobileArrowBottom, Profile, Location } from '@agendapro/emerald-icons';
// import ReactCountryFlag from 'react-country-flag';

import { TextHeader, Icon, Paragraph, Spinner, Select } from '@/UI';
import { useLocation, useLocations } from '@/services/locations';
import { useIframeRouter, useAuthContext, usePageContext } from '@/hooks';
import { getCookie } from '@/utils';
import { LoginModal } from '../LoginModal';
import { SideBar } from './SideBar';
import { GlobalContentLayout } from '../Layouts';
import { useCurrentUser, useCurrentUserByCode } from '@/services/user';
import { NavBarProps } from './types';
import AgendaProLogo from '../../../public/assets/Logo_sitio_web.png';
import * as St from './NavBar.styles';

export const NavBar: FC<NavBarProps> = ({ hideSelectBranch = false }) => {
  const theme = useTheme();
  const router = useRouter();
  const {
    countryCode,
    locationId,
    user_code: userCode,
    // eslint-disable-next-line camelcase
  } = router.query as { countryCode: string; locationId: string; user_code?: string };
  const [userCookie, setUserCookie] = useState<string>();
  const { isLoading } = useCurrentUser(!!userCookie, userCode, (data) => {
    dispatch({ type: 'SET_USER_DATA', payload: data });
  });
  const { isLoading: isLoadingUserByCode } = useCurrentUserByCode(userCode, (data) => {
    dispatch({ type: 'SET_USER_DATA', payload: data });
  });
  const { auth, dispatch } = useAuthContext();
  const { companyOverview, queryParams } = usePageContext();
  const { routerPush, routerReplace } = useIframeRouter();
  const { data: locationsData } = useLocations({ companyId: companyOverview?.id });
  const { data: currentLocation } = useLocation(locationId);
  const [navBar, setNavbar] = useState({ isSideOpened: false, isLoginModalOpened: false });
  // const [lang, setLang] = useState('es');
  const { t, i18n } = useTranslation();
  /* const langOptions = [
    {
      leftAdornment: { component: () => <ReactCountryFlag countryCode="ES" /> },
      value: 'es',
      label: '',
    },
    {
      leftAdornment: { component: () => <ReactCountryFlag countryCode="US" /> },
      value: 'en',
      label: '',
    },
  ]; */

  const locations =
    locationsData
      ?.filter((location) => location.validLocation)
      .map((location) => ({
        value: location.id,
        label: location.locationName || '',
        leftAdornment: {
          component: Location,
          size: 20,
          color: theme.palette.grays100,
        },
      })) || [];

  const openOrCloseModal = () => {
    setNavbar((state) => ({
      ...state,
      isLoginModalOpened: !state.isLoginModalOpened,
      isSideOpened: false,
    }));
  };
  const openOrCloseSideBar = () => {
    setNavbar((state) => ({ ...state, isSideOpened: !state.isSideOpened }));
  };

  const officeBranchRedirect = () => {
    if (currentLocation?.id) {
      routerReplace(`/${countryCode}/sucursal/${currentLocation?.id}`);
    } else {
      routerReplace(`/${countryCode}`);
    }
  };

  const onChangeOfficce = (office: typeof locations[number]) => {
    routerPush(`/${countryCode}/sucursal/${office.value}${queryParams}`);
  };

  /* const handleChangeLang = (option) => {
    i18n.changeLanguage(option.value);
    setLang(option.value);
  }; */

  useEffect(() => {
    setUserCookie(getCookie('ap_authorization'));
  }, []);

  return (
    <St.MainWrapper>
      <GlobalContentLayout>
        <St.Container>
          <div className="companyConfigContainer">
            <button type="button" onClick={officeBranchRedirect} className="logoButton">
              {companyOverview?.logo ? (
                <Image
                  src={companyOverview.logo}
                  alt={t('IMG_ALT_COMPANY_LOGO')}
                  arial-label="company-logo"
                  layout="intrinsic"
                  objectFit="contain"
                  height={50}
                  width={50}
                />
              ) : (
                <Image
                  src={AgendaProLogo}
                  alt={t('IMG_ALT_COMPANY_LOGO')}
                  arial-label="company-logo"
                  layout="intrinsic"
                  objectFit="contain"
                  height={60}
                  width={60}
                />
              )}
            </button>
            <div>
              <TextHeader className="siteName" onClick={officeBranchRedirect}>
                <Paragraph size="subHeadline">{companyOverview?.name}</Paragraph>
              </TextHeader>
              {currentLocation && !hideSelectBranch && (
                <St.LocationContainer>
                  {locations?.length > 1 && currentLocation?.locationName && (
                    <St.LocationName weight="light" align="left">
                      {currentLocation.locationName}
                    </St.LocationName>
                  )}
                  {companyOverview?.isPlanSolo === false && locations.length > 1 && currentLocation?.locationName && (
                    <Select
                      options={locations}
                      onChange={(selected) => onChangeOfficce(selected)}
                      type="LINK"
                      search={locations?.length > 5}
                    >
                      <St.StyledSelectText weight="light">{`${t('CHANGE_OFFICE_BRANCH')}`}</St.StyledSelectText>
                    </Select>
                  )}
                </St.LocationContainer>
              )}
            </div>
          </div>
          <div className="navLinksContainer">
            {isLoading || isLoadingUserByCode ? (
              <Spinner desktopSize="30px" />
            ) : (
              <>
                {/* <Select
                  options={langOptions}
                  selected={langOptions.find((option) => option.value === lang)}
                  onChange={handleChangeLang}
                  type="BUTTON"
                >
                  <Paragraph weight="light" color={theme.palette.grays80}>
                    {t('LANGUAGE')}
                  </Paragraph>
            </Select> */}
                {auth.userData ? (
                  <button type="button" className="userButton" onClick={openOrCloseSideBar}>
                    <Icon icon={<Profile />} />
                    <Paragraph size="subHeadline" weight="regular" color={theme.palette.grays80}>
                      {auth.userData.firstName}
                    </Paragraph>
                    <Icon icon={<MobileArrowBottom />} size="xxSmall" className="arrowBottom" />
                  </button>
                ) : (
                  <>
                    {/* <Link href={routes.register} className="navLink">{`${t('NAV.REGISTER')}`}</Link>
                    <div className="separator" /> */}
                    <Paragraph
                      onClick={openOrCloseModal}
                      className="navLink"
                      weight="light"
                      color={theme.palette.grays80}
                    >
                      {`${t('NAV.LOGIN')}`}
                    </Paragraph>
                  </>
                )}
              </>
            )}
          </div>

          {navBar.isLoginModalOpened && <LoginModal handleClose={openOrCloseModal} />}
          {/* <St.MobileSelectContainer>
            <Select
              options={langOptions}
              selected={langOptions.find((option) => option.value === lang)}
              onChange={handleChangeLang}
              type="BUTTON"
            >
              {t('LANGUAGE')}
            </Select>
                  </St.MobileSelectContainer> */}
          <Icon
            className="openSideBtn"
            icon={<Menu />}
            size="medium"
            onClick={openOrCloseSideBar}
            data-cy="menu_icon"
          />

          {navBar.isSideOpened && <SideBar handleOpenLoginModal={openOrCloseModal} handleClose={openOrCloseSideBar} />}
        </St.Container>
      </GlobalContentLayout>
    </St.MainWrapper>
  );
};
