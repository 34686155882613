import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const Wrapper = styled.div`
  overflow: hidden;

  .categorySlider {
    .scrollbar-wrapper {
      @media ${devices.XLarge} {
        flex-direction: column;
        margin: 0;
      }
    }
  }

  @media ${devices.XLarge} {
    width: 100%;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SliderWrapper = styled.div`
  position: relative;
`;

export const SliderMobile = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  button:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const Category = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
  :not(:first-child) {
    margin-left: 0.5rem;
  }
  @media ${devices.XLarge} {
    width: 100%;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
    }
    :not(:first-child) {
      margin-left: 0;
    }
  }
  button {
    width: 100%;
    box-shadow: none;
  }
`;
