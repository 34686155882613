import React from 'react';
import propTypes from 'prop-types';
import { Close } from '@agendapro/emerald-icons';
import { useTheme } from 'styled-components';
import { Paragraph } from '../../Atoms';
import { Wrapper, Content, Header, Button } from './SimpleModal.styles';
import { useLockBodyScroll } from '@/hooks';

export const SimpleModal = ({ children, visible, title, onClose }) => {
  const theme = useTheme();

  useLockBodyScroll(!!visible);

  return (
    <Wrapper visible={visible} arial-label="SimpleModal" data-testid="simpleModal">
      <Content>
        <Header>
          <Paragraph>{title}</Paragraph>
          <Button onClick={onClose}>
            <Close size={20} color={theme.palette.grays100} />
          </Button>
        </Header>
        {children}
      </Content>
    </Wrapper>
  );
};

SimpleModal.propTypes = {
  children: propTypes.node.isRequired,
  visible: propTypes.bool,
  title: propTypes.string,
  onClose: propTypes.func,
};

SimpleModal.defaultProps = {
  visible: undefined,
  title: undefined,
  onClose: undefined,
};
