import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const MessageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 17px;
  @media ${devices['no-mobile']} {
    margin-top: 25px;
  }

  img {
    width: 119px;
    margin-bottom: 17px;
    @media ${devices['no-mobile']} {
      margin-bottom: 25px;
    }
  }
`;
