import styled, { css } from 'styled-components';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { applyFlexAndCenter } from '@/styles';
import { fadeIn, keyFramesFadeIn } from '@/utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  width: 100%;
  @media ${devices.XLarge} {
    width: 100%;
    max-width: unset;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding-bottom: ${({ theme }) => theme.spacing[60]};
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px 8px 0 0;
  padding: ${({ theme }) => theme.spacing[20]};
  border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};

  @media ${devices['no-mobile']} {
    border-radius: 0;
  }
  @media ${devices.XLarge} {
    border-radius: 8px 8px 0 0;
  }
`;

export const Content = styled.div`
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]}`};
  @media ${devices['no-mobile']} {
    display: flex;
    justify-content: center;
  }
`;

export const Title = styled(Paragraph)`
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[16]} 0 ${theme.spacing[16]}`};
  @media ${devices['no-mobile']} {
    padding: 0;
    margin: ${({ theme }) => theme.spacing[12]} auto;
    margin-top: ${({ theme }) => theme.spacing[24]};
    font-weight: 400;
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  }
`;

export const SelectCard = styled.button<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    position: relative;
    display: flex;
    box-shadow: ${isSelected
      ? `0px 0px 20px rgba(0, 0, 0, 0.15), 0 0 0 3px ${theme.brandTheme.brand100}`
      : `0px 0px 20px rgba(0, 0, 0, 0.15), 0 0 0 1px ${theme.migrateColors('background.tertiary', 'background')}`};
    background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    border-radius: ${theme.spacing[16]};
    padding: ${theme.spacing[20]} ${theme.spacing[8]};
    margin-bottom: ${theme.spacing[16]};
    transition: 200ms ease-in-out;
    @media ${devices['no-mobile']} {
      margin: 0px ${({ theme }) => theme.spacing[32]};
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 265px;
      p {
        text-align: center;
      }
    }
  `}
`;

export const ImageContainer = styled.div`
  position: relative;
  min-width: 135px;
  height: 100px;
  margin-right: ${({ theme }) => theme.spacing[12]};
  img {
    object-fit: contain;
  }
  @media ${devices.XLarge} {
    width: 220px;
    height: 160px;
  }
`;

export const DescriptionTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Description = styled(Paragraph)`
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  @media ${devices.XLarge} {
    padding: 0 ${({ theme }) => theme.spacing[16]};
  }
`;

export const CheckContainer = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  ${applyFlexAndCenter}
  top: -15px;
  right: -15px;
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  border-radius: 50%;
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.btnPrimary', 'white')};
  }
  ${fadeIn(200)}
  ${keyFramesFadeIn}
`;
export const ConfirmBtnContainer = styled.div`
  margin: auto 16px 16px 16px;
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .modeSelectionButtonNext {
    background-color: ${({ theme }) => theme.brandTheme.brand100};

    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
    }
  }
`;
