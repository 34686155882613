import styled from 'styled-components';
import { applyFlexAndCenter } from '@/styles';

export const Wrapper = styled.div`
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[8]}`};
  p {
    padding: 0px 10px;
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  }
`;

export const ActionBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.migrateColors('border.btnSecondary', 'background')};
  box-shadow: 0 2px 2px 0 rgba(57, 65, 73, 0.08);
  padding: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  ${applyFlexAndCenter}

  .deleteIcon {
    path {
      fill: ${({ theme }) => theme.migrateColors('icon.btnDestructiveSecondary', 'alert')};
    }
  }
`;
