import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Calendar,
  Clock,
  Survey,
  Home,
  MobileArrowLeft,
  MobileArrowRight,
  Pay,
  VideoCall,
} from '@agendapro/emerald-icons';

import { SummaryItem } from '@/components/SummaryItem';
import { Icon, palette, Paragraph } from '@/UI';
import { format } from '@/utils/date';

import * as St from './ServicesSummaryCards.styles';
import { useDayLocale, useFormatCurrency } from '@/hooks';
import { BookingGroupType } from '@/services/bookings';

const ServicesSummaryCards: FC<{ isEdited: boolean; services: Array<BookingGroupType>; bundled: boolean }> = ({
  services,
  isEdited,
  bundled,
}) => {
  const { t, i18n } = useTranslation();
  const fc = useFormatCurrency();
  const ref = useRef<any>(null);
  const capitalizeDate = (date: string | undefined) => {
    if (!date) {
      return null;
    }
    const split = date.split(' ');
    const capitalizeByIndex = (array, index) => `${array[index][0].toUpperCase()}${array[index].slice(1)}`;

    split[0] = capitalizeByIndex(split, 0);
    split[2] = capitalizeByIndex(split, 2);
    return split.join(' ');
  };

  const firstDay = capitalizeDate(useDayLocale(services[0].start, i18n.language.slice(0, 2), 'dddd D MMMM - h:mm a'));

  const getDuration = (start: string, end: string) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    return endDate.diff(startDate, 'minutes');
  };

  const scroll = (scrollOffset: number) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const showPrices = (service) => {
    const discountPrice = fc(service.payingPrice);
    const regularPrice = fc(service.price);

    if (!service.serviceShowPrice) {
      return <Paragraph weight="light">{t('TO_DETERMINE')}</Paragraph>;
    }

    if (service.shouldPayOnline && service.payingPrice) {
      return <Paragraph weight="light">{`${fc(discountPrice)} (${t('MUST_PAY_ONLINE')})`}</Paragraph>;
    }

    if (service.payingPrice !== service.price) {
      return (
        <>
          <Paragraph weight="light" color={palette.grays80}>
            {regularPrice}
          </Paragraph>
          <Paragraph weight="light">{!!discountPrice && `${discountPrice} (${t('PAYING_ONLINE')})`}</Paragraph>
        </>
      );
    }

    return (
      <Paragraph weight="light" color={palette.grays80}>
        {regularPrice}
      </Paragraph>
    );
  };

  const getServiceLocation = (service) => {
    if (service.videocall) {
      return undefined;
    }
    if (service.outcall) {
      return `${t('OUTCALL_SERVICE')}`;
    }
    return `${service.location}, ${service.locationAddress}`;
  };

  return (
    <St.ServicesSummaryContainer>
      {services.length === 1 && (
        <div key={services[0].id} className="singleCard">
          <SummaryItem
            itemName={`${t('ITEM_SERVICE')}`}
            itemData={services[0].serviceName}
            iconComponent={<Survey />}
          />
          <SummaryItem itemName={`${t('ITEM_DATE_AND_HOUR')}`} iconComponent={<Calendar />}>
            <Paragraph weight="light">{`${firstDay}`}</Paragraph>
          </SummaryItem>
          {services[0].serviceShowDuration && (
            <SummaryItem
              iconComponent={<Clock />}
              itemName={`${t('ITEM_DURATION')}`}
              itemData={`${getDuration(services[0].start, services[0].end)} ${t('MINUTES')}`}
            />
          )}
          <SummaryItem
            itemName={`${t('ITEM_LOCATION')}`}
            itemData={getServiceLocation(services[0])}
            iconComponent={<Home />}
          >
            {services[0].videocall && (
              <div className="videoConference">
                <Icon icon={<VideoCall />} size="medium" />
                <Paragraph weight="light">{`${t('VIDEO_CONFERENCE')}`}</Paragraph>
              </div>
            )}
          </SummaryItem>
          {!isEdited && !bundled && !services[0].isSession && (
            <SummaryItem itemName={`${t('ITEM_PRICE')}`} iconComponent={<Pay />}>
              {showPrices(services[0])}
            </SummaryItem>
          )}
        </div>
      )}
      {services.length > 1 && (
        <div className="carrousel">
          {services.length > 3 && (
            <Icon icon={<MobileArrowLeft />} className="arrowLeft" onClick={() => scroll(-325)} size="large" />
          )}
          <div className="cardsContainer" ref={ref}>
            {services.map((service, index) => (
              <div key={service.id} className="multipleCard">
                <div className="serviceNumber">
                  <Paragraph weight="light">
                    {service.hasSession ? `${t('SESSION')} ` : `${t('SERVICE')} `}
                    {index + 1}
                  </Paragraph>
                </div>
                <div>
                  <Paragraph size="subHeadline" weight="bold" className="serviceName">
                    {service.serviceName}
                  </Paragraph>
                </div>
                <SummaryItem itemName={`${t('ITEM_DATE_AND_HOUR')}`} iconComponent={<Calendar />}>
                  <Paragraph weight="light">
                    {`${capitalizeDate(format(service.start, i18n.language, 'dddd D MMMM h:mm a'))}`}
                  </Paragraph>
                </SummaryItem>
                {service.serviceShowDuration && (
                  <SummaryItem
                    iconComponent={<Clock />}
                    itemName={`${t('ITEM_DURATION')}`}
                    itemData={`${getDuration(service.start, service.end)} ${t('MINUTES')}`}
                  />
                )}
                <SummaryItem
                  itemName={`${t('ITEM_LOCATION')}`}
                  itemData={getServiceLocation(service)}
                  iconComponent={<Home />}
                >
                  {service.videocall && (
                    <div className="videoConference">
                      <VideoCall size={32} />
                      <Paragraph weight="light">{`${t('VIDEO_CONFERENCE')}`}</Paragraph>
                    </div>
                  )}
                </SummaryItem>
                {!isEdited && !bundled && !service.isSession && (
                  <SummaryItem itemName={`${t('ITEM_PRICE')}`} iconComponent={<Pay />}>
                    {showPrices(service)}
                  </SummaryItem>
                )}
              </div>
            ))}
          </div>
          {services.length > 3 && (
            <Icon icon={<MobileArrowRight />} className="arrowRight" onClick={() => scroll(325)} size="large" />
          )}
        </div>
      )}
    </St.ServicesSummaryContainer>
  );
};

export default ServicesSummaryCards;
