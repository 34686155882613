import { Calendar, Metrics, Profile, Stop } from '@agendapro/emerald-icons';
import { TFunction } from 'react-i18next';

import { ENV } from '@/config/env';

export const routes = {
  bookingSummary: `:countryCode/bookings/:bookingId/:accessToken/:edited`,
  cancelMultipleBookings: `:countryCode/bookings/cancel_all/:confirmationCode`,
  cancelSingleBooking: `:countryCode/bookings/cancel/:confirmationCode`,
  companyPanel: 'https://business.agendapro.com/bookings',
  confirmMultipleBookings: `:countryCode/bookings/confirm_all/:confirmationCode`,
  confirmSingleBooking: `:countryCode/bookings/confirm/:confirmationCode`,
  editBooking: `:countryCode/bookings/edit/:confirmationCode`,
  employeeServices: ':countryCode/sucursal/:locationId/profesional/:employeeId',
  forgotPassword: `${ENV.APRO_URL}/reset_password`,
  locations: ':countryCode',
  mySchedule: `${ENV.BACK_URL}/my_agenda`,
  paymentDLocal: `:countryCode/dlocal/:tokenData/:bookingId/:accessToken`,
  register: `${ENV.APRO_URL}/sign_up`,
  services: ':countryCode/sucursal/:locationId',
  tutorialsAdmin: `https://academia.agendapro.com/`,
  tutorialsUser: `https://agendapro.com/`,
};

export const getNavRoutes = (user: any, t: TFunction<'localeES'>) => {
  if (user && user.companyId) {
    return [
      {
        name: `${t('NAV.COMPANY_PANEL')}`,
        url: routes.companyPanel,
        icon: <Metrics />,
      },
      {
        name: `${t('NAV.TUTORIALS_ADMIN')}`,
        url: routes.tutorialsAdmin,
        icon: <Stop />,
      },
      {
        name: `${t('NAV.SCHEDULE_ADMIN')}`,
        url: routes.mySchedule,
        icon: <Calendar />,
      },
    ];
  }
  return [
    {
      name: `${t('NAV.SCHEDULE_USER')}`,
      url: routes.mySchedule,
      icon: <Calendar />,
    },
    {
      name: `${t('NAV.TUTORIALS_USER')}`,
      url: routes.tutorialsUser,
      icon: <Stop />,
    },
  ];
};
