import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div<{ isSelected?: boolean }>`
  button {
    margin: ${({ theme }) => theme.spacing[4]};
    padding: 0 ${({ theme }) => theme.spacing[8]};
    min-width: 75px;
    border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.brandTheme.brand100 : theme.migrateColors('background.primary', 'white')};
    transition: 150ms ease-in-out;
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.migrateColors('text.btnPrimary', 'white') : theme.migrateColors('text.default', 'gray')};
    :hover {
      background-color: ${({ theme }) => theme.brandTheme.brand100};
      color: ${({ theme }) => theme.migrateColors('text.btnPrimary', 'white')};
      transition: 150ms ease-in-out;
    }
  }
`;
