import { FC, useState, useRef, useEffect } from 'react';
import { View, ViewLocked } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useMediaQuery } from 'react-responsive';
import { Icon, Paragraph, SocialIcons, devices } from '../..';
import { ViewMoreProps } from './types';
import ViewMoreSkeleton from './ViewMoreSkeleton';
import * as St from './ViewMore.styles';
import ReviewsInfo from '@/entries/BranchOffice/components/CompanyInfo/ReviewsInfo';

export const ViewMore: FC<ViewMoreProps> = ({
  resourceName,
  textContent,
  textContentSecondary,
  maxNumOfLines = 2,
  isLoading,
  rrss,
  companyName,
  showReviews,
  logo,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const handleClick = () => setClamped(!clamped);
  const isDesktop = useMediaQuery({ query: devices.Medium });

  useEffect(() => {
    if (containerRef?.current?.scrollHeight !== containerRef?.current?.clientHeight) {
      setShowButton(true);
    }
  }, [containerRef?.current?.scrollHeight]);

  const DescriptionText = () => (
    <St.DetailContainer
      className={`pt-2 mb-2 md:pt-4 md:mb-0 pb-0 ${clamped ? 'max-h-[46px] md:max-h-[unset]' : 'max-h-[unset]'}`}
    >
      {(textContent || textContentSecondary) && (
        <St.DescriptionContainer ref={containerRef} clamped={clamped}>
          <Paragraph weight="light">
            {!!textContent && textContent} {textContent && textContentSecondary && '\n'}
            {!!textContentSecondary && textContentSecondary}
            {showButton && !clamped && (textContent || textContentSecondary) && (
              <button type="button" onClick={handleClick}>
                <St.ViewMoreLabel color={palette.textLink}>{t('READ_LESS')}</St.ViewMoreLabel>
              </button>
            )}
          </Paragraph>
        </St.DescriptionContainer>
      )}
      {showButton && clamped && (textContent || textContentSecondary) && (
        <button
          type="button"
          onClick={handleClick}
          className={`absolute bottom-0 right-0 ${logo ? 'bg-[#fafafa]' : 'bg-white'} flex flex-row items-center gap-1`}
        >
          ...
          <St.ViewMoreLabel color={palette.textLink}>{t('READ_MORE')}</St.ViewMoreLabel>
        </button>
      )}
    </St.DetailContainer>
  );

  return (
    <St.Wrapper {...props}>
      {isLoading ? (
        <ViewMoreSkeleton />
      ) : (
        <>
          <div className="flex flex-row gap-2 md:gap-6 mt-1 md:mt-3 w-full max-h-[86px] md:max-h-[unset]">
            {logo && (
              <img
                src={logo}
                alt="logo"
                data-testid="logo"
                className="w-[86px] h-[86px] md:w-[140px] md:h-[140px] rounded-[16px]"
              />
            )}
            <div className="flex flex-col overflow-hidden w-full justify-start">
              <div className="flex flex-col-reverse md:flex-row justify-between">
                {companyName && (
                  <p className="text-[#1D1D3D] text-lg md:text-[26px] font-bold w-full line-clamp-2">{companyName}</p>
                )}
                {rrss && rrss.length > 0 && <SocialIcons rrss={rrss} className="socialIcons" isLoading={isLoading} />}
              </div>
              {showReviews && <ReviewsInfo />}

              {isDesktop && DescriptionText()}
            </div>
          </div>
          {!isDesktop && DescriptionText()}
        </>
      )}
    </St.Wrapper>
  );
};
