import img0 from './images/img_0.png';
import img1 from './images/img_1.png';
import img2 from './images/img_2.png';
import img3 from './images/img_3.png';

export default {
  v: '5.7.4',
  fr: 25,
  ip: 0,
  op: 100,
  w: 800,
  h: 432,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 249, h: 252, u: '', p: img0, e: 0 },
    { id: 'image_1', w: 296, h: 300, u: '', p: img1, e: 0 },
    { id: 'image_2', w: 677, h: 621, u: '', p: img2, e: 0 },
    { id: 'image_3', w: 1151, h: 484, u: '', p: img3, e: 0 },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: 'Estrella.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 50, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 79, s: [19] },
            { t: 100, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [244, 256, 0], ix: 2, l: 2 },
        a: { a: 0, k: [124.5, 126, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [44.559, 44.559, 100],
            },
            { t: 79, s: [49.559, 49.559, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: 'Estrella.png',
      cl: 'png',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [92] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 19, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 24, s: [75] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 39, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 45, s: [88] },
            { t: 53, s: [100] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 78, s: [0] },
            { t: 100, s: [3600] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [576, 214, 0], ix: 2, l: 2 },
        a: { a: 0, k: [124.5, 126, 0], ix: 1, l: 2 },
        s: { a: 0, k: [34.921, 34.921, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'Viento.png',
      cl: 'png',
      parent: 4,
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [166.682, 619.773, 0], ix: 2, l: 2 },
        a: { a: 0, k: [148, 150, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 80,
              s: [91.636, 91.636, 100],
            },
            { t: 84, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'Avión.png',
      cl: 'png',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [412, 242, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 8,
              s: [422, 242, 0],
              to: [0, 0, 0],
              ti: [0.995, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 20,
              s: [414.333, 236, 0],
              to: [-0.344, 0, 0],
              ti: [0.253, -0.154, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 28,
              s: [413.442, 242.242, 0],
              to: [-0.187, 0.114, 0],
              ti: [0.143, -0.067, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 42,
              s: [404.949, 242.51, 0],
              to: [-0.445, 0.207, 0],
              ti: [-1.143, 0.089, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 51,
              s: [402.383, 246.627, 0],
              to: [1.136, -0.088, 0],
              ti: [-2.248, 1.211, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 69,
              s: [406.458, 237.998, 0],
              to: [1.936, -1.043, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 80,
              s: [412, 242, 0],
              to: [-11.667, 11, 0],
              ti: [-75.333, 52.333, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 81,
              s: [342, 308, 0],
              to: [75.333, -52.333, 0],
              ti: [-87, 63.333, 0],
            },
            { t: 100, s: [864, -72, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [338.5, 310.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 84,
              s: [40.741, 40.741, 100],
            },
            { t: 100, s: [16.741, 16.741, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'nube.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [452, 72, 0],
              to: [3.333, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 14,
              s: [472, 72, 0],
              to: [0, 0, 0],
              ti: [1.333, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 32,
              s: [452, 72, 0],
              to: [-1.333, 0, 0],
              ti: [-0.333, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [464, 72, 0],
              to: [0.333, 0, 0],
              ti: [-0.333, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 71,
              s: [454, 72, 0],
              to: [0.333, 0, 0],
              ti: [-2, 0, 0],
            },
            { t: 95, s: [466, 72, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [575.5, 242, 0], ix: 1, l: 2 },
        s: { a: 0, k: [-22.216, 26.438, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: 'nube.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [628, 150, 0],
              to: [-2.667, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [612, 150, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [628, 150, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 75,
              s: [612, 150, 0],
              to: [0, 0, 0],
              ti: [-2.667, 0, 0],
            },
            { t: 100, s: [628, 150, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [575.5, 242, 0], ix: 1, l: 2 },
        s: { a: 0, k: [19.835, 19.835, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: 'nube.png',
      cl: 'png',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 25, s: [50] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 50, s: [100] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 75, s: [50] },
            { t: 100, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [218, 198, 0],
              to: [3.333, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 25,
              s: [238, 198, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 50,
              s: [218, 198, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 75,
              s: [238, 198, 0],
              to: [0, 0, 0],
              ti: [3.333, 0, 0],
            },
            { t: 100, s: [218, 198, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [575.5, 242, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 0,
              s: [36.364, 36.364, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 50,
              s: [39.364, 39.364, 100],
            },
            { t: 100, s: [36.364, 36.364, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Capa de formas 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [400, 216, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [824.897, 478.741], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 0, ix: 4 },
              nm: 'Trazado de rectángulo 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [4.448, 7.37], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transformar',
            },
          ],
          nm: 'Rectángulo 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 100,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
