import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { MobileArrowLeft } from '@agendapro/emerald-icons';
import { useRouter } from 'next/router';

import { Icon, InitialsAvatar, Paragraph } from '../../UI';
import { ScheduleMap } from './ScheduleMap/ScheduleMap';
import { ProviderDetailsProps } from './ProviderDetails.types';
import { useIframeRouter, usePageContext } from '@/hooks';
import { useServiceProviders } from '@/services/serviceProviders';
import * as St from './ProviderDetails.styles';

export const ProviderDetails: FC<ProviderDetailsProps> = ({ imageUrl, name, schedule, className, withoutReturn }) => {
  const { t } = useTranslation();
  const { companyOverview, currentLocation, queryParams } = usePageContext();
  const { routerPush } = useIframeRouter();
  const router = useRouter();
  const { countryCode } = router.query as { countryCode: string };
  const theme = useTheme();
  const { data: providers } = useServiceProviders(currentLocation?.id);

  const goToBranchLocation = () => {
    routerPush(`/${countryCode}/sucursal/${currentLocation?.id}/${queryParams}`);
  };

  if (withoutReturn) {
    return (
      <>
        <St.ProviderDetailsContainer className={className}>
          <St.RightBox>
            {imageUrl ? (
              <St.ProviderPortraitContainer>
                <St.ProviderPortrait src={imageUrl} height={80} width={80} />
              </St.ProviderPortraitContainer>
            ) : (
              <InitialsAvatar name={name} />
            )}
            <St.DataContainer>
              <Paragraph size="subHeadline" weight="bold" className="name">
                {name}
              </Paragraph>
              {schedule && <ScheduleMap schedule={schedule} />}
            </St.DataContainer>
          </St.RightBox>
        </St.ProviderDetailsContainer>
      </>
    );
  }

  return companyOverview?.isPlanSolo ? null : (
    <St.ProviderDetailsContainer className={className}>
      {providers && providers?.length > 1 && (
        <St.LeftBox>
          <Icon
            onClick={goToBranchLocation}
            className="backIcon"
            icon={<MobileArrowLeft />}
            color={theme.brandTheme.brand100}
            size="small"
          />
        </St.LeftBox>
      )}
      <St.RightBox>
        {imageUrl ? (
          <St.ProviderPortraitContainer>
            <St.ProviderPortrait src={imageUrl} height={67} width={67} />
          </St.ProviderPortraitContainer>
        ) : (
          <St.CustomInitialsAvatar name={name} />
        )}
        <St.DataContainer>
          <Paragraph size="subHeadline" weight="bold" className="name">
            {name}
          </Paragraph>
          {schedule && <ScheduleMap schedule={schedule} />}
          {providers && providers?.length > 1 && (
            <St.StyledLink onClick={goToBranchLocation}>{t('SHOW_BRANCH_LOCATION')}</St.StyledLink>
          )}
        </St.DataContainer>
      </St.RightBox>
    </St.ProviderDetailsContainer>
  );
};
