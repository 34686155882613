import styled, { css } from 'styled-components';
import { Skeleton } from '@/UI/Atoms/Skeleton';

export const StyledSkeleton = styled(Skeleton)`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border-radius: ${theme.spacing[8]};
    margin-bottom: ${theme.spacing[16]};
    height: ${theme.spacing[48]};
    width: 100%;
    @media (min-width: ${theme.breakpoints.lg}) {
      height: ${theme.spacing[48]};
    }
  `}
`;
