import { FC } from 'react';

import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from '@agendapro/emerald/v2';
import { Paragraph } from '@/UI';

import { SummaryItemProps } from './SummaryItem.types';
import { useFormatCurrency, usePageContext } from '@/hooks';
import * as St from './SummaryItem.styles';

export const SummaryItem: FC<SummaryItemProps> = ({ iconComponent, itemData, children, prices }) => {
  const { t } = useTranslation();
  const {
    palette,
    tokens: { color },
  } = useTheme();
  const { companyOverview } = usePageContext();
  const fc = useFormatCurrency();
  const regularPrice = fc(prices?.price);
  const discountPrice = fc(prices?.payingPrice);
  const onlineDownPayment = fc(prices?.onlineDownPaymentAmount || undefined);
  const companyAllowsOnlinePayment =
    companyOverview?.allowsOnlinePayment &&
    companyOverview?.onlinePaymentCapable &&
    companyOverview?.providerOnlinePayment;

  const showPrices = ({
    shouldPayOnline,
    price,
    payingPrice,
    hasCharlyPromotion,
    inPromotionRange,
    onlineDownPaymentAmount,
  }: any) => {
    if (shouldPayOnline && payingPrice && !onlineDownPaymentAmount && companyAllowsOnlinePayment) {
      return <Text type="body" color={color.text.subtle}>{`${discountPrice} (${t('MUST_PAY_ONLINE')})`}</Text>;
    }

    if (hasCharlyPromotion && inPromotionRange) {
      return (
        <Text type="body" color={color.text.subtle}>
          {discountPrice}
        </Text>
      );
    }

    if (payingPrice !== price && !hasCharlyPromotion && companyAllowsOnlinePayment) {
      return (
        <>
          <Text type="body" color={color.text.subtle} className="widthDiscount">
            {price}
          </Text>
          <Text type="body" color={color.text.subtle}>
            {!!discountPrice && `| ${discountPrice} (${t('PAYING_ONLINE')})`}
          </Text>
        </>
      );
    }

    if (onlineDownPaymentAmount && companyAllowsOnlinePayment) {
      return (
        <Text
          type="body"
          color={color.text.subtle}
          data-testid="summary_item_online_downpayment_amount"
        >{`${price} | ${onlineDownPaymentAmount} (${t('ONLINE_DOWNPAYMENT')})`}</Text>
      );
    }

    return (
      <Text type="body" color={color.text.subtle} data-testid="summary_item_price">
        {price}
      </Text>
    );
  };

  return (
    <St.Wrapper>
      <div>{iconComponent}</div>
      {itemData && (
        <Text type="body" color={color.text.subtle}>
          {itemData}
        </Text>
      )}
      {prices &&
        showPrices({
          shouldPayOnline: prices?.shouldPayOnline,
          price: regularPrice,
          payingPrice: discountPrice,
          hasCharlyPromotion: prices?.hasCharlyPromotion,
          inPromotionRange: prices?.inPromotionRange,
          onlineDownPaymentAmount: prices?.onlineDownPaymentAmount ? onlineDownPayment : null,
        })}
      {children}
    </St.Wrapper>
  );
};
