import { useQuery } from 'react-query';

import * as Repo from './locations.repo';
import * as Types from './locations.types';

export const useLocation = (locationId: string) => {
  const { data, ...rest } = useQuery(['getLocation', locationId], () => Repo.getLocation(parseInt(locationId, 10)), {
    enabled: !!locationId,
  });

  const image = data?.image;
  const isEmptyAddress = !data?.address.replace(/,/g, '').trim();
  const address = isEmptyAddress ? null : data?.address.replace(/^(,\s){1,}/, '');
  const parsedData = { ...data, address, image } as Types.LocationResponse;

  return { data: parsedData, ...rest };
};

export const useLocations = ({ companyId, onSuccess, onError }: Types.useLocationsParams) =>
  useQuery(['getLocations', companyId, onSuccess, onError], () => Repo.getLocations(companyId), {
    enabled: !!companyId,
    onSuccess,
    onError,
  });
