import styled from 'styled-components';

import { Card } from '@/UI/Atoms/Card';
import { Button } from '@/UI/Atoms/Button';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { fadeIn, keyFramesFadeIn } from '@/utils/animationModal';
import { devices, fontFamily, fontSize } from '../../Styles';

interface IdInputProps {
  showError: string | null;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: ${({ theme }) => theme.palette.grays50};
  ${fadeIn(200)};
  ${keyFramesFadeIn}
`;

export const CustomCard = styled(Card)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;
  border: 0;
  @media ${devices['no-mobile']} {
    width: 465px;
  }
  p {
    text-align: center;
  }
`;

export const CloseIconContainer = styled.div`
  margin-right: 0px;
  margin-left: auto;
  cursor: pointer;
`;

export const Title = styled(Paragraph)`
  margin-top: 15px;
  color: ${({ theme }) => theme.brandTheme.brand100};
`;

export const Text = styled(Paragraph)`
  margin-top: 30px;
  padding: 0px 10px;
  color: ${({ theme }) => theme.palette.grays80};
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 40px;
  align-items: flex-start;
  label {
    color: ${({ theme }) => theme.palette.grays80};
  }
  p {
    margin-bottom: 12px;
  }
`;

export const ErrorMsg = styled(Paragraph)`
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.alert100};
`;

export const CustomButton = styled(Button)`
  margin-top: 40px;
  padding: 15px 0px;
  width: 150px;
  p {
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: 500;
  }
`;

export const NotAClient = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.grays80};
  margin-top: ${({ theme }) => theme.spacing[40]};
`;

export const GrayParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.grays80};
`;

export const IdInput = styled.input<IdInputProps>`
  border: 0px;
  width: 100%;
  border-bottom: ${({ theme, showError }) =>
    showError ? `1px solid ${theme.palette.alert100}` : `1px solid ${theme.palette.grays50}`};
  outline: none;
  font-family: ${fontFamily};
  font-size: ${fontSize['paragraph-mobile']};
  padding-bottom: 12px;
  transition: 300ms ease-in-out;
  ::placeholder {
    color: ${({ theme }) => theme.palette.graysPlaceholder};
  }
  :focus {
    border-bottom: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
  }
  @media ${devices['no-mobile']} {
    font-size: ${fontSize['paragraph-desktop']};
  }
`;
