import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const Container = styled.footer`
  width: 100%;
  padding: 22px 20px;
  border-top: 1px solid ${({ theme }) => theme.tokens.color.border.primary};
  @media ${devices['no-mobile']} {
    padding: 28px 0;
  }

  .agendaproLogo {
    width: 130px;
    margin-top: 6px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media ${devices.XLarge} {
    margin-top: 0;
  }
  .companyLink {
    margin-top: ${({ theme }) => theme.spacing[4]};
    text-decoration: underline;
    color: ${({ theme }) => theme.migrateColors('text.btnLink', 'primaryDark700')};
    p {
      color: ${({ theme }) => theme.migrateColors('text.btnLink', 'primaryDark700')};
      font-weight: 500;
    }
  }
`;
