import styled from 'styled-components';
import { devices } from '../../Styles';
import { TextHeaderProps } from './types';

export const BaseHeader = styled.h1<Pick<TextHeaderProps, 'weight' | 'size' | 'caps' | 'onClick'>>`
  font-family: ${({ theme }) => theme.fontFamily};
  margin: 0;
  font-weight: ${({ weight, theme }) => theme.fontWeight[weight || 'bold']};
  text-transform: ${({ caps }) => (caps ? 'uppercase' : '')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  font-size: ${({ size, theme }) => theme.fontSize[`${size || 'headline'}-mobile`]};
  @media ${devices['no-mobile']} {
    font-size: ${({ size, theme }) => theme.fontSize[`${size || 'headline'}-desktop`]};
  }
`;
