import { MobileArrowLeft, MobileArrowRight } from '@agendapro/emerald-icons';
import styled from 'styled-components';
import Image from 'next/image';
import { Icon } from '@/UI/Atoms';
import { fadeIn, keyFramesFadeIn, keyFramesSlideIn, keyFramesSlideInLeft, slideIn, slideInLeft } from '../../../utils';
import { devices } from '../../Styles';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.grays80};
  z-index: 5;
  ${keyFramesFadeIn}
  ${fadeIn()}
  path,
  p {
    color: ${({ theme }) => theme.palette.white};
    fill: ${({ theme }) => theme.palette.white};
  }
`;

export const CustomNextImage = styled(Image)<{ $swipeDirection: 'left' | 'right' | '' }>`
  ${keyFramesSlideIn};
  ${keyFramesSlideInLeft};
  ${({ $swipeDirection }) => ($swipeDirection === 'right' ? slideIn : null)};
  ${({ $swipeDirection }) => ($swipeDirection === 'left' ? slideInLeft : null)};
  @media ${devices['no-mobile']} {
    ${fadeIn(200)}
  }
`;

export const LeftArrow = styled(MobileArrowLeft)<{ $start: boolean }>`
  cursor: ${({ $start }) => ($start ? 'unset' : 'pointer')};
  margin-right: 25px;
  display: none;
  path {
    transition: 300ms ease-in-out;
    visibility: ${({ $start }) => ($start ? 'hidden' : 'visible')};
    fill: ${({ theme, $start }) => ($start ? theme.palette.grays20 : theme.palette.white)};
  }
  @media ${devices['no-mobile']} {
    display: flex;
  }
`;

export const RightArrow = styled(MobileArrowRight)<{ $end: boolean }>`
  cursor: ${({ $end }) => ($end ? 'unset' : 'pointer')};
  margin-left: 25px;
  display: none;
  path {
    transition: 300ms ease-in-out;
    visibility: ${({ $end }) => ($end ? 'hidden' : 'visible')};
    fill: ${({ theme, $end }) => ($end ? theme.palette.grays20 : theme.palette.white)};
  }
  @media ${devices['no-mobile']} {
    display: flex;
  }
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 45px auto auto auto;
  padding: 0px 25px;
  z-index: 6;
  @media ${devices['no-mobile']} {
    width: 500px;
    padding: 0px;
    margin: auto;
    margin-bottom: 0;
  }
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  margin: auto;
  margin-top: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 6;
  @media ${devices['no-mobile']} {
    margin-top: 0;
  }
`;

export const SwipeContainer = styled.div`
  position: relative;
  display: flex;
  overflow-x: visible;
  width: 100%;
  height: 400px;
  width: 350px;
  @media ${devices['no-mobile']} {
    height: 438px;
    width: 500px;
  }
`;
