import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, Check, Clock, Delete, CreditCard, Edit, Profile } from '@agendapro/emerald-icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useReservation } from '@/context/ReservationsContext';
import { usePageContext, useShowComponent, useFormatCurrency } from '@/hooks';
import * as St from './ServiceSelector.styles';
import { AlertModal, Paragraph } from '@/UI';
import { deleteServiceFromBooking, formatAMPMHour } from '@/utils';
import { ServiceReservationProps } from '@/context/ReservationsContext/ReservationContext.types';

dayjs.extend(isBetween);
const getServingProviderName = (service, servicesWithProviders) => {
  if (service.bundled) {
    const providers = service.bundle.map((service) => servicesWithProviders[`${service.internalId}`]);

    return providers.every((item) => item === undefined) ? null : providers.map((provider) => provider?.publicName);
  }

  return servicesWithProviders[`${service.internalId}`]?.publicName;
};

const handleSelectService = ({ service, dispatch }) => {
  dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
  return dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: service });
};

const Consecutive: FC = () => {
  const { t } = useTranslation();
  const { reservations } = useReservation();
  const { servicesToReserve, servicesWithProviders, currentIndex } = reservations;
  const currentService = servicesToReserve[currentIndex];
  const servicesNames = servicesToReserve.map((service) => service.name).join(', ');
  const serviceNameWithProviderName = Object.entries(servicesWithProviders)
    .flatMap((entry) => ({
      internalId: entry[0],
      serviceName: servicesToReserve.find((service) => service.internalId === entry[0])?.name,
      publicName: entry[1].publicName,
    }))
    .sort(
      (a, b) =>
        servicesToReserve.findIndex((service) => service.internalId === a.internalId) -
        servicesToReserve.findIndex((service) => service.internalId === b.internalId),
    );

  return (
    <St.Wrapper isSelected={false} disabled>
      <St.TitleContainer>
        <St.Title>{servicesNames}</St.Title>
      </St.TitleContainer>
      {currentService?.dateFormatted && (
        <St.Data>
          <Calendar size={20} />
          <Paragraph weight="light">{currentService?.dateFormatted}</Paragraph>
        </St.Data>
      )}
      {currentService?.hourSelected && (
        <St.Data>
          <Clock size={20} />
          <Paragraph weight="light">
            {currentService?.hourSelected
              ? `${formatAMPMHour(currentService?.hourSelected)} ${t('TO')} ${formatAMPMHour(currentService?.hourEnd)}`
              : `${currentService?.duration} ${t('ABBREVIATED_MINUTES')}`}
          </Paragraph>
        </St.Data>
      )}
      {serviceNameWithProviderName.map((service) => (
        <St.Data key={service.internalId}>
          <div>
            <Profile size={20} />
          </div>
          <Paragraph weight="light">
            <b>{`${service.serviceName}: `}</b>
            {service.publicName}
          </Paragraph>
        </St.Data>
      ))}
    </St.Wrapper>
  );
};

const Separate: FC<{
  service: ServiceReservationProps;
  isEligible: boolean;
  isSelected: boolean;
}> = ({ service, isEligible, isSelected }) => {
  const { t } = useTranslation();
  const { isSalfa } = usePageContext();
  const { reservations, dispatch } = useReservation();
  const fc = useFormatCurrency();
  const { hide, show, isShown } = useShowComponent();
  const {
    charlyPromotionEndDate,
    charlyPromotionStartDate,
    dateFormatted,
    daySelected,
    discount,
    duration,
    hourEnd,
    hourSelected,
    name,
    onlinePayable,
    payingPrice,
    payingPriceCharly,
    price,
    serviceProvider,
    showDuration,
    showPrice,
  } = service;
  const { currentIndex, handleCloseNow, isEditing, isSession, servicesToReserve, servicesWithProviders, step } =
    reservations;
  const formattedPrice = fc(price);
  const formattedPayingPrice = fc(payingPrice);
  const formattedCharlyPrice = fc(payingPriceCharly);
  const providerNames = getServingProviderName(service, servicesWithProviders);
  const isComplete = !!hourSelected && !!serviceProvider;

  const getShownPrice = () => {
    const charlyInRange = dayjs(daySelected).isBetween(charlyPromotionStartDate, charlyPromotionEndDate, 'day', '[]');

    if (payingPriceCharly && charlyInRange) {
      return (
        <Paragraph weight="light">
          <St.Crossed>{formattedPrice}</St.Crossed>
          {` | ${formattedCharlyPrice}`}
        </Paragraph>
      );
    }
    if (discount && onlinePayable) {
      return (
        <Paragraph weight="light">
          <St.Crossed>{formattedPrice}</St.Crossed>
          {` | ${formattedPayingPrice} ${t('PAYING_ONLINE')}`}
        </Paragraph>
      );
    }
    return <Paragraph weight="light">{formattedPrice}</Paragraph>;
  };

  return (
    <St.Wrapper isSelected={isSelected} disabled={!isEligible}>
      <St.Content>
        <St.Main
          onClick={() => (isSelected || !isEligible ? null : handleSelectService({ service, dispatch }))}
          data-testid="service-select-button"
          data-cy="service_select_button"
        >
          <St.TitleContainer>
            {isComplete && (
              <St.Completed>
                <Check size={16} />
              </St.Completed>
            )}
            <St.Title>{isSession && service === servicesToReserve[0] ? `${t('SESSION')} 1` : name}</St.Title>
          </St.TitleContainer>
          {showPrice && !isEditing && !isSession && !!price && (
            <St.Data>
              <CreditCard size={20} />
              {getShownPrice()}
            </St.Data>
          )}
          {dateFormatted && (
            <St.Data>
              <Calendar size={20} />
              <Paragraph weight="light">{dateFormatted}</Paragraph>
            </St.Data>
          )}
          <St.Data>
            {showDuration ? (
              <>
                <Clock size={20} />
                <Paragraph weight="light">
                  {hourSelected
                    ? `${formatAMPMHour(hourSelected)} ${t('TO')} ${formatAMPMHour(hourEnd)}`
                    : `${duration} ${t('ABBREVIATED_MINUTES')}`}
                </Paragraph>
              </>
            ) : (
              <>
                {hourSelected && (
                  <>
                    <Clock size={20} />
                    <Paragraph weight="light">{formatAMPMHour(hourSelected)}</Paragraph>
                  </>
                )}
              </>
            )}
          </St.Data>
          {!isSalfa && (
            <>
              {servicesToReserve[currentIndex]?.bundled ? (
                providerNames?.map((name, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <St.Data key={index}>
                    {name && <Profile size={20} />}
                    <Paragraph weight="light">{name}</Paragraph>
                  </St.Data>
                ))
              ) : (
                <St.Data>
                  {providerNames && <Profile size={20} />}
                  <Paragraph weight="light">{providerNames}</Paragraph>
                </St.Data>
              )}
            </>
          )}
        </St.Main>
        <St.ActionsContainer>
          {!isEditing && servicesToReserve.length > 1 && (
            <St.Delete
              onClick={(e) => {
                e.stopPropagation();
                show();
              }}
              data-testid="service-delete"
              data-cy="service_delete"
            >
              <Delete className="deleteIcon" size={20} />
            </St.Delete>
          )}
          {isComplete && (
            <St.Data
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
                dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: service });
              }}
              data-cy="service_edit"
            >
              <Edit size={20} />
              <St.EditText weight="light">{t('EDIT')}</St.EditText>
            </St.Data>
          )}
        </St.ActionsContainer>
      </St.Content>
      {isShown && (
        <AlertModal
          title={t('DELETE_SERVICE_ALERT_TITLE')}
          description={t('DELETE_SERVICE_ALERT_DESCRIPTION')}
          onConfirm={() => {
            deleteServiceFromBooking({
              dispatch,
              length: servicesToReserve.length,
              handleClose: handleCloseNow,
              service,
            });
            hide();
          }}
          onCancel={hide}
          showCancelButton
        />
      )}
    </St.Wrapper>
  );
};

const StartingSelector: FC<{ service: ServiceReservationProps }> = ({ service }) => {
  const { t } = useTranslation();

  return (
    <St.Wrapper isSelected={false} disabled>
      <St.TitleContainer>
        <St.Title>{service.name}</St.Title>
      </St.TitleContainer>
      {service.showDuration && (
        <St.Data>
          <Clock size={20} />
          <Paragraph weight="light">{`${service.duration} ${t('ABBREVIATED_MINUTES')}`}</Paragraph>
        </St.Data>
      )}
    </St.Wrapper>
  );
};

const ServiceSelector: FC = () => {
  const { reservations } = useReservation();
  const { servicesToReserve, isSession, currentIndex, bookingMode, step } = reservations;
  const checkIsEligible = () =>
    (currentIndex === -1 ||
      !!(servicesToReserve[currentIndex]?.hourSelected && servicesToReserve[currentIndex]?.serviceProvider)) &&
    step !== 'modeSelection';

  if (step === 'modeSelection') {
    return (
      <>
        {servicesToReserve.map((service) => (
          <StartingSelector key={service.internalId} service={service} />
        ))}
      </>
    );
  }

  if (bookingMode === 'consecutive') {
    return <Consecutive />;
  }
  return (
    <>
      {servicesToReserve.map((service: ServiceReservationProps, index: number) => (
        <Separate
          service={service}
          key={service.internalId}
          isEligible={isSession ? false : checkIsEligible()}
          isSelected={currentIndex === index}
        />
      ))}
    </>
  );
};

export default ServiceSelector;
