import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { Button } from '@/UI/Atoms/Button';
import { Drawer } from '@/UI/Atoms/Drawer';
import { hideScrollBar } from '@/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  width: 100%;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media ${devices['no-mobile']} {
    border-radius: 0 0 8px 8px;
  }
  @media ${devices.XLarge} {
    border-radius: 8px;
    max-height: calc(100vh - 150px);
  }
  .title {
    z-index: 1;
    box-sizing: border-box;
    border-bottom: 1px solid 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
    word-wrap: break-word;
    padding: ${({ theme }) => theme.spacing[20]};
  }
  .serviceName {
    display: none;
    @media ${devices.XLarge} {
      display: flex;
    }
  }
`;

export const Content = styled.div<{ consecutiveMode: boolean }>`
  background-color: ${({ theme }) => theme.migrateColors('background.secondary', 'background50')};
  padding: ${({ theme }) => theme.spacing[16]};
  padding-right: ${({ theme }) => theme.spacing[16]};
  overflow-y: scroll;

  @media ${devices['no-mobile']} {
    height: 100%;
    max-height: 400px;
  }
  @media ${devices.XLarge} {
    max-height: calc(100% - 110px);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
`;

export const ButtonsContainer = styled.div<{ consecutiveMode?: boolean }>`
  > button:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  }
  @media ${devices.XLarge} {
    padding: 0px;
    padding-top: 1px;
  }
`;

export const StepWrapper = styled.div`
  @media ${devices.XLarge} {
    margin-top: 16px;
  }
`;

export const ConsecutiveButtonContainer = styled.div<{ isConsecutive: boolean }>`
  position: fixed;
  bottom: 0;
  display: ${({ isConsecutive }) => (isConsecutive ? 'flex' : 'none')};
  width: 100%;
  padding: ${({ theme }) => theme.spacing[16]};
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  box-shadow: ${({ theme }) => theme.boxShadows['inverse-md']};
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const ConsecutiveButton = styled(Button)`
  justify-content: center;
  margin-top: auto;
  margin-bottom: 0px;
`;

export const MobileDrawer = styled(Drawer)`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const ConfirmButton = styled(Button)`
  margin: 0px 40px;
  padding: 12px 25px;
`;
