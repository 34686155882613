import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const ServicesSummaryContainer = styled.div`
  white-space: pre-wrap;

  .singleCard {
    width: 100%;
    max-width: 550px;
    padding: ${({ theme }) => theme.spacing[16]};
    border-radius: ${({ theme }) => theme.spacing[8]};
    border: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
    margin: ${({ theme }) => theme.spacing.lg} auto;

    .videoConference {
      display: flex;
      align-items: flex-start;
      max-height: 70vh;
    }
  }
  .carrousel {
    display: flex;
    align-items: center;
    justify-content: center;
    .arrowRight {
      display: none;
      margin-left: 40px;
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.migrateColors('icon.subtle', 'gray50')};
      }
      @media ${devices['no-mobile']} {
        display: flex;
      }
    }
    .arrowLeft {
      display: none;
      margin-right: 40px;
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.migrateColors('icon.subtle', 'gray50')};
      }
      @media ${devices['no-mobile']} {
        display: flex;
      }
    }
    .cardsContainer {
      display: flex;
      gap: ${({ theme }) => theme.spacing[16]};
      padding: 25px;
      margin-top: 45px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
      ::-webkit-scrollbar {
        display: none;
      }
      @media ${devices['no-mobile']} {
        width: 940px;
      }
      .videoConference {
        display: flex;
        align-items: flex-start;
        max-height: 70vh;
      }
      .multipleCard {
        position: relative;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.spacing[16]};

        border-radius: 8px;
        min-width: 260px;
        max-width: 325px;
        box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.15);
        @media ${devices['no-mobile']} {
          width: 100%;
        }
        .serviceNumber {
          position: absolute;
          top: 0;
          left: 0;
          background-color: ${({ theme }) => theme.brandTheme.brand100};
          color: ${({ theme }) => theme.migrateColors('text.tagNewFeature', 'white')};
          padding: 3px 13px;
          border-radius: 7px 0px 11px;
          p {
            color: ${({ theme }) => theme.migrateColors('text.tagNewFeature', 'white')};
          }
        }
        .serviceName {
          color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
          padding: 25px 0px 12px 0px;
        }
      }
    }
  }
`;
