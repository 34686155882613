import styled, { css } from 'styled-components';

import { devices, screenBreakpoints } from '@/UI/Styles';
import { Button } from '@/UI/Atoms/Button';
import { keyFramesSlideBottom, slideBottom } from '../../utils';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Title = styled(Paragraph)<{ isXLarge?: boolean }>`
  color: ${({ theme, isXLarge }) => (!isXLarge ? theme.palette.grays200 : theme.palette.grays100)};
`;

export const Wrapper = styled.div<{ showSearchBar: boolean; isB2C?: boolean; showsFiltering: boolean }>`
  color: ${({ theme }) => theme.palette.grays100};
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme, isB2C, showsFiltering }) => theme.spacing[isB2C || showsFiltering ? 32 : 0]};
  @media ${devices.XLarge} {
    padding-top: ${({ theme }) => theme.spacing[32]};
    flex-direction: ${({ showSearchBar }) => (showSearchBar ? 'column' : 'row')};
  }
`;

export const FilterContainer = styled.div<{ scrolled?: boolean; isRef?: boolean }>`
  background-color: ${({ theme }) => theme.palette.white};
  display: ${({ isRef }) => (isRef ? 'flex' : 'none')};
  flex-direction: row;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[12]};
  gap: 8px;
  align-items: center;
  .searchInputContainer {
    margin-bottom: 32px;
    margin-bottom: ${({ theme, isRef }) => (isRef ? theme.spacing[32] : theme.spacing[16])};
  }
  @media ${`(max-width: ${screenBreakpoints.xl})`} {
    ${({ scrolled, isRef }) =>
      scrolled &&
      !isRef &&
      css`
        display: flex;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        padding-bottom: 0px;
        position: fixed;
        top: -100px;
        left: 0;
        padding: ${({ theme }) => `0px ${theme.spacing[16]} 0px ${theme.spacing[16]}`};
        z-index: 5;
        ${slideBottom}
        ${keyFramesSlideBottom}
      `}
  }
  @media ${devices.XLarge} {
    width: 315px;
    .searchInputContainer {
      margin-bottom: 16px;
    }
    flex-direction: column;
  }
`;

export const ServicesContainer = styled.div<{ showSearchBar?: boolean }>`
  width: 100%;

  @media ${devices.XLarge} {
    margin-left: ${({ showSearchBar }) => (showSearchBar ? '0px' : '30px')};
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 25px;
  background-color: ${({ theme }) => theme.tokens.color.background.tertiary};
  border-radius: 8px;
  margin-bottom: 17px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.grays80};
  }
  @media ${devices.XLarge} {
    padding: 16px 25px;
    margin-bottom: 25px;
  }
`;

export const ServiceContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ServiceContainer = styled.div`
  margin-bottom: 17px;
  width: 100%;
  @media ${devices.Large} {
    margin-bottom: 25px;
    width: 48.87%;
  }
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  width: 100%;
`;

export const PickButton = styled(Button)`
  padding: 12px 25px;
`;

export const AdvancedFilterContainer = styled.div`
  margin: 0px 0px 0px auto;
  display: flex;
  align-items: center;
  text-decoration: underline;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
  svg {
    margin-right: 4px;
  }
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
`;

export const LoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AlertModalContainer = styled.div`
  div > p {
    color: ${({ theme }) => theme.palette.grays80};
  }
  .confirmBtn {
    background-color: ${({ theme }) => theme.brandTheme.brand100} !important;
  }
`;
