import styled from 'styled-components';
import { devices } from '../../Styles';

export const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.tokens.color.background.tertiary};
  @media ${devices['no-mobile']} {
    flex-direction: row;
  }

  .mainIcon {
    svg {
      height: 80px;
      width: 80px;
      @media ${devices['no-mobile']} {
        height: 130px;
        width: 130px;
      }
    }
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }

  .textContainer {
    margin-top: 4px;
    @media ${devices['no-mobile']} {
      margin-top: 0;
    }
    .bold {
      font-weight: bold;
    }
    .text {
      color: ${({ theme }) => theme.palette.grays80};
      margin-top: 16px;
    }
    p {
      text-align: center;
      @media ${devices['no-mobile']} {
        text-align: left;
      }
    }
  }
`;

export const IconContainer = styled.div`
  svg {
    height: 80px;
    width: 80px;
    @media ${devices['no-mobile']} {
      height: 130px;
      width: 130px;
    }
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }
`;

export const Content = styled.div`
  max-width: 950px;
  margin-top: 4px;
  @media ${devices['no-mobile']} {
    margin-top: 0;
  }
  p {
    text-align: center;
    @media ${devices['no-mobile']} {
      text-align: left;
    }
  }
`;
