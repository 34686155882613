import styled from 'styled-components';
import { applyFlexAndCenter } from '@/styles';

export const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const LoaderContainer = styled.div`
  position: relative;
  ${applyFlexAndCenter};
`;

export const LoaderWrapper = styled.div`
  /* Sizes */
  --square-size: 100px;
  --border-radius: 10px;

  --total-duration: 3s;

  /* Ease function */
  --animation-ease: ease-in-out;

  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: scaleAll var(--total-duration) infinite var(--animation-ease);

  @keyframes scaleAll {
    0%,
    8.333333333333332% {
      transform: scale(1);
    }
    25.0%,
    66.66666666666666% {
      transform: scale(1.5);
    }
    83.33333333333334%,
    100% {
      transform: scale(1);
    }
  }

  .animation-wrapper {
    position: relative;
    width: 100px;
    height: 100px;

    .square {
      width: var(--square-size);
      height: var(--square-size);
      border-radius: var(--border-radius);
      position: absolute;
      top: 0;
      left: 0;
    }

    .small-red-gradient-square {
      background: linear-gradient(45deg, #fa23c3 8.63%, #fd5782 56.31%, #ff7a59 90.84%);
      transform: scale(0.5);
      transform-origin: bottom left;
      animation: redSquare var(--total-duration) infinite var(--animation-ease);
      z-index: 2;
      top: 0;
      left: 0;
    }

    @keyframes redSquare {
      0%,
      8.333333333333332% {
        transform: scale(0.5);
      }
      25.0%,
      41.666666666666664% {
        transform: scale(1);
        opacity: 1;
      }
      41.66666666666667% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    .small-red-gradient-square-2 {
      background: linear-gradient(45deg, #fa23c3 8.63%, #fd5782 56.31%, #ff7a59 90.84%);
      transform: scale(0);
      transform-origin: bottom left;
      animation: redSquare2 var(--total-duration) infinite var(--animation-ease);
      z-index: 4;
      top: 0;
      left: 0;
    }

    @keyframes redSquare2 {
      20%,
      30% {
        transform: scale(0);
      }
      45%,
      100% {
        transform: scale(0.5);
        opacity: 1;
      }
    }

    .blue-gradient-hexagon {
      animation: blueHexagon var(--total-duration) infinite var(--animation-ease);
      transform-origin: top right;
      top: -25px;
      left: 25px;
      position: absolute;
    }

    @keyframes blueHexagon {
      0%,
      8.333333333333332% {
        transform: scale(1);
      }
      25.0%,
      41.66666666666667% {
        transform: scale(0);
      }
      58.333333333333336%,
      100% {
        transform: scale(1);
      }
    }

    .transparent-square {
      transform: scale(0);
      z-index: 3;
      transform-origin: bottom left;
      background-size: cover;
      border-radius: 0 10px 0 0;
      background-color: #f3f3f3;
      border: 1px solid transparent;
      animation: trasnparentSquare var(--total-duration) infinite;
    }

    @keyframes trasnparentSquare {
      0%,
      25.0% {
        transform: scale(0);
        opacity: 1;
      }

      41.66666666666667% {
        transform: scale(1);
        opacity: 1;
      }
      58.333333333333336%,
      100% {
        opacity: 0;
      }
    }
  }
`;
