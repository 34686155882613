import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalUnavailableBookingDateProps } from './types';

import * as St from './styles';

import { useReservation } from '@/context/ReservationsContext';

export const ModalUnavailableBookingDate: FC<ModalUnavailableBookingDateProps> = ({ bookingServices, showModal }) => {
  const { t } = useTranslation();
  const { reservations } = useReservation();
  const { servicesToReserve } = reservations;

  const unavailableServices = bookingServices?.unavailable.map((service) => {
    const serviceToReserve = servicesToReserve.find((item) => item.id === service.serviceId);

    return serviceToReserve;
  });

  const handleConfirm = () => {
    window.location.reload();
  };

  if (!showModal || !bookingServices) {
    return null;
  }

  return (
    <St.Container
      type="warning"
      handleConfirm={handleConfirm}
      hideCancelButton
      confirmButtonText={t('BUTTON_BOOK_AGAIN')}
    >
      <St.Content>
        <St.ParagraphContent size="mdSmall">{t('UNAVAILABLE_BOOKING_DATE_SERVICES')}</St.ParagraphContent>
        <St.ListWrapper>
          {unavailableServices?.map((service) => (
            <St.Item key={service?.internalId}>{service?.name}</St.Item>
          ))}
        </St.ListWrapper>
      </St.Content>
    </St.Container>
  );
};
