import { RefObject, useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const useCheckRefScrolled = (ref: RefObject<HTMLDivElement> | null) => {
  const [scrolled, setScrolled] = useState(false);

  useLayoutEffect(() => {
    const updatePosition = () => {
      const yPos = ref ? ref.current?.getBoundingClientRect().y : 0;

      if (yPos && yPos < -200) {
        setScrolled(true);
      }

      if (yPos && yPos > 100) {
        setScrolled(false);
      }
    };
    const debouncedCheck = debounce(updatePosition, 100);

    window.addEventListener('scroll', debouncedCheck);
    return () => window.removeEventListener('scroll', debouncedCheck);
  }, [ref]);
  return scrolled;
};

export default useCheckRefScrolled;
