import { Check } from '@agendapro/emerald-icons';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph } from '../Paragraph';
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from './Checkbox.styles';
import { CheckboxProps } from './types';

export const Checkbox: FC<CheckboxProps> = ({ register, className, checked = true, label, onChange, ...props }) => {
  const { palette } = useTheme();

  return (
    <CheckboxContainer className={className}>
      <label className="checkboxLabel" htmlFor={label}>
        <HiddenCheckbox type="checkbox" {...register} checked={checked} {...props} id={label} onChange={onChange} />
        <StyledCheckbox checked={checked}>{checked && <Check size={16} color={palette.white} />}</StyledCheckbox>
        <Paragraph weight="light">{label}</Paragraph>
      </label>
    </CheckboxContainer>
  );
};
