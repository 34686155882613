import { useContext } from 'react';
import { PageContext } from '@/context/PageContext';

const usePageContext = () => {
  const pageContext = useContext(PageContext);

  if (pageContext === undefined || pageContext === null) {
    throw new Error('usePageContext must be used within an PageProvider');
  }

  return pageContext;
};

export default usePageContext;
