import Image from 'next/image';
import styled from 'styled-components';

import { devices } from '../../Styles';

type BannerLogoContainerProps = {
  banner?: string;
  collapsed?: boolean;
};

export const NextImage = styled(Image)`
  border-radius: 18px;
  margin: 0 !important;
  height: auto !important;
  max-height: unset !important;
  width: 100% !important;
  position: relative !important;
`;

export const Wrapper = styled.div<BannerLogoContainerProps>`
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
  }
`;

export const Logo = styled.img<BannerLogoContainerProps>`
  z-index: 2;
  border-radius: 50%;
  height: 85px;
  width: 85px;
  background-color: ${({ theme }) => theme.palette.white};
  position: absolute;
  bottom: -43px;
  left: 16px;

  @media ${devices.Medium} {
    height: ${({ collapsed }) => (collapsed ? '86px' : '140px')};
    width: ${({ collapsed }) => (collapsed ? '86px' : '140px')};
    bottom: -70px;
  }
`;

export const BannerGradient = styled.div`
  border-radius: 18px;
  background: ${({ theme }) => `linear-gradient(50deg, ${theme.brandTheme.brand50} 0%, #fff 100%)`};
  margin: 0;
  height: auto;
  width: 100%;
  position: relative;
  min-height: 185px;
  @media ${devices.Medium} {
    min-height: 320px;
  }
`;
