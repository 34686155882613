import styled from 'styled-components';

import { applyFlexAndCenter, devices, fontWeight } from '@/UI/Styles';

export const Wrapper = styled.div`
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  padding: 17px;
  p {
    color: ${({ theme }) => theme.palette.white};
  }
`;

export const WarningContainer = styled.div`
  box-sizing: border-box;
  ${applyFlexAndCenter()}
  background-color: ${({ theme }) => theme.palette.white20};
  color: ${({ theme }) => theme.palette.white};
  border-radius: 100%;

  svg {
    width: 29px;
    height: 29px;

    @media ${devices.XSmall} {
      font-size: 13.14px;
    }

    @media ${devices['no-mobile']} {
      font-size: 14px;
    }
  }
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
    font-weight: ${fontWeight.bold};

    @media ${devices['no-mobile']} {
      margin-left: 12px;
    }
  }
`;

export const ContactContainer = styled.div`
  margin-top: 10px;
  padding-left: 37px;

  svg {
    margin-right: 8px;

    @media ${devices['no-mobile']} {
      margin-right: 15px;
    }
  }

  p {
    font-weight: ${fontWeight.light};
    font-size: 0.875rem;
  }

  @media ${devices['no-mobile']} {
    padding-left: 41px;
  }
`;

export const ContactContent = styled.div`
  @media ${devices['no-mobile']} {
    display: flex;
    align-items: center;
  }
`;

export const Separator = styled.div`
  display: none;

  @media ${devices['no-mobile']} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 7px;
    font-size: 15px;
    margin: 0 12px;
  }
`;

export const IconLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.white};

  @media ${devices['no-mobile']} {
    margin-top: 8px;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 4px;
`;
