import styled from 'styled-components';
import { devices } from '../../Styles';

interface InputContentContainerProps {
  error?: string;
}

export const InputContainer = styled.div`
  .errorMsg {
    color: ${({ theme }) => theme.migrateColors('text.inputError', 'alert')};
    margin-top: 8px;
    margin-bottom: 0px;
  }
`;

export const InputContentContainer = styled.div<InputContentContainerProps>`
  box-sizing: border-box;
  display: flex;
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.migrateColors('border.inputError', 'alert')}`
      : `1px solid ${theme.migrateColors('border.inputDefault', 'gray')}`};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 5px;
  padding: 8px 15px;
  width: 100%;
  i {
    margin-right: 8px;
  }
  @media ${devices['no-mobile']} {
    justify-content: flex-start;
    i {
      margin-right: 4px;
    }
  }
`;
export const CustomInput = styled.input`
  width: 100%;
  border: 0;
  padding: 0;
  &::placeholder {
    color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')} !important;
  }
  &:focus {
    outline: none;
  }
`;

export const ViewPasswordContainer = styled.div`
  cursor: pointer;
  @media ${devices['no-mobile']} {
    margin-left: auto;
    margin-right: 0px;
  }
`;
