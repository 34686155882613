import { FC } from 'react';

import { useTheme } from 'styled-components';
import { Icon, Paragraph } from '@/UI';

import * as St from './SummaryItem.styles';
import { SummaryItemProps } from './SummaryItem.types';

export const SummaryItem: FC<SummaryItemProps> = ({
  icon,
  iconComponent,
  itemData,
  itemName,
  children,
  size = 'medium',
}) => {
  const { palette } = useTheme();

  return (
    <St.Wrapper>
      <Icon className={icon} size={size} icon={iconComponent} />
      <St.ItemNameContainer>
        <Paragraph weight="bold">{itemName}</Paragraph>
        {itemData && (
          <Paragraph weight="light" color={palette.grays80}>
            {itemData}
          </Paragraph>
        )}
        {children}
      </St.ItemNameContainer>
    </St.Wrapper>
  );
};
