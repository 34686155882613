import { FC } from 'react';
import { BaseHeader } from './TextHeader.styles';
import { TextHeaderProps } from './types';

export const TextHeader: FC<TextHeaderProps> = ({
  heading = 'h1',
  children,
  size = 'headline',
  weight = 'bold',
  caps = false,
  className,
  onClick,
}) => (
  <BaseHeader as={heading} size={size} weight={weight} caps={caps} className={className} onClick={onClick}>
    {children}
  </BaseHeader>
);
