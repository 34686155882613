import styled from 'styled-components';
import { devices, fontFamily, fontSize } from '../../../Styles';

interface InputContainerProps {
  gridItem: string;
}

interface CustomInputProps {
  error?: string;
}

export const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 20px;
  grid-area: ${({ gridItem }) => `${gridItem}`};
  .labelContainer {
    display: flex;
  }

  .required-span {
    margin-left: 4px;
    color: ${({ theme }) => theme.migrateColors('text.danger', 'alert')};
  }
  .errorMsg {
    color: ${({ theme }) => theme.migrateColors('text.inputError', 'alert')};
    margin-top: ${({ theme }) => theme.spacing[8]};
    margin-bottom: 0px;
  }
  p {
    padding-left: 0px;
    font-family: ${fontFamily};
    font-size: ${fontSize['inputs-label-mobile']};
    @media ${devices['no-mobile']} {
      font-size: ${fontSize['inputs-label-desktop']};
    }
  }

  .labelInput {
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  }
  .labelTextArea {
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  }
`;

export const InputContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  i {
    margin-right: 8px;
  }

  @media ${devices['no-mobile']} {
    justify-content: flex-start;
    i {
      margin-right: 4px;
    }
  }
`;
export const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  padding: 0;
  font-family: ${fontFamily};
  font-size: ${fontSize['inputs-text-mobile']};
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.migrateColors('border.inputError', 'alert')}`
      : `1px solid ${theme.migrateColors('border.inputDefault', 'gray')}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 8px;

  ::placeholder {
    color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')} !important;
  }
  :focus-visible {
    border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`} !important;
    outline: none;
  }
  @media ${devices['no-mobile']} {
    font-size: ${fontSize['inputs-text-desktop']};
  }
`;
