import { useMutation, useQuery } from 'react-query';

import * as Types from './user.types';
import * as Repo from './user.repo';

export const useCurrentUser = (hasSessionCookie: boolean, userCode?: string, onSuccess?: (data: any) => void) =>
  useQuery(['getUser'], () => Repo.getUser(), { onSuccess, enabled: hasSessionCookie && !userCode });

export const useLogin = (opts) =>
  useMutation<Types.LoginResponse, Types.LoginError, Types.LoginRequest>(['login'], Repo.login, opts);

export const useCurrentUserByCode = (userCode?: string, onSuccess?: (data: any) => void) =>
  useQuery(['getUserByCode'], () => Repo.getUserByCode({ userCode }), { onSuccess, enabled: !!userCode });
