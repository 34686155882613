export const setCookie = ({
  cname,
  cvalue,
  exdays,
  cookieDomain,
}: {
  cname: string;
  cvalue: string;
  exdays: number;
  cookieDomain: string;
}) => {
  const currentDate = new Date();

  currentDate.setTime(currentDate.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${currentDate.toUTCString()}`;

  document.cookie = `${cname}=${cvalue};${expires};path=/; domain=${cookieDomain}`;
};

export const getDomainNameForCookies = (): string => {
  /**
   * Retrieve the hostname from the current window location.
   */
  const url = window.location.hostname;

  /**
   * Use a regular expression to match and extract the domain from the hostname.
   * The regular expression extracts the domain from the hostname by removing
   * any protocol prefixes (http:// or https://), username and password components (user:password@),
   * and subdomains (www.) before capturing the domain itself.
   */
  const urlMatch = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\n]+)/im);

  /**
   * Extract the domain from the match result, or set it to an empty string if no match is found.
   */
  const domain = urlMatch ? urlMatch[1] : '';

  /**
   * Format the domain by adding a leading dot and selecting the last two segments.
   */
  const formattedDomain = `.${domain.split('.').slice(-2).join('.')}`;

  /**
   * Return the formatted domain name for setting cookies.
   */
  return formattedDomain;
};
