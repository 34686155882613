import { FC } from 'react';
import { Icon, Paragraph } from '../../../Atoms';
import { InputContainer, InputContentContainer, CustomInput } from './Input.styles';
import { ClientInputProps } from './types';

export const ClientInput: FC<ClientInputProps> = ({
  className,
  errorMsg,
  icon,
  label,
  onBlur,
  onChange,
  placeholder,
  register,
  type = 'text',
  onKeyPress,
  isGtm,
  id,
  required,
}) => (
  <InputContainer className={className} gridItem="">
    <div className="labelContainer">
      <Paragraph className="labelInput">{label}</Paragraph>
      {required && <span className="required-span">*</span>}
    </div>
    <InputContentContainer>
      {icon && <Icon icon={icon} size="small" />}
      <CustomInput
        autoComplete="off"
        data-cy={`${label?.toLowerCase()}_input`}
        data-testid={`${label?.toLowerCase()}_input`}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        onKeyPress={onKeyPress}
        error={errorMsg}
        {...register}
        className={isGtm ? 'click-gtm' : ''}
        id={id}
      />
    </InputContentContainer>
    {errorMsg && (
      <Paragraph size="small" weight="light" className="errorMsg">
        {errorMsg}
      </Paragraph>
    )}
  </InputContainer>
);
