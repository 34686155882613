import styled, { css } from 'styled-components';
import { ReactElement } from 'react';
import { devices, fontWeight } from '@/UI/Styles';
import { SizeType } from './types';
import theme from '@/UI/Theme/theme';

interface ParagraphContainerProps {
  weight: keyof typeof fontWeight;
  caps?: boolean;
  align?: string;
  color?: string;
  size: SizeType;
  as?: ReactElement;
  clamp?: number;
}

export const ParagraphContainer = styled.p<ParagraphContainerProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  margin: 0;
  font-weight: ${({ weight, theme }) => theme.fontWeight[weight]};
  text-transform: ${({ caps }) => (caps ? 'uppercase' : 'initial')};
  text-align: ${({ align }) => align};
  /* HACK: when is render by children */
  line-height: 19px;
  font-size: 14px;
  font-size: ${({ size, theme }) => theme.fontSize[`${size}-mobile`]};

  @media ${devices.XLarge} {
    font-size: ${({ size, theme }) => theme.fontSize[`${size}-desktop`]};
  }

  color: ${({ color }) => color || theme.palette.textDefault};
  ${({ clamp }) => {
    if (clamp) {
      return css`
        display: -webkit-box;
        -webkit-line-clamp: ${clamp};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
    return null;
  }}
`;
