import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useIsIframe = () => {
  const router = useRouter();
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top || router.asPath.includes('iframe');
      } catch (e) {
        return true;
      }
    };

    setIsIframe(inIframe());
  }, [router.asPath]);

  return isIframe;
};

export default useIsIframe;
