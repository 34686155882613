export const getFormatPriceByCurrency = (price, { unit, delimiter, separator }) => {
  if (price === 0) {
    return `${unit}0${delimiter}00`;
  }
  const integerIndex = 0;
  const decimalIndex = 1;
  const integerPart = price
    .toString()
    .split('.')
    [integerIndex].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  const decimalPart = price.toString().split('.')[decimalIndex];

  return price ? `${unit}${integerPart}${decimalPart ? `${separator}${decimalPart}` : ''}` : '';
};

export const getFormatTime = (duration) => {
  const hourInMinutes = 60;
  const hours = parseInt(duration / hourInMinutes, 10);
  const minutes = duration % hourInMinutes;

  return `${hours ? `${hours} hrs` : ''}${hours && minutes ? ' ' : ''}${minutes ? `${minutes} min` : ''}`;
};

export const isEllipsisApplied = ({ offsetHeight, scrollHeight }) => offsetHeight !== scrollHeight;

const unicodeNormalizationForm = 'NFD';

const removeDiacritics = (str) => str?.normalize(unicodeNormalizationForm).replace(/[\u0300-\u036f-,]/g, '');

export const validateIfTextContainsStr = (text, strToMatch) => {
  const regExp = new RegExp(removeDiacritics(strToMatch), 'gi');

  return regExp.test(removeDiacritics(text));
};

const capitalizeName = (name) => {
  if (typeof name !== 'string') {
    return '';
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const addUpperCaseToName = (name) =>
  name
    .split(' ')
    .map((eachName) => capitalizeName(eachName))
    .join(' ');

const normalizeString = (string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s/g, '')
    .toLowerCase()
    .replace(/(.)\1+/g, '$1');

export const notEmpty = (value) => ![null, undefined, '', NaN].includes(value);

export const isSearchTermSimilar = (string, searchTerm) => {
  if (!string || !searchTerm) {
    return false;
  }

  return normalizeString(string).includes(normalizeString(searchTerm));
};
