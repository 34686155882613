import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CircleWarning, Email, Phone } from '@agendapro/emerald-icons';
import { Icon, Paragraph, Link } from '@/UI';
import * as St from './InactiveNotice.styles';
import usePageContext from '@/hooks/usePageContext';
import { CompanyOverviewResponse } from '@/services/company';
import { GlobalContentLayout } from '../Layouts';

export const InactiveNotice: FC = () => {
  const { t } = useTranslation();
  const { companyOverview } = usePageContext();
  const { email, phone } = companyOverview as CompanyOverviewResponse;

  return (
    <St.Wrapper data-testid="inactiveNotice">
      <GlobalContentLayout>
        <St.MainContent>
          <St.WarningContainer>
            <Icon icon={<CircleWarning size={16} color="white" />} />
          </St.WarningContainer>
          <Paragraph size="subHeadline">
            {companyOverview?.companyIsActive ? t('NOT_TAKING_RESERVES_NOW') : t('INACTIVE_COMPANY_GET_IN_TOUCH')}
          </Paragraph>
        </St.MainContent>

        {companyOverview?.companyIsActive && (email || phone) && (
          <St.ContactContainer>
            <Paragraph>{t('DOUBTS_CONTACT_US')}</Paragraph>
            <St.ContactContent>
              {email && (
                <>
                  <St.IconLinkContainer>
                    <Icon icon={<Email size={16} color="white" />} size="small" />
                    <Link href={`mailto:${email}`} isUnderlined>
                      {email}
                    </Link>
                  </St.IconLinkContainer>
                  <St.Separator>|</St.Separator>
                </>
              )}
              {phone && (
                <St.IconLinkContainer>
                  <Icon icon={<Phone size={16} color="white" />} size="small" />
                  <Link href={`tel:${phone}`} isUnderlined>
                    +{phone}
                  </Link>
                </St.IconLinkContainer>
              )}
            </St.ContactContent>
          </St.ContactContainer>
        )}
        {!companyOverview?.companyIsActive && (
          <St.LinkContainer>
            <Paragraph>{t('GET_REGISTERED_FOR_FREE')}</Paragraph>
            <Link href="https://www.agendapro.com/" isUnderlined>
              www.agendapro.com
            </Link>
          </St.LinkContainer>
        )}
      </GlobalContentLayout>
    </St.Wrapper>
  );
};
