import styled from 'styled-components';
import { devices } from '../../Styles';

export const DrawerContainer = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing[16]};
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 0px 9px rgba(57, 65, 73, 0.08);
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
  min-height: 95px;

  @media ${devices['no-mobile']} {
    display: flex;
    justify-content: space-around;
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;
