import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { fadeIn } from '@/utils';

export const Checked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  margin-right: 8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  ${fadeIn()}
  path {
    fill: ${({ theme }) => theme.palette.white}!important;
  }
`;

export const StepNumber = styled.div<{ isSelected?: boolean }>`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  transition: 100ms ease-in-out;
  color: ${({ theme, isSelected }) => (isSelected ? theme.brandTheme.brand100 : theme.brandTheme.brand50)};
  border: ${({ theme, isSelected }) =>
    isSelected ? `2px solid ${theme.brandTheme.brand100}` : `2px solid ${theme.brandTheme.brand50}`};
  p {
    color: ${({ theme, isSelected }) => (isSelected ? theme.brandTheme.brand100 : theme.brandTheme.brand50)} !important;
  }
`;

export const Wrapper = styled.div`
  display: none;
  @media ${devices['no-mobile']} {
    display: flex;
  }
`;
