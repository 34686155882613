import styled from 'styled-components';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div``;

export const ServiceNumber = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.grays200};
`;

export const MainButtonContainer = styled.button<{ isLast?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-bottom: ${({ theme }) => `2px solid ${theme.palette.grays20}`};
  @media ${devices.XLarge} {
    padding: 12px 40px 16px 24px;
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
  }
  display: flex;
  justify-content: space-between;
  .rotate {
    transition: 200ms;
    transform: rotate(-90deg);
  }
`;

export const ProviderButtonContainer = styled.div`
  > button {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
  }
  @media ${devices.XLarge} {
    > button {
      border-bottom: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
    }
  }
`;

export const ServiceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Checked = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  margin-right: 8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.palette.white}!important;
  }
`;
