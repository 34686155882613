export const selectValueFormat = (values = [], value = 'id', label = 'name') =>
  values.map((item) => ({ value: item[`${value}`], label: item[`${label}`] }));

export const serviceStaffFormatter = (service, provider = 0) => {
  const serviceStaffs = [];
  const obj = {
    bundled: false,
    service: service.id,
    provider,
    simultaneous_package: false,
    simultaneous_package_id: false,
  };

  if (service.bundled) {
    // eslint-disable-next-line array-callback-return
    service.bundle.map((item) => {
      serviceStaffs.push({
        ...obj,
        bundled: true,
        service: item.id,
        provider: 0,
      });
    });
  } else {
    serviceStaffs.push(obj);
  }

  return serviceStaffs;
};
