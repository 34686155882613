import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, Clock, Plus } from '@agendapro/emerald-icons';
import { useRouter } from 'next/router';

import { useReservation } from '@/context/ReservationsContext';
import { Button, Drawer, Paragraph } from '@/UI';
import { generateInternalId, getServicesTotal, showSeeAllHoursAvailable } from '@/utils';

import { useServiceProviders } from '@/services/serviceProviders';
import { useFormatCurrency, usePageContext } from '@/hooks';
import ServiceSelector from './ServiceSelector/ServiceSelector';
import * as St from './ServiceList.styles';

const ServiceList: FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { companyOverview } = usePageContext();
  const { reservations, dispatch } = useReservation();
  const {
    bookingMode,
    canChooseProvider,
    isEditing,
    isSession,
    providerPreSelected,
    serviceBeingReserved,
    servicesToReserve,
    servicesWithProviders,
    step,
    companyBookingInfo,
  } = reservations;
  const { locationId } = router.query as { locationId: string };
  const { data: providersByLocation } = useServiceProviders(locationId);
  const fc = useFormatCurrency();
  const formattedPrice = fc(getServicesTotal(servicesToReserve));
  const sessionsAmountReached = !!(servicesToReserve[0]?.sessionsAmount === servicesToReserve.length);
  const sessionPrice = fc(servicesToReserve[0]?.price);
  const servicesCompleted = !servicesToReserve.some((service) => !service.hourSelected || !service.serviceProvider);

  const addSession = () => {
    const baseService = servicesToReserve[0];
    const { id } = baseService;
    const session = {
      ...baseService,
      internalId: generateInternalId(id),
      daySelected: null,
      hourSelected: null,
      name: `${t('SESSION')} ${servicesToReserve.length + 1}`,
    };

    dispatch({ type: 'SET_SERVICES_TO_RESERVE', payload: [...servicesToReserve, session] });
    dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: session });
    dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
    dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
    dispatch({ type: 'SET_DAY_SELECTED', payload: null });
    if (canChooseProvider === 'CANT') {
      dispatch({
        type: 'SET_SERVICES_WITH_PROVIDERS',
        payload: {
          ...servicesWithProviders,
          [session.internalId]: { providerId: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') },
        },
      });
    }
    if (providerPreSelected) {
      dispatch({
        type: 'SET_SERVICES_WITH_PROVIDERS',
        payload: {
          ...servicesWithProviders,
          [session.internalId]: { providerId: providerPreSelected.id, publicName: providerPreSelected.publicName },
        },
      });
    }
  };

  const getDrawerString = (
    servicesAmount: number,
    isSession: boolean,
  ): 'SERVICE' | 'SERVICES' | 'SESSION' | 'SESSIONS' => {
    let returnString: any = 'SERVICE';

    if (isSession) {
      returnString = 'SESSION';
    }
    if (servicesAmount > 1) {
      returnString = returnString.concat('S');
    }
    return returnString;
  };

  const handleNext = () => {
    const uncompletedServiceIndex = servicesToReserve.findIndex(
      (service) => !service.hourSelected || !service.serviceProvider,
    );

    window.scrollTo(0, 0);
    if (uncompletedServiceIndex >= 0 && !serviceBeingReserved?.bundled) {
      dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: servicesToReserve[uncompletedServiceIndex] });
      return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
    }
    if (companyBookingInfo.clientExclusive) {
      return dispatch({ type: 'SET_STEP', payload: 'reservationSummary' });
    }
    return dispatch({ type: 'SET_STEP', payload: 'clientForm' });
  };

  const handleChangeBookingMode = () => {
    dispatch({ type: 'SET_SERVICES_TO_RESERVE', payload: reservations.servicesToReserveUnmuted });
    dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: null });
    dispatch({ type: 'SET_BOOKING_MODE', payload: null });
    dispatch({ type: 'SET_SERVICES_IDS', payload: undefined });
    dispatch({ type: 'SET_DAY_SELECTED', payload: null });
    dispatch({ type: 'SET_HOUR_SELECTED', payload: null });
    dispatch({ type: 'SET_SERVICES_WITH_PROVIDERS', payload: {} });
    dispatch({ type: 'SET_STEP', payload: 'modeSelection' });
  };

  return (
    <>
      <St.Wrapper>
        <St.Title size="subHeadline" weight="regular">
          {t('SERVICES_LIST.TITLE')}
        </St.Title>
        <St.ServiceButtonWrapper noBorderRadius={!!((isSession || providerPreSelected) && step !== 'modeSelection')}>
          <ServiceSelector />
        </St.ServiceButtonWrapper>
        {isSession && !sessionsAmountReached && !isEditing && (
          <St.AddSession
            isEnabled={!sessionsAmountReached && servicesCompleted}
            onClick={() => !sessionsAmountReached && servicesCompleted && addSession()}
            data-cy="add_session_button"
          >
            <Plus size={20} />
            <Paragraph weight="light">{`${t('SERVICES_LIST.ADD_A_SESSION')}`}</Paragraph>
          </St.AddSession>
        )}
        {showSeeAllHoursAvailable(
          reservations,
          providersByLocation,
          companyOverview?.isPlanSolo,
          canChooseProvider,
        ) && (
          <St.SeeAllDates>
            <Clock size={50} />
            <St.RemoveProviderText className="removeProviderText" weight="light">
              {t("SERVICES_LIST.DIDN'T_FIND_SERVICES_TEXT")}
            </St.RemoveProviderText>
            <St.RemoveProvider onClick={() => dispatch({ type: 'REMOVE_PRE_SELECTED_PROVIDER' })}>
              <Paragraph>{t('SERVICES_LIST.SEE_ALL_DATES_AVAILABLE')}</Paragraph>
            </St.RemoveProvider>
          </St.SeeAllDates>
        )}
        {!isEditing && (
          <Drawer className="drawer">
            <St.DrawerContainer>
              <div>
                <Paragraph weight="light" className="drawerService">
                  {servicesToReserve.length}&nbsp;
                  {t(getDrawerString(servicesToReserve.length, isSession))}
                </Paragraph>
                <Paragraph weight="bold">{isSession ? sessionPrice : formattedPrice}</Paragraph>
              </div>
              <Button
                className="continueBtn"
                onClick={handleNext}
                data-testid="button_confirm_multiple_selector"
                data-cy="button_confirm_multiple_selector"
              >
                {t('BUTTON_NEXT')}
              </Button>
            </St.DrawerContainer>
          </Drawer>
        )}
      </St.Wrapper>
      {step !== 'modeSelection' && bookingMode === 'consecutive' && (
        <St.ChangeBookingMode onClick={() => handleChangeBookingMode()}>
          <Calendar size={25} />
          <Paragraph weight="light">{t('CONSECUTIVE_SERVICES_SELECTION.CHANGE_BOOKING_MODE')}</Paragraph>
        </St.ChangeBookingMode>
      )}
    </>
  );
};

export default ServiceList;
