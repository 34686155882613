import { useMemo, useState } from 'react';

import { RequestErrorModal } from '@/components/RequestErrorModal';
import { DialogsContext } from './DialogsContext';
import { ErrorDialogProps } from './DialogsContext.types';

export const DialogsContextProvider = ({ children }) => {
  const [errorDialog, setErrorDialog] = useState<ErrorDialogProps>({ open: false });

  const value = useMemo(() => ({ setErrorDialog }), [setErrorDialog]);

  return (
    <DialogsContext.Provider value={value}>
      {errorDialog.open && (
        <RequestErrorModal
          {...errorDialog}
          onClose={() => {
            setErrorDialog((st) => ({ ...st, open: false }));
          }}
        />
      )}
      {children}
    </DialogsContext.Provider>
  );
};
