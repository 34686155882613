import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Day, ServiceModel } from '@/services/services';
import { Paragraph } from '@/UI/Atoms';
import * as St from './Description.styles';
import { formatAMPMHour, getWeekDays } from '@/utils';

export const Description = ({ service }: { service: ServiceModel }) => {
  const [open, setOpen] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const { images, description, bundled, classService, times, bundle, simultaneousPackage } = service;
  const timesData = times && Object.values(times).filter((date) => date.dayId);

  const scheduleFilter = (time: Day, t) => {
    const schedules = time.hours
      ?.sort((a, b) => a.open.localeCompare(b.open))
      .map((hour) => <St.Hour key={hour.open}>{formatAMPMHour(hour.open)}</St.Hour>);

    return (
      <St.ServiceDate key={time.dayId}>
        <St.DateSection>
          <Paragraph weight="light" size="small">
            {time.dayId && getWeekDays(t)[time.dayId]}
          </Paragraph>
        </St.DateSection>
        <St.DateSection>
          <Paragraph size="small" weight="light">
            {schedules}
          </Paragraph>
        </St.DateSection>
      </St.ServiceDate>
    );
  };

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setIsClamped(true);
    }
  }, [ref]);

  return (
    <St.Wrapper>
      <St.DescriptionText
        isDescriptionShown={!!open}
        hasImages={!!images?.length}
        ref={ref}
        data-testid="description-text"
      >
        {description}
      </St.DescriptionText>
      {open && bundled && (
        <St.BundleServiceContainer>
          <St.BundleTitle size="small" weight="light">{`${t('ASSOCIATED_SERVICES')}`}</St.BundleTitle>
          <St.ListContainer>
            {bundle.map((service) => (
              <St.ListItem key={service.id}>
                <Paragraph weight="light" size="small" as="span">
                  {service.name}
                </Paragraph>
              </St.ListItem>
            ))}
          </St.ListContainer>
          <Paragraph weight="light" size="small">
            {simultaneousPackage && `(${t('CARD_SERVICE.SIMULTANEOUS_PACKAGE')})`}
          </Paragraph>
        </St.BundleServiceContainer>
      )}
      {open && classService && timesData!.length > 0 && (
        <St.ScheduleWrapper data-testid="schedule-wrapper">
          <St.ScheduleTitle size="small" weight="light">
            {t('CARD_SERVICE.SCHEDULE_TITLE')}
          </St.ScheduleTitle>
          <St.ScheduleDateWrapper>{timesData?.map((date) => scheduleFilter(date, t))}</St.ScheduleDateWrapper>
        </St.ScheduleWrapper>
      )}
      {(bundled || classService || isClamped || (!!images?.length && !!description)) && (
        <St.MoreInformation type="button" onClick={() => setOpen(!open)} data-testid="toggle-more-info">
          <Paragraph weight="light" size="small">
            {open ? `${t('CARD_SERVICE.SEE_LESS')}` : `${t('CARD_SERVICE.MORE_INFORMATION')}`}
          </Paragraph>
        </St.MoreInformation>
      )}
    </St.Wrapper>
  );
};
