import styled from 'styled-components';
import { devices } from '../../Styles';
import { Paragraph } from '../Paragraph';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.palette.grays80};
  padding-top: 0;
  p {
    white-space: pre-line;
  }
  @media ${devices.XLarge} {
    padding: 0;
  }
`;

export const DetailLabel = styled(Paragraph)`
  display: none;

  @media ${devices.Large} {
    display: block;
    margin-bottom: 22px;
  }
`;

export const DetailContainer = styled.div`
  position: relative;
  text-align: justify;
`;

export const ShowDetailButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
  color: ${({ theme }) => theme.brandTheme.brand100};
`;

export const ShowDetailIconContainer = styled.button`
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  p {
    margin-left: 4px;
  }
`;

export const ViewMoreLabel = styled(Paragraph)`
  text-decoration: underline;
`;

export const TextContentSecondary = styled.span`
  display: block;
  margin-top: 1rem;
`;

export const DescriptionContainer = styled.div<{ clamped: boolean }>`
  padding-right: 12px;
  overflow: hidden;
  overflow-wrap: break-word;
  ${({ clamped }) =>
    clamped &&
    `
    max-height: 38px;
  `}

  @media ${devices.XLarge} {
    ${({ clamped }) =>
      clamped &&
      `
      max-height: 76px;
    `}
  }
`;
