import { EmeraldTheme } from '@agendapro/emerald/v2';
import { fontFamily, fontSize, fontWeight, iconSize, palette } from '../Styles';
import { brandTheme } from './brandTheme';

const theme = {
  ...EmeraldTheme,
  fontFamily,
  fontSize,
  fontWeight,
  iconSize,
  palette,
  brandTheme,
  migrateColors: EmeraldTheme.migrateColors,
  spacing: EmeraldTheme.spacing,
  breakpoints: EmeraldTheme.breakpoints,
  borderRadius: EmeraldTheme.borderRadius,
  boxShadows: EmeraldTheme.boxShadows,
};

export default theme;
