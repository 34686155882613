import styled from 'styled-components';
import { fadeIn, keyFramesFadeIn } from '@/utils';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing[32]} ${theme.spacing[16]}`};
  background-color: rgba(26, 26, 26, 0.5);
  box-sizing: border-box;
  ${fadeIn(200)}
  ${keyFramesFadeIn}
`;
export const Card = styled.div`
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[16]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  .closeIcon {
    margin-left: auto;
    margin-right: 0px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100% - 66px);
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
