import { Charly } from '@agendapro/emerald-icons';
import styled, { css } from 'styled-components';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { Skeleton } from '@/UI/Atoms/Skeleton';

export const Wrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing[24]} 0px`};
  display: flex;
  align-items: center;
  @media ${devices.XLarge} {
    padding: ${({ theme }) => `${theme.spacing[60]} 0px`};
  }
`;

export const CharlySkeleton = styled(Skeleton)`
  ${({ theme }) => css`
    display: flex;
    border-radius: ${theme.spacing[8]};
    margin: ${theme.spacing[16]} 0px;
    height: 139px;
    @media (min-width: ${theme.breakpoints.lg}) {
      height: 150px;
    }
  `}
`;

export const CharlyContainer = styled.div`
  display: none;
  position: relative;
  @media ${devices.XLarge} {
    display: flex;
  }
`;

export const CharlyIcon = styled(Charly)`
  path {
    fill: ${({ theme }) => theme.palette.grays50};
  }
  transform: rotate(8deg);
`;

export const CharlyWink = styled.div`
  z-index: 10;
  position: absolute;
  top: 72px;
  right: 39px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: ${({ theme }) => theme.palette.white};
`;

export const TextBanner = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #ef9b3b 0%, #ef9b3b 58.22%, #ff9243 100%);
  border-radius: 50px 8px;
  padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[36]}`};
  p {
    color: ${({ theme }) => theme.palette.white};
  }
  path {
    fill: ${({ theme }) => theme.palette.white};
  }
  @media ${devices.XLarge} {
    padding: ${({ theme }) => `${theme.spacing[28]} ${theme.spacing[60]}`};
  }
`;

export const Headline = styled(Paragraph)`
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Flex = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing[8]};
  }
  @media ${devices.XLarge} {
    p {
      font-size: 20px;
    }
  }
`;

export const SubHeadline = styled(Paragraph)`
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  font-size: 14px;
  @media ${devices.XLarge} {
    font-size: 20px;
  }
`;
