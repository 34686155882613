import { FC } from 'react';
import { SpinnerContainer, SpinnerLoader } from './Spinner.styles';
import { SpinnerProps } from './Types';

export const Spinner: FC<SpinnerProps> = ({ color, size, desktopSize, className, fullScreen, margin }) => (
  <SpinnerContainer data-testid="spinner-container" fullScreen={fullScreen} margin={margin}>
    <SpinnerLoader
      data-testid="spinner-loader"
      className={className}
      color={color}
      size={size}
      desktopSize={desktopSize}
    />
  </SpinnerContainer>
);
