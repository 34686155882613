import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { CalendarOnlineBookings, CircleCheck, TriangleWarning } from '@agendapro/emerald-icons';

import Link from 'next/link';
import { HeaderNotification, Paragraph } from '@/UI';

import { BookingResponse } from '@/services/bookings';
import { ClientSummary } from './components/ClientSummary';
import { useDialogsContext } from '@/context/DialogsContext';
import * as St from './ConfirmBooking.styles';
import { ServicesSummaryCards } from '../ServicesSummaryCards';
import { usePageContext } from '@/hooks';

export const ConfirmBooking: FC<{
  isMultiple?: boolean;
  isBookingUnconfirmable?: boolean;
  bookingData: Omit<BookingResponse, 'trxId' | 'sessionPrice'> | undefined;
  bookingDataError: unknown;
  companySiteUrl: string;
}> = ({ isMultiple = false, bookingData, isBookingUnconfirmable, bookingDataError, companySiteUrl }) => {
  const { t } = useTranslation();
  const { setErrorDialog } = useDialogsContext();
  const { isSiteAvailable } = usePageContext();
  const { tokens } = useTheme();

  useEffect(() => {
    if (bookingDataError) {
      setErrorDialog({ open: true });
    }
  }, [bookingDataError, setErrorDialog]);

  if (bookingDataError) {
    return null;
  }
  return (
    <St.Wrapper>
      <HeaderNotification
        className="header"
        icon={isBookingUnconfirmable ? <TriangleWarning size={130} /> : <CircleCheck size={130} />}
      >
        {!isBookingUnconfirmable && (
          <St.HeaderContent>
            <Paragraph size="sectionHeadline" weight="bold" aria-label="confirmationTitleSuccess">{`${t(
              'WE_ARE_READY',
            )}`}</Paragraph>
            <St.ReadyText weight="light">
              {bookingData?.clientFullName},
              <b>
                {bookingData?.bookingGroups && bookingData.bookingGroups.length > 1
                  ? ` ${t('YOUR_RESERVATIONS_ARE_CONFIRMED')}`
                  : ` ${t('YOUR_RESERVATION_IS_CONFIRMED')}`}
              </b>
            </St.ReadyText>
          </St.HeaderContent>
        )}
        {isBookingUnconfirmable && (
          <>
            <Paragraph size="sectionHeadline" weight="bold" aria-label="confirmationTitleError">
              {t('WE_ARE_SORRY')}
            </Paragraph>
            <St.ReadyText weight="light">{t('YOUR_RESERVATION_COULD_NOT_BE_CONFIRMED')}</St.ReadyText>
          </>
        )}
      </HeaderNotification>
      <div>
        <St.ServicesContainer>
          {isSiteAvailable && (
            <St.PromotionContainer>
              <div>
                <CalendarOnlineBookings size={62} color={tokens.color.icon.brand} />
              </div>
              <div>
                <Paragraph size="sectionHeadline" weight="bold" aria-label="promotionTitle" className="text-text-brand">
                  {t('ONLINE_BOOKING_PROMOTION.TITLE')}
                </Paragraph>
                <Paragraph weight="light" className="text-text-brand whitespace-pre-wrap">
                  {t('ONLINE_BOOKING_PROMOTION.DESCRIPTION_1')}
                  <span className="text-text-brand underline break-all">
                    <Link href="/">{companySiteUrl}</Link>
                  </span>
                  {t('ONLINE_BOOKING_PROMOTION.DESCRIPTION_2')}
                </Paragraph>
              </div>
            </St.PromotionContainer>
          )}
          <St.ClientContainer isMultiple={isMultiple}>
            <ClientSummary
              name={bookingData?.clientFullName}
              email={bookingData?.clientEmail}
              phone={bookingData?.clientPhone}
            />
          </St.ClientContainer>
          <ServicesSummaryCards
            services={bookingData?.bookingGroups || []}
            bundled={bookingData?.bundled || false}
            isEdited
          />
        </St.ServicesContainer>
      </div>
    </St.Wrapper>
  );
};
