import styled from 'styled-components';

type CardContainerProps = {
  borderColor?: string;
};

export const CardContainer = styled.div<CardContainerProps>`
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  border: ${({ theme, borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : `1px solid ${theme.brandTheme.brand100}`};
  background: ${({ theme }) => theme.palette.white};
  padding: 25px;
`;
