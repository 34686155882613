import { Whatsapp } from '@agendapro/emerald-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { WhatsAppLinkProps } from './types';
import { WhatsAppLinkContainer } from './WhatsAppLink.styles';

export const WhatsAppLink: FC<WhatsAppLinkProps> = ({ whatsAppUrl, className, text }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <WhatsAppLinkContainer className={className}>
      <Icon icon={<Whatsapp color={theme.palette.textDefault} />} className="whatsAppIcon" />
      <Paragraph
        weight="light"
        onClick={() => window.open(whatsAppUrl, '_blank')}
        color={theme.palette.textSubtle}
        className="whatsAppLink font-medium"
        data-testid="whatsAppLink"
      >
        {text || t('CONTACT_US_WHATSAPP')}
      </Paragraph>
    </WhatsAppLinkContainer>
  );
};
