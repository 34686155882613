import styled from 'styled-components';
import { devices } from '@/UI/Styles';
import { keyFramesFadeIn, keyFramesSlideIn } from '@/utils/animationModal';
import { hideScrollBar } from '@/styles';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  ${hideScrollBar}
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  @media ${devices['no-mobile']} {
    background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
  }
  @media ${devices.XLarge} {
    margin-bottom: 120px;
    padding-bottom: 66px;
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  ${keyFramesFadeIn}
  ${keyFramesSlideIn}
  .btnOutline {
    color: ${({ theme }) => theme.brandTheme.brand100};
    background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    border: 1px solid ${({ theme }) => theme.brandTheme.brand100};
    &:hover {
      transition: 500ms;
      color: ${({ theme }) => theme.migrateColors('text.btnPrimary', 'white')} !important;
      background-color: ${({ theme }) => theme.brandTheme.brand100};
    }
    &:disabled {
      color: ${({ theme }) => theme.brandTheme.brand100};
      border: 1px solid ${({ theme }) => theme.brandTheme.brand100};
      background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    }
  }
`;

export const MediumWrapper = styled.div`
  @media ${devices['no-mobile']} {
    padding: ${({ theme }) => theme.spacing[40]};
    position: relative;
    min-height: calc(100vh - 69px);
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
  }
`;

export const DialogContainer = styled.div<{ postPay?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 122px);
  ${hideScrollBar}
  @media ${devices['no-mobile']} {
    background-color: ${({ theme, postPay }) =>
      postPay
        ? theme.migrateColors('background.primary', 'white')
        : theme.migrateColors('background.tertiary', 'background')};
    padding-bottom: 110px;
  }
  @media ${devices.XLarge} {
    height: 100%;
    padding-bottom: 50px;
    margin: ${({ theme }) => `0 ${theme.spacing[32]}`};
  }
`;

export const MainDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: ${({ theme }) => theme.spacing[16]};
  padding-bottom: 40px;
  background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
  @media ${devices['no-mobile']} {
    padding: 0;
    max-width: 500px;
    padding-bottom: ${({ theme }) => theme.spacing[24]};
  }
  @media ${devices.Medium} {
    width: 90%;
    max-width: 717px;
  }
  @media ${devices.Large} {
    width: 100%;
    min-width: 650px;
  }
  @media ${devices.XLarge} {
    max-width: 830px;
    margin: 0;
  }
`;

export const SideDialog = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  margin-left: ${({ theme }) => theme.spacing[24]};
`;
