import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { applyBoxShadowSm } from '@/styles/mixins';

export const Header = styled.div<{ isLogged?: boolean }>`
  display: flex;
  justify-content: ${({ isLogged }) => (isLogged ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
  path {
    fill: ${({ theme }) => theme.palette.grays100}!important;
  }
  i {
    @media ${devices['no-mobile']} {
      display: none;
    }
  }
`;

export const SideBarItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.grays80};
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
  width: 100%;
  a {
    width: 100%;
  }
  i {
    color: ${({ theme }) => theme.brandTheme.brand100};
    margin-right: 8px;
    font-size: 20px;
    @media ${devices['no-mobile']} {
      margin-right: 12px;
      font-size: 24px;
    }
  }
  @media ${devices['no-mobile']} {
    cursor: pointer;
    :hover {
      background: ${({ theme }) => theme.brandTheme.brand20};
    }
  }
`;

export const FlexSpan = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.palette.white};
  z-index: 999999;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  @keyframes scaleUp {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @media ${devices['no-mobile']} {
    position: absolute;
    height: auto;
    width: 350px;
    top: 71px;
    left: auto;
    right: 0px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.grays20};
    ${applyBoxShadowSm()}

    -webkit-animation: scale-up-ver-top 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-ver-top 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @-webkit-keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.6);
        transform: scaleY(0.6);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
    @keyframes scale-up-ver-top {
      0% {
        -webkit-transform: scaleY(0.6);
        transform: scaleY(0.6);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
      100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
      }
    }
  }
`;
