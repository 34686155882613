import { getCookie } from '@agendapro/sapphire';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { ENV } from '@/config/env';

export const API_APP_PRO =
  process.env.NODE_ENV === 'test'
    ? axios
    : axios.create({
        baseURL: ENV.APRO_URL,
        timeout: 60000,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'Authorization',
          ...(ENV.API_KEY ? { 'Api-Key': ENV.API_KEY } : undefined),
          ...(ENV.FRONTEND_COOKIE && typeof window !== 'undefined'
            ? { Authorization: getCookie(ENV.FRONTEND_COOKIE) }
            : undefined),
        },
      });

API_APP_PRO.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };

    if (newConfig.headers && newConfig.headers['Content-Type'] === 'multipart/form-data') {
      return newConfig;
    }
    if (config.params) {
      newConfig.params = decamelizeKeys(config.params);
    }
    if (config.data) {
      newConfig.data = decamelizeKeys(config.data);
    }
    return newConfig;
  },
  (error) => Promise.reject(error),
);

API_APP_PRO.interceptors.response.use(
  (response) => {
    if (response.data && response.headers['content-type'].includes('application/json')) {
      response.data = camelizeKeys(response.data);
    }

    return response;
  },
  (error) => Promise.reject(error),
);
