/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import { Check, Close, TriangleWarning } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';

import { NotificationModalContainer } from './NotificationModal.styles';
import { INotificationModalProps } from './types';
import { Button, Icon, Paragraph } from '../..';

export const NotificationModal: FC<INotificationModalProps> = ({
  children,
  type,
  handleClose,
  handleConfirm,
  hideCancelButton,
  hideConfirmButton,
  confirmButtonText,
  cancelButtonText,
}) => {
  const { t } = useTranslation();

  const getIcon = (type?: string) => {
    if (type === 'success') {
      return <Check size={60} />;
    }
    if (type === 'error') {
      return <Close size={60} />;
    }
    return <TriangleWarning size={60} />;
  };

  const justifyButtons = hideCancelButton ? 'center' : undefined;

  return (
    <NotificationModalContainer type={type} justifyButtons={justifyButtons}>
      <div className="modal">
        <div className="iconContainer">
          <Icon icon={getIcon(type)} size={60} className="icon" />
        </div>
        <Paragraph className="title" size="headline" data-testid="title">
          {type === 'success' ? t('SUCCESS') : type === 'error' ? t('ERRORS.ERROR') : t('WARNING')}
        </Paragraph>
        <div className="messageContainer">{children}</div>
        <div className="buttonContainer">
          {!hideCancelButton && (
            <Button className="buttonCancel" data-testid="cancelButton" onClick={handleClose}>
              {cancelButtonText || t('BUTTON_CANCEL')}
            </Button>
          )}
          {!hideConfirmButton && (
            <Button className="buttonConfirm" data-testid="confirmButton" onClick={handleConfirm}>
              {confirmButtonText || t('BUTTON_CONFIRM')}
            </Button>
          )}
        </div>
      </div>
    </NotificationModalContainer>
  );
};
