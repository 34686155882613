import { FC, useState, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, Delete, Gift, Home, Minus, Plus, VideoCall } from '@agendapro/emerald-icons';
import { useTheme } from 'styled-components';

import { Button } from '@agendapro/emerald/v2';
import { useShowComponent, useFormatCurrency } from '@/hooks';
import { CarouselModal } from '../CarouselModal/CarouselModal';
import { ServiceModel } from '@/services/services';
import { Description } from './Description/Description';
import { getFormatTime } from '@/UI/Utils';
import { Paragraph } from '@/UI/Atoms';
import * as St from './CardService.styles';

const getTypeOfServiceText = ({ hasSession, bundled, sessionsAmount, t }) => {
  if (hasSession) {
    return `(${sessionsAmount} ${t('CARD_SERVICE.SESSIONS')})`;
  }
  if (bundled) {
    return `${t('CARD_SERVICE.SERVICE_PACKAGE')}`;
  }
  return null;
};

export const CardService: FC<{
  handleAddService: (service: ServiceModel) => void;
  handleRemoveService: (service: ServiceModel) => void;
  isSalfa: boolean;
  selected: ServiceModel[];
  service: ServiceModel;
  setOpenSalfa: Dispatch<SetStateAction<boolean>>;
  setSelected: (selected: ServiceModel[]) => void;
}> = ({ handleAddService, handleRemoveService, isSalfa, selected, service, setOpenSalfa, setSelected }) => {
  const {
    bundled,
    charlyDiscount,
    description,
    discount,
    duration,
    hasSession,
    images,
    isVideo,
    name,
    outcall,
    payingPrice,
    payingPriceCharly,
    price,
    sessionsAmount,
    showDuration,
    showPrice,
    onlinePayable,
  } = service;
  const { palette, tokens } = useTheme();
  const { t } = useTranslation();
  const fc = useFormatCurrency();
  const typeOfService = getTypeOfServiceText({
    hasSession,
    bundled,
    sessionsAmount,
    t,
  });
  const { isShown, show, hide } = useShowComponent();
  const [imageSelected, setImageSelected] = useState<number | undefined>(undefined);
  const selectedAmount = selected.filter((selected) => selected.id === service.id).length;
  const isSelected = selected?.some(
    (selectedService) => selectedService.id === service.id && selectedService.bundled === service.bundled,
  );
  const priceFormatted = fc(price);
  const payingPriceFormatted = fc(payingPrice);
  const CharlyPriceFormatted = fc(payingPriceCharly);

  return (
    <>
      <St.ServiceCard
        isSelected={isSelected}
        hasDiscount={(!!discount && onlinePayable) || !!charlyDiscount}
        reducedPadding={!!((!images || !images.length) && !description)}
      >
        {((!!discount && onlinePayable) || !!charlyDiscount) && (
          <St.DiscountLabel isCharly={!!charlyDiscount}>
            {charlyDiscount ? (
              <St.CharlyDiscountLabel data-cy="charly-discount-card">
                <Gift size={15} />
                <Paragraph weight="light">{t('CARD_SERVICE.SPECIAL_DISCOUNT')}</Paragraph>
              </St.CharlyDiscountLabel>
            ) : (
              <Paragraph weight="light" color={palette.white} data-cy="normal-discount-card">{`${t(
                'CARD_SERVICE.DISCOUNT_PAYING_ONLINE',
              )}`}</Paragraph>
            )}
          </St.DiscountLabel>
        )}

        <St.Content isPaidOnline={!!discount} data-cy="card_service_content">
          {typeOfService && (
            <St.TypeOfService weight="light" size="xsmall">
              {typeOfService}
            </St.TypeOfService>
          )}
          <St.Title heading="h2" size="paragraph" weight="regular">
            {name}
          </St.Title>
          <St.SubMain>
            <St.Extras>
              {showDuration && <St.Duration weight="light">{getFormatTime(duration)}</St.Duration>}
              {(isVideo || outcall) && showDuration && <St.Separator />}
              {isVideo && (
                <St.ExtrasContainer>
                  <VideoCall size={20} />
                  <Paragraph size="xsmall" weight="light" color={palette.grays80}>
                    {t('CARD_SERVICE.VIDEO_CONFERENCE')}
                  </Paragraph>
                </St.ExtrasContainer>
              )}
              {outcall && (
                <St.ExtrasContainer>
                  <Home size={20} />
                  <Paragraph size="xsmall" weight="light" color={palette.grays80}>
                    {t('CARD_SERVICE.OUTCALL_SERVICE')}
                  </Paragraph>
                </St.ExtrasContainer>
              )}
            </St.Extras>
          </St.SubMain>
          {showPrice && (
            <St.PriceContainer>
              <Paragraph size="small" weight="bold" color={palette.grays200}>
                {price === '' ? t('CARD_SERVICE.FREE') : CharlyPriceFormatted || payingPriceFormatted || priceFormatted}
              </Paragraph>
              <St.DiscountPrice size="xsmall" weight="light">
                {((!!discount && onlinePayable) || !!charlyDiscount) &&
                  !!priceFormatted &&
                  `(${t('REGULAR_PRICE')}) ${priceFormatted}`}
              </St.DiscountPrice>
            </St.PriceContainer>
          )}
          <St.CardImageContainer>
            {images?.map((image, index) => (
              <button
                type="button"
                key={image}
                onClick={() => {
                  show();
                  setImageSelected(index);
                }}
              >
                <St.CardImage
                  src={image}
                  alt={`${t('CARD_SERVICE.IMG_ALT_SERVICE')}`}
                  height={52}
                  width={52}
                  objectFit="cover"
                  data-testid="service-image"
                />
              </button>
            ))}
          </St.CardImageContainer>
          <Description service={service} />
          <St.ServiceAmountContainer>
            {isSelected && !isSalfa && (
              <>
                {selectedAmount > 1 ? (
                  <Button
                    variant="secondary"
                    data-testid="remove-btn"
                    iconButton
                    onClick={() => handleRemoveService(service)}
                  >
                    <Minus size={20} color={tokens.color.icon.danger} />
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    data-testid="delete-btn"
                    iconButton
                    onClick={() => setSelected(selected.filter((selectedService) => selectedService.id !== service.id))}
                  >
                    <Delete size={20} color={tokens.color.icon.danger} />
                  </Button>
                )}
              </>
            )}
            {isSelected && !isSalfa && <Paragraph>{selectedAmount}</Paragraph>}
            <St.AddButton
              type="button"
              onClick={() => {
                handleAddService(service);
                if (isSalfa) {
                  setOpenSalfa(true);
                }
              }}
              visible={
                (!isSelected && isSalfa) ||
                (!isSalfa && !isSelected) ||
                (!isSalfa && isSelected && !bundled && !hasSession)
              }
              selected={isSelected}
              data-testid="add-btn"
            >
              {isSelected ? <Plus size={18} color={tokens.color.text.default} /> : t('CARD_SERVICE.SCHEDULE_SERVICE')}
            </St.AddButton>
          </St.ServiceAmountContainer>
          {isSelected && isSalfa && (
            <Button onClick={() => handleRemoveService(service)} className="buttonAddedService">
              <Check size={15} />
              <St.ScheduleSpan>{`${t('CARD_SERVICE.SELECTED')}`}</St.ScheduleSpan>
            </Button>
          )}
        </St.Content>
      </St.ServiceCard>
      {isShown && <CarouselModal handleClose={hide} images={images!} selectedIndex={imageSelected} />}
    </>
  );
};
