import { FC } from 'react';
import { Paragraph } from '../Paragraph';
import { Anchor } from './Link.styles';
import { LinkProps } from './types';

export const Link: FC<LinkProps> = ({ size, children, href, className, target = '_self', isUnderlined = false }) => (
  <Anchor className={className} href={href} target={target} isUnderlined={isUnderlined}>
    <Paragraph size={size} weight="light">
      {children}
    </Paragraph>
  </Anchor>
);
