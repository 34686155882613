import { FC } from 'react';
import { Icon, Paragraph } from '../../Atoms';
import { FormAlertContainer } from './FormAlert.styles';
import { FormAlertProps, FormAlertThemeType } from './types';

const getIconByTheme = (theme: FormAlertThemeType) => {
  let icon;

  switch (theme) {
    case 'success':
      icon = 'aproicon-send';
      break;
    case 'danger':
      icon = 'aproicon-info';
      break;
    default:
      icon = undefined;
  }
  return icon;
};

export const FormAlert: FC<FormAlertProps> = ({ children, theme = 'danger', className, ...props }) => (
  <FormAlertContainer className={`${theme} ${className}`} {...props}>
    <Icon className={getIconByTheme(theme)} size="small" />
    <Paragraph size="small">{children}</Paragraph>
  </FormAlertContainer>
);
