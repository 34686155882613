import styled, { css } from 'styled-components';
import { Strategy } from '@floating-ui/react-dom';
import { Text } from '@agendapro/emerald/v2';

const DesktopOptionHeight = 36;

const MobileOptionHeight = 45;

export const SelectContainer = styled.div`
  position: relative;
`;

export const StyledErrorMessage = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing[4]};
`;

export const StyledSelect = styled.button<{
  full?: boolean;
  error?: boolean;
  isPrefilled?: boolean;
  hasLeftIcon?: boolean;
  withChips?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme, hasLeftIcon }) =>
    `${theme.spacing[2]} ${theme.spacing[16]} ${theme.spacing[2]} ${
      hasLeftIcon ? theme.spacing[8] : theme.spacing[16]
    }`};

  width: ${({ full }) => (full ? '100%' : 'auto')};

  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme, isPrefilled }) => (isPrefilled ? theme.palette.background : theme.palette.white)};
  ${({ theme, disabled, error, isPrefilled }) => {
    if (disabled) {
      return css`
        border: 1px solid ${theme.palette.grays20};
      `;
    }
    if (error) {
      return css`
        border: 1px solid ${theme.palette.alert100};
      `;
    }
    if (isPrefilled) {
      return css`
        border: none;
      `;
    }
    return css`
      &:hover {
        border: 1px solid ${theme.palette.grays20};
      }
      &:focus {
        border: 1px solid ${theme.palette.primary100};
      }
      border: 1px solid ${theme.palette.grays50};
    `;
  }}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ theme, withChips }) =>
    withChips
      ? css`
          min-height: ${`${MobileOptionHeight}px`};
          @media (min-width: ${theme.breakpoints.sm}) {
            min-height: ${`${DesktopOptionHeight}px`};
          }
        `
      : css`
          height: ${`${MobileOptionHeight}px`};
          @media (min-width: ${theme.breakpoints.sm}) {
            height: ${`${DesktopOptionHeight}px`};
          }
        `}
`;

export const OptionsContainer = styled.div<{
  withSearch?: boolean;
  offsetY: number | null;
  position?: Strategy;
  width?: number;
  displayOptionsNumber: number;
  selectAll?: boolean;
}>`
  position: ${({ position }) => position || 'absolute'};
  width: ${({ width }) => `${width}px` || '100%'};
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.grays20};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 16000;

  overflow-y: auto;

  ${({ theme, withSearch, offsetY, displayOptionsNumber, selectAll }) => css`
    top: ${offsetY ? `${offsetY}px` : `${MobileOptionHeight}px`};
    max-height: ${withSearch
      ? `calc(${MobileOptionHeight}px * ${displayOptionsNumber + 1 + (selectAll ? 1 : 0)})`
      : `calc(${MobileOptionHeight}px * ${displayOptionsNumber})`};
    @media (min-width: ${theme.breakpoints.sm}) {
      top: ${offsetY ? `${offsetY}px` : `${DesktopOptionHeight}px`};
      max-height: ${withSearch
        ? `calc(${DesktopOptionHeight + 1}px * ${displayOptionsNumber + 1 + (selectAll ? 1 : 0)})`
        : `calc(${DesktopOptionHeight + 1}px * ${displayOptionsNumber})`};
    }
  `}
`;

export const Arrow = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const StyledOptionContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
  &:last-child {
    border-bottom: none;
  }
`;

export const IconContainer = styled.div<{ left?: boolean }>`
  margin-right: ${({ theme, left }) => (left ? theme.spacing[8] : '0px')};
  display: flex;
  align-items: center;
`;

export const StyledOption = styled.button<{
  isSelected?: boolean;
  depth: number;
  hasLeftIcon?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;

  padding-left: ${({ theme, depth, hasLeftIcon }) =>
    hasLeftIcon ? theme.spacing[8] : `calc(${depth || 1} * ${theme.spacing[16]})`};
  padding-right: ${({ theme }) => theme.spacing[12]};

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${({ theme }) => theme.palette.primary20};
        `
      : css`
          &:hover {
            background-color: ${({ theme }) => theme.palette.background};
          }
        `}
  ${({ theme }) => css`
    min-height: ${`${MobileOptionHeight}px`};
    @media (min-width: ${theme.breakpoints.sm}) {
      min-height: ${`${DesktopOptionHeight}px`};
    }
  `}
  ${({ depth }) =>
    (depth === 1 || depth === 2) &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
      &:last-child {
        border-bottom: none;
      }
    `}
`;

export const StyledChildren = styled.div`
  padding-left: ${({ theme }) => theme.spacing[24]};
`;

export const FixedOptionContainer = styled.div`
  position: sticky;
  top: 0;
`;

export const OptionContainer = styled.button`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
  background-color: ${({ theme }) => theme.palette.white};
  height: 36px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0px ${theme.spacing[16]}`};
  width: 100%;
`;

export const SearchContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grays20};
  background-color: ${({ theme }) => theme.palette.white};
`;

export const StyledLabel = styled(Text)`
  ${({ theme }) => css`
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    @media (min-width: ${theme.breakpoints.sm}) {
      margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
  `}
`;

export const StyledPlaceholder = styled(Text)<{ color?: string }>`
  margin-right: ${({ theme }) => theme.spacing[8]};
  color: ${({ color }) => color || 'inherit'};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ChipWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing[2]};
`;

export const ChipButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing[4]};
`;

export const StyledButtonLink = styled.button`
  padding: 0;
`;
