import { useQuery } from 'react-query';

import * as Repo from './serviceProviders.repo';
import * as Types from './serviceProviders.types';

export const useServiceProvider = ({ providerId, locationId }: Types.GetServiceProviderRequest) =>
  useQuery(['getServiceProvider', providerId, locationId], () => Repo.getServiceProvider({ providerId, locationId }));

export const useServiceProviders = (locationId) =>
  useQuery(['getServiceProviders', locationId], () => Repo.getServiceProviders(locationId), {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!locationId,
  });

export const useAvailableDays = ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  bundled,
  bundleId,
  ...options
}) =>
  useQuery(
    ['availableDays', startDate, endDate, localId, services, providers, providersArray, bundled, bundleId],
    () =>
      Repo.getAvailableDays({
        startDate,
        endDate,
        localId,
        services,
        providers,
        providersArray,
        bundled,
        bundleId,
      }),
    {
      ...options,
      enabled: !!startDate && !!endDate,
      cacheTime: 0,
      staleTime: 0,
    },
  );

export const useAvailableHours = ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  datesAndProviders,
  bundled,
  bundleId,
  ...options
}) =>
  useQuery(
    [
      'availableHours',
      startDate,
      endDate,
      localId,
      services,
      providers,
      providersArray,
      datesAndProviders,
      bundled,
      bundleId,
    ],
    () =>
      Repo.getAvailableHours({
        startDate,
        endDate,
        localId,
        services,
        providers,
        providersArray,
        datesAndProviders,
        bundled,
        bundleId,
      }),
    {
      ...options,
      enabled: !!startDate,
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    },
  );

export const useAvailableProviders = (serviceId, locationId, startAt, endAt, enabled, datesAndProviders) =>
  useQuery(
    ['availableProviders', serviceId, locationId, startAt, endAt, datesAndProviders],
    () => Repo.getAvailableProviders({ serviceId, locationId, startAt, endAt, datesAndProviders }),
    { enabled },
  );

export const useNextAvailableDay = ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  datesAndProviders,
  bundled,
  bundleId,
  ...options
}) =>
  useQuery(
    ['nextAvailableDay', startDate, endDate, localId, services, providers, bundled, bundleId, datesAndProviders],
    () =>
      Repo.getNextAvailableDay({
        bundled,
        bundleId,
        datesAndProviders,
        endDate,
        localId,
        providers,
        providersArray,
        services,
        startDate,
      }),
    {
      ...options,
    },
  );

export const useFilteredProvider = ({ locationId, id }) =>
  useQuery(['getFilteredProvider', locationId, id], () => Repo.getFilteredProvider({ locationId, id }));
