import styled from 'styled-components';

export const Wrapper = styled.button<{ selected?: boolean; completed?: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: -webkit-fill-available;
  width: 100%;
  padding: 10px 0;
  display: flex;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  border-bottom: ${({ theme, selected }) =>
    selected
      ? `3px solid ${theme.brandTheme.brand100}`
      : `1px solid ${theme.migrateColors('border.primary', 'gray30')}`};
  p {
    color: ${({ selected, theme, completed }) =>
      selected || completed
        ? theme.migrateColors('text.navDefault', 'gray')
        : theme.migrateColors('text.btnSecondaryDisabled', 'gray30')};
  }
  .cover {
    cursor: default;
    position: absolute;
    width: 100%;
    height: 115%;
    background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'white80')};
    z-index: 10;
  }
  transition: 100ms ease-in-out;
`;

export const Cover = styled.div<{ whiteOverlay?: boolean }>`
  display: ${({ whiteOverlay }) => (whiteOverlay ? 'flex' : 'none')};
  cursor: default;
  position: absolute;
  width: 100%;
  height: 115%;
  background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'white80')};
  z-index: 10;
`;
