import styled from 'styled-components';

interface StyledCheckBoxProps {
  checked: boolean;
}

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  .checkboxLabel {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .check {
    path {
      fill: ${({ theme }) => theme.palette.white};
    }
    position: absolute;
  }
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div<StyledCheckBoxProps>`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.tokens.color.border.white}` : `1px solid ${theme.tokens.color.border.inputDefault}`};
  background: ${({ checked, theme }) =>
    checked ? theme.tokens.color.background.brandSubtle : theme.tokens.color.background.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  transition: all 150ms;
  margin-right: 8px;
`;
