import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import * as Repo from './company.repo';

export const useCompanyOverview = (companySlug: string) => {
  const router = useRouter();
  const { countryCode } = router.query as { countryCode: string };

  return useQuery(
    ['companyOverview', companySlug, countryCode],
    () => Repo.getCompanyOverview(companySlug, countryCode),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export const useCompanyBookingInfo = (companySlug: string, onSuccess?) => {
  const router = useRouter();
  const { countryCode } = router.query as { countryCode: string };

  return useQuery(
    ['companyBookingData', companySlug, countryCode],
    () => Repo.getCompanyBookingInfo(companySlug, countryCode),
    {
      onSuccess,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};

export const useCompanyRRSS = (companyId?: number) =>
  useQuery(['companyRRSS', companyId], () => Repo.getCompanyRRSS(companyId), { enabled: !!companyId });

export const useCheckWebAddressCountries = (webAddress: string) =>
  useQuery(['useCheckWebAddressCountries', webAddress], () => Repo.checkWebAddressCountries(webAddress), {
    enabled: !!webAddress,
  });
