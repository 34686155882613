import { API_V1, API_V2 } from '@/infra/http';
import * as Types from './serviceProviders.types';

export const getServiceProvider = async ({ providerId, locationId }: Types.GetServiceProviderRequest) =>
  API_V2.get<Types.ServiceProviderResponse>(`/service_providers/${providerId}`, {
    params: { locationId },
  }).then((res) => res.data);

// getListOfProvidersByLocationId
export const getServiceProviders = async (locationId: string) =>
  API_V2.get<Types.ServiceProviderResponse[]>(`/service_providers`, { params: { locationId } }).then((res) => res.data);

export const getAvailableDays = async ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  bundled = 0,
  bundleId,
}: Types.AvailableDateRequest) =>
  API_V2.get(`/service_providers/available_days_sql_improved`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      local: localId,
      services,
      providers: JSON.stringify(providers),
      providersArray,
      bundled,
      bundleId,
    },
  }).then((res) => res.data);

export const getAvailableHours = async ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  datesAndProviders,
  bundled = 0,
  bundleId,
}: Types.AvailableDateRequest & { datesAndProviders: string }) =>
  API_V2.get<Types.AvailableHoursResponse>(`/service_providers/available_hours_sql_improved`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      local: localId,
      services,
      providers: JSON.stringify(providers),
      providersArray,
      datesAndProviders,
      bundled,
      bundleId,
    },
  }).then((res) => res.data);

export const getNextAvailableDay = async ({
  startDate,
  endDate,
  localId,
  services,
  providers,
  providersArray,
  datesAndProviders,
  bundled = 0,
  bundleId,
}) =>
  API_V2.get(`/service_providers/sooner_available_hours_sql_improved`, {
    params: {
      start_date: startDate,
      end_date: endDate,
      local: localId,
      services,
      providers: JSON.stringify(providers),
      providersArray,
      datesAndProviders,
      bundled,
      bundleId,
    },
  }).then((res) => res.data);

export const getAvailableProviders = async ({ serviceId, locationId, startAt, endAt, datesAndProviders }) =>
  API_V1.get(`/api/v2/sites/service_providers/date_time`, {
    params: {
      serviceId,
      locationId,
      startAt,
      endAt,
      datesAndProviders,
    },
  }).then((res) => res.data);

export const getFilteredProvider = async ({
  locationId,
  id,
  charlyToken,
}: {
  locationId: string;
  id: string;
  charlyToken?: string;
}) =>
  API_V2.get<Types.ServiceProviderResponse[] | []>('/service_providers', {
    params: {
      location_id: locationId,
      id,
      charly_token: charlyToken,
    },
  }).then((res) => res.data);
