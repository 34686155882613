import { useLayoutEffect } from 'react';

const useLockBodyScroll = (enabled = true) => {
  useLayoutEffect((): (() => void) => {
    if (enabled) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [enabled]);
};

export default useLockBodyScroll;
