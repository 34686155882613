import styled, { css } from 'styled-components';
import Image from 'next/image';
import { Delete, Minus, Plus } from '@agendapro/emerald-icons';
import { Card, TextHeader } from '../../Atoms';
import { Paragraph } from '../../Atoms/Paragraph';
import { Button } from '../../Atoms/Button';
import { devices } from '../../Styles';

export const ServiceCard = styled(Card)<{ isSelected: boolean; hasDiscount?: boolean; reducedPadding: boolean }>`
  ${({ theme, isSelected, hasDiscount, reducedPadding }) => css`
    display: flex;
    justify-content: space-between;
    transition: 150ms ease-in-out;
    color: ${theme.migrateColors('text.subtler', 'gray80')};
    position: relative;
    background-color: ${theme.tokens.color.background.primary};
    padding: ${theme.spacing[16]};
    padding-top: ${hasDiscount ? theme.spacing[32] : theme.spacing[16]};
    @media ${devices.XLarge} {
      padding: ${reducedPadding
        ? `${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[24]} ${theme.spacing[24]}`
        : `${theme.spacing[24]}`};
      padding-top: ${hasDiscount && theme.spacing[32]};
    }
  `}
`;

export const DiscountLabel = styled.div<{ isCharly?: boolean }>`
  background: ${({ theme, isCharly }) =>
    isCharly ? theme.migrateColors('background.accentDark', 'accent') : theme.brandTheme.brand100};
  color: ${({ theme }) => theme.migrateColors('text.defaultInverse', 'white')};
  border-radius: 6px 0 6px 0;
  padding: 3px 13px;
  position: absolute;
  top: -1px;
  left: -1px;
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.inverse', 'white')};
  }
  p {
    color: ${({ theme }) => theme.migrateColors('text.defaultInverse', 'white')};
    font-size: 12px;
  }
`;

export const CharlyDiscountLabel = styled.span`
  display: flex;
  align-items: center;
  p {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
`;

export const Content = styled.div<{ isPaidOnline: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .buttonAddService,
  .buttonAddedService {
    margin: 16px 0px 0px auto;
    padding: ${({ theme }) => theme.spacing[8]};
    background-color: ${({ theme }) => theme.brandTheme.brand100};
    width: fit-content;
    border-radius: 6px;
    transition: all 1s ease-out;
    svg {
      margin-right: 4px;
      path {
        fill: ${({ theme }) => theme.migrateColors('icon.btnPrimary', 'white')};
      }
    }
    p {
      display: flex;
      align-items: center;
    }
  }
`;

export const TypeOfService = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  margin-bottom: 5px;
`;

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  text-align: left;
  p:last-child {
    margin-left: 10px;
    @media ${devices['no-mobile']} {
      margin-left: 25px;
    }
  }
`;

export const Title = styled(TextHeader)`
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
`;

export const SubMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Extras = styled.div`
  margin-top: 8px;
  @media ${devices['no-mobile']} {
    display: flex;
    align-items: center;
  }
`;

export const Duration = styled(Paragraph)`
  font-size: 12px;
  color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')};
`;

export const Separator = styled.div`
  display: none;
  @media ${devices['no-mobile']} {
    display: block;
    height: 9px;
    border: 1px solid ${({ theme }) => theme.migrateColors('border.secondary', 'gray20')};
    margin: 0 12px;
  }
`;

export const ExtrasContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.brandTheme.brand100};
  svg {
    margin-right: 4px;
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }
`;

export const BundleServiceContainer = styled.div`
  margin-top: 15px;
`;

export const BundleTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  font-size: 12px;
`;

export const ListContainer = styled.ul`
  list-style-position: inside;
  font-size: 12px;
  margin: 0px;
  margin-top: 10px;
  padding-left: 0;
  line-height: 22px;
`;

export const ListItem = styled.li`
  list-style-type: disc;
  display: list-item;
  text-align: start;
`;

export const CardImageContainer = styled.div`
  margin-top: 13px;
  display: flex;
`;
export const CardImage = styled(Image)`
  border-radius: 50%;
  margin-right: 17px;
`;

export const ButtonContainer = styled.div<{ isSelected: boolean; isPaidOnline: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSelected, theme }) =>
    css`
      ${isSelected ? theme.brandTheme.brand100 : theme.migrateColors('background.primary', 'white')}
    `};
  border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: ${({ isPaidOnline }) => (isPaidOnline ? '6px' : '')};
  -webkit-tap-highlight-color: transparent;
  transition: 150ms ease-in-out;
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.inverse', 'white')};
  }
`;

export const ToggleBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  cursor: pointer;
  margin: 1rem 0 0 0;
`;

export const ToggleBtn = styled.button`
  background: transparent;
  border: none;
  color: #1a1a1acc;

  @media ${devices.XSmall} {
    font-size: 0.75rem;
  }

  @media ${devices.Medium} {
    font-size: 0.875rem;
  }
`;

export const ServiceDate = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 0.2rem;
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ScheduleSpan = styled.span`
  font-weight: normal;
`;

export const ServiceAmountContainer = styled.div`
  margin: 16px 0px 0px auto;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: ${({ theme }) => theme.spacing[16]};
  transition: all 1s ease-in;
  animation: fade 0.5s;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  & > * {
    transition: fade 1s ease-in;
    animation: fade 1s;
  }

  button {
    &:active,
    &:focus {
      background-image: none;
    }
  }
`;

export const AddButton = styled.button<{ selected?: boolean; visible?: boolean }>`
  padding: ${({ theme, visible }) => (!visible ? '0' : theme.spacing[8])};
  background-color: ${({ theme, selected }) =>
    selected ? theme.tokens.color.background.primary : theme.brandTheme.brand100};
  border-radius: 6px;
  border: ${({ theme, selected, visible }) =>
    `${visible ? '1px' : '0px'} solid ${
      selected ? theme.tokens.color.border.btnSecondary : theme.brandTheme.brand100
    }`};
  color: ${({ theme }) => theme.tokens.color.text.btnPrimary};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  transition: all 0.25s ease-out !important;
  height: 36px;
  overflow: hidden;
  width: ${({ selected, visible }) => (!visible ? '0px' : (selected && '36px') || '130px')} !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: ${({ theme }) => theme.boxShadows.sm};

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.tokens.color.background.btnSecondaryHover : theme.brandTheme.brand100};
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;

export const DiscountPrice = styled(Paragraph)`
  color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')};
  margin-left: 6px;
  font-size: 12px;
`;
