import { rutValidate } from './utils';

export const emailPatternValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type FieldProps = 'email' | 'password' | 'id' | 'rut' | 'phone' | 'simpleRequired' | 'integer' | undefined;

export const validationByField = (field: FieldProps, t, required?: boolean) => {
  switch (field) {
    case 'email':
      return {
        maxLength: {
          value: 60,
          message: `${t('ERRORS.VALIDATION_EMAIL_TOO_LONG')}`,
        },
        pattern: {
          value: emailPatternValidation,
          message: `${t('ERRORS.VALIDATION_EMAIL_INVALID')}`,
        },
        required: {
          value: required || false,
          message: `${t('ERRORS.VALIDATION_EMAIL_REQUIRED')}`,
        },
      };
    case 'password':
      return {
        required: {
          value: true,
          message: `${t('ERRORS.VALIDATION_PASSWORD_REQUIRED')}`,
        },
        minLength: { value: 8, message: `${t('ERRORS.VALIDATION_PASSWORD_LENGTH')}` },
      };
    case 'id':
      return {
        required: {
          value: required || false,
          message: `${t('ERRORS.VALIDATION_REQUIRED')}`,
        },
        minLength: { value: 2, message: `${t('ERRORS.ID_TOO_SHORT')}` },
      };
    case 'rut':
      return {
        validate: (value) => {
          if (!required && value === '') {
            return true;
          }
          return rutValidate(value);
        },
      };
    case 'phone':
      return {
        required: {
          value: required || false,
          message: `${t('ERRORS.VALIDATION_REQUIRED')}`,
        },
        minLength: { value: 8, message: `${t('ERRORS.VALIDATION_PHONE_TOO_SHORT')}` },
      };
    case 'simpleRequired':
      return {
        required: {
          value: true,
          message: `${t('ERRORS.VALIDATION_REQUIRED')}`,
        },
        maxLength: { value: 280, message: `${t('ERRORS.VALIDATION_INPUT_TOO_LONG')}` },
      };
    case 'integer':
      return {
        required: {
          value: required || false,
          message: `${t('ERRORS.VALIDATION_REQUIRED')}`,
        },
        max: { value: 2147483647, message: `${t('ERRORS.VALIDATION_INTEGER_TOO_LONG')}` },
      };
    default:
      return {
        required: {
          value: true,
          message: `${t('ERRORS.VALIDATION_REQUIRED')}`,
        },
      };
  }
};
