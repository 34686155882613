import { FC, Fragment } from 'react';
import { Calendar, Clock, Home, VideoCall, Profile, CirclePrice } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { useTheme } from 'styled-components';
import { Paragraph } from '@/UI';
import { formatAMPMHour } from '@/utils/formatAMPMHour';
import { SummaryItem } from './SummaryItem/SummaryItem';

import * as St from './ServiceCollapse.styles';
import { useLocation } from '@/services/locations';
import { ServiceReservationProps } from '@/context/ReservationsContext/ReservationContext.types';

export const ServiceCollapse: FC<{ services: ServiceReservationProps[] }> = ({ services }) => {
  dayjs.extend(isBetween);
  const router = useRouter();
  const {
    tokens: { color },
  } = useTheme();
  const { t } = useTranslation();
  const { locationId } = router.query as { locationId: string };
  const { data: currentLocation } = useLocation(locationId);
  const iconColor = color.icon.brand;

  return (
    <St.Wrapper>
      {services.map((service, index) => (
        <Fragment key={service.internalId}>
          <St.CollapseContent>
            {services.length > 1 && (
              <Paragraph className="serviceName" data-testid="service-name">
                {service.hasSession ? `${services[0].name} (${index + 1})` : service.name}
              </Paragraph>
            )}
            {!!service.price && !service.hasSession && services.length > 1 && service.showPrice && (
              <div data-testid="service-price">
                <SummaryItem
                  iconComponent={<CirclePrice size={20} />}
                  prices={{
                    hasCharlyPromotion: !!service.charlyDiscount,
                    inPromotionRange: dayjs(service.daySelected).isBetween(
                      service.charlyPromotionStartDate,
                      service.charlyPromotionEndDate,
                      'day',
                      '[]',
                    ),
                    payingPrice: service.charlyDiscount ? service.payingPriceCharly : service.payingPrice,
                    price: service.price,
                    shouldPayOnline: service.mustBePaidOnline,
                    onlineDownPaymentAmount: service.onlineDownPaymentAmount,
                  }}
                />
              </div>
            )}
            <div>
              <SummaryItem
                iconComponent={<Calendar color={iconColor} size={20} />}
                itemData={`${service.dateFormatted}`}
              />
              {service.showDuration ? (
                <div data-testid="service-duration">
                  <SummaryItem
                    iconComponent={<Clock size={20} />}
                    itemData={
                      service.hourSelected && service.hourEnd
                        ? `${formatAMPMHour(service.hourSelected)} - ${formatAMPMHour(service.hourEnd)}`
                        : undefined
                    }
                  />
                </div>
              ) : (
                <SummaryItem iconComponent={<Clock size={20} />} itemData={formatAMPMHour(service.hourSelected)} />
              )}
              <SummaryItem iconComponent={<Profile size={20} />} itemData={service.serviceProvider?.publicName} />
              <SummaryItem
                iconComponent={service.isVideo ? <VideoCall size={20} /> : <Home size={20} />}
                itemData={service.isVideo ? `${t('VIDEO_CONFERENCE')}` : currentLocation?.locationName || ''}
              />
            </div>
          </St.CollapseContent>
          {services.length !== index + 1 && <St.Separator data-testid="separator" />}
        </Fragment>
      ))}
    </St.Wrapper>
  );
};
