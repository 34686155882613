import styled from 'styled-components';

interface AnchorProps {
  isUnderlined: boolean;
}

export const Anchor = styled.a<AnchorProps>`
  transition: 0.5s all ease;
  color: inherit;
  text-decoration: ${({ isUnderlined }) => (isUnderlined ? 'underline' : 'none')};
`;
