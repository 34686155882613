import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from '../../public/locales';
import countryCodeLocale from './countryCodeLocale';

const languageDetector = new LanguageDetector();

languageDetector.addDetector(countryCodeLocale);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['countryCodeLocale', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    },
    resources,
    fallbackLng: ['es', 'esAr', 'esCl', 'esCo', 'esCr', 'esEc', 'esMx', 'esPa', 'esPe', 'esUy', 'esVe'],
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
