/* eslint-disable no-console */
import { API_V2 } from '@/infra/http';
import * as Types from './locations.types';
import { encrypt } from '@/utils';
// getLocationById
export const getLocation = async (locationId: number) =>
  API_V2.get<Types.LocationResponse>(`/locations/${encrypt(locationId)}`).then((res) => res.data);

// getLocationsByCompanyId
export const getLocations = async (companyId?: number) =>
  API_V2.get<Types.LocationsResponse[]>(`/locations`, { params: { companyId: encrypt(companyId) } }).then(
    (res) => res.data,
  );
