import styled from 'styled-components';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { Button } from '@/UI/Atoms/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  padding-top: ${({ theme }) => theme.spacing[32]};
  padding-bottom: ${({ theme }) => theme.spacing[12]};
  @media ${devices.XLarge} {
    align-items: center;
    padding-top: ${({ theme }) => theme.spacing[16]};
    padding-bottom: ${({ theme }) => theme.spacing[20]};
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${devices.XLarge} {
    align-items: flex-start;
  }
`;

export const Title = styled(Paragraph)`
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.migrateColors('text.default', 'secondary')};
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  max-width: 300px;
  @media ${devices.XLarge} {
    font-size: 25px;
    padding-bottom: ${({ theme }) => theme.spacing[4]};
    max-width: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
`;

export const Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  padding-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const Colored = styled.span`
  color: ${({ theme }) => theme.palette.emerald500};
  font-weight: 500;
`;

export const Link = styled.a`
  display: inline-block;

  .createAccountButton {
    margin-bottom: ${({ theme }) => theme.spacing[8]};
    path {
      fill: ${({ theme }) => theme.migrateColors('icon.btnPrimary', 'white')} !important;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 330px;
  height: 220px;
  @media ${devices.XLarge} {
    width: 500px;
    height: 340px;
  }
`;
