import React, { FC } from 'react';
import { CalendarShifts } from '@agendapro/emerald-icons';
import { Controller } from 'react-hook-form';
import { EmeraldThemeProvider, Datepicker } from '@agendapro/emerald/v2';

import { Icon, Paragraph } from '../../..';
import { ClientDateSelectorContainer } from './DateSelector.styles';

export const ClientDateSelector: FC<any> = ({ item, control, enableTime, rules, isCustom = true }) => (
  <EmeraldThemeProvider>
    <ClientDateSelectorContainer>
      <div className="labelContainer">
        <Paragraph className="label">{item.name}</Paragraph>
        {item.required && <span className="required-span">*</span>}
      </div>
      <div className="inputWrap">
        <Icon icon={<CalendarShifts className="calendar" />} />
        <Controller
          control={control}
          name={isCustom ? `custom_${item.slug}` : item.control}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Datepicker
              calendarStyle="input"
              placeholder={item.name}
              onChange={onChange}
              enableTime={enableTime}
              dateFormat={enableTime ? 'd-m-Y H:i' : 'd-m-Y'}
            />
          )}
          rules={rules}
        />
      </div>
    </ClientDateSelectorContainer>
  </EmeraldThemeProvider>
);
