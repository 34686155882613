import { FC } from 'react';
import { Paragraph } from '../Paragraph';
import { Wrapper } from './ButtonCategory.styles';

export const ButtonCategory: FC<{ label: string; onClick?: () => void; isSelected: boolean; isCharly: boolean }> = ({
  label,
  onClick,
  isSelected = false,
  isCharly = false,
}) => (
  <Wrapper onClick={onClick} isSelected={isSelected} isCharly={isCharly} data-testid="button-category">
    <Paragraph weight="light" title={label} size="subHeadline">
      {label}
    </Paragraph>
  </Wrapper>
);
