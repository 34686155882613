export const availableDateMapper = {
  toArray: ({ servicesWithProviders, servicesIds, isBundle }) =>
    Object.entries(servicesWithProviders).map((entry: any, index) => {
      const serviceId = entry[0];
      const serviceIndex = servicesIds.indexOf(serviceId);
      const orderId = serviceIndex === -1 ? index : serviceIndex;

      if (servicesIds.length > 1 || isBundle) {
        return entry[1].providerId === 0 ? null : [serviceId.split('-')[0], entry[1].providerId, orderId];
      }
      if (servicesIds.includes(entry[0])) {
        return entry[1].providerId === 0 ? null : [serviceId.split('-')[0], entry[1].providerId, orderId];
      }
      return null;
    }),
  toObject: ({ servicesWithProviders, servicesIds, isBundle }) => ({
    ...Object.entries(servicesWithProviders)
      .flatMap((entry: any, index) => {
        const serviceId = entry[0];
        const serviceIndex = servicesIds.indexOf(serviceId);
        const orderId = serviceIndex === -1 ? index : serviceIndex;

        if (servicesIds.length > 1 || isBundle) {
          return entry[1]?.providerId === 0 ? {} : { [`${serviceId.split('-')[0]}-${orderId}`]: entry[1]?.providerId };
        }

        if (servicesIds.includes(entry[0])) {
          return entry[1]?.providerId === 0 ? {} : { [`${serviceId.split('-')[0]}-${orderId}`]: entry[1]?.providerId };
        }
        return {};
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  }),
};
