import { FC } from 'react';
import { Close, Edit, Profile, TurnOn } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Link from 'next/link';

import { Icon, Paragraph } from '@/UI';
import { useAuthContext } from '@/hooks';
import { getNavRoutes, routes } from '@/utils';
import { SideBarProps } from './types';
import { logout } from '@/services/user/user.repo';
import * as St from './Sidebar.styles';

export const SideBar: FC<SideBarProps> = ({ handleOpenLoginModal, handleClose }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { auth, dispatch } = useAuthContext();
  const navRoutes = getNavRoutes(auth.userData, t);

  const revokeCookieAndLogoutUser = async () => {
    await logout();
    dispatch({ type: 'LOGOUT' });
    handleClose();
  };

  return (
    <St.Wrapper>
      <St.Header isLogged={!!auth.userData}>
        {auth.userData && (
          <Paragraph size="subHeadline" weight="bold" color={theme.palette.grays80}>
            {auth.userData.email}
          </Paragraph>
        )}
        <Icon icon={<Close />} size="medium" onClick={handleClose} data-testid="closeSideBar" />
      </St.Header>

      {!auth.userData && (
        <>
          <St.SideBarItem onClick={handleOpenLoginModal}>
            <Icon size="medium" icon={<Profile />} />
            <Paragraph weight="light">{t('NAV.LOGIN')}</Paragraph>
          </St.SideBarItem>
          {/* <Link href={routes.register} target="_blank">
            <St.SideBarItem>
              <Icon size="medium" icon={<Edit />} />
              <Paragraph weight="light">{t('NAV.REGISTER')}</Paragraph>
            </St.SideBarItem>
          </Link> */}
        </>
      )}

      {!!auth.userData && (
        <>
          {navRoutes.map((route) => (
            <St.SideBarItem key={route.name}>
              <a href={route.url} target="_blank" rel="noreferrer">
                <St.FlexSpan>
                  <Icon size="medium" icon={route.icon} />
                  {route.name}
                </St.FlexSpan>
              </a>
            </St.SideBarItem>
          ))}
          <St.SideBarItem onClick={revokeCookieAndLogoutUser}>
            <Icon size="medium" icon={<TurnOn />} />
            <Paragraph weight="light">{`${t('NAV.LOGOUT')}`}</Paragraph>
          </St.SideBarItem>
        </>
      )}
    </St.Wrapper>
  );
};
