import mixpanel from 'mixpanel-browser';
import { getCookie } from '@agendapro/sapphire';
import { ENV } from '@/config/env';
import { getDomainNameForCookies, setCookie } from '@/utils/cookieFunctions';

export const mixpanelInit = () => {
  const apiKey = ENV.MIXPANEL.APP_KEY;

  if (apiKey) {
    mixpanel.init(apiKey, {
      debug: false,
      track_pageview: false,
      persistence: 'localStorage',
      loaded(mixpanel) {
        const cookieDomain = getDomainNameForCookies();
        const distinctId = mixpanel.get_distinct_id();
        const cookieDistinctId = getCookie('ap_mp_distinct_id');
        const callSetCookie = () =>
          setCookie({ cname: 'ap_mp_distinct_id', cvalue: distinctId, exdays: 365, cookieDomain });

        if (cookieDistinctId) {
          if (cookieDistinctId.includes('$device:')) {
            mixpanel.register({
              distinct_id: cookieDistinctId,
              $device_id: cookieDistinctId.replace('$device:', ''),
            });
          } else {
            mixpanel.register({
              distinct_id: cookieDistinctId,
            });
          }
        } else {
          callSetCookie();
        }
        mixpanel.track_pageview();
      },
    });
  }
};

export const mixpanelTrackEvent = (name: string, data?: any) => {
  if (data) {
    mixpanel.track(name, data);
  } else {
    mixpanel.track(name);
  }
};
