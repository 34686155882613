import styled from 'styled-components';

import Image from 'next/image';
import { devices } from '@/UI/Styles';
import { applyEllipsisByNumOfLines, applyFlexAndCenter } from '@/styles';
import { InitialsAvatar } from '@/UI/Molecules/InitialsAvatar';

export const ProviderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  margin-bottom: 17px;
  .backIcon {
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }
  .name {
    ${applyEllipsisByNumOfLines()}
    text-transform: capitalize;
    margin: 8px 0px;
  }
  @media ${devices['no-mobile']} {
    flex-direction: row;
    max-width: 100%;
    justify-content: flex-start;
    margin-bottom: 24px;
    .name {
      margin: 0;
      padding-top: 0;
    }
  }
`;

export const CustomInitialsAvatar = styled(InitialsAvatar)`
  min-height: 67px;
  min-width: 67px;
  height: 67px;
  width: 67px;
  p {
    font-size: 30px;
  }
`;

export const ProviderPortraitContainer = styled.div`
  ${applyFlexAndCenter}
  height: 80px;
  width: 80px;
`;

export const ProviderPortrait = styled(Image)`
  border-radius: 50%;
  margin-bottom: 2px;
`;

export const DataContainer = styled.div`
  color: ${({ theme }) => theme.palette.grays100};
  text-align: center;
  .name {
    text-align: center;
  }

  @media ${devices['no-mobile']} {
    width: 100%;
    padding-left: ${({ theme }) => theme.spacing[24]};
    text-align: left;
    .name {
      text-align: start;
      width: 100%;
    }
  }
  .scheduleDiv {
    margin-bottom: 8px;
  }
`;

export const StyledLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.brandTheme.brand100};
  text-decoration: underline;
  cursor: pointer;
`;

export const LeftBox = styled.div`
  width: 40px;
  display: none;

  i {
    cursor: pointer;
  }

  @media ${devices['no-mobile']} {
    display: block;
  }
`;

export const RightBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media ${devices['no-mobile']} {
    flex-direction: row;
  }
`;
