import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@agendapro/emerald-icons';
import { Button } from '@agendapro/emerald/v2';
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive';
import { useReservation } from '@/context/ReservationsContext';

import * as St from './ModeSelection.styles';
import { devices, Drawer, Paragraph } from '@/UI';
import { usePageContext } from '@/hooks';

const SelectCard: FC<{ isSelected: boolean; mode: 'CONSECUTIVE' | 'SEPARATE'; onClick: () => void }> = ({
  isSelected,
  mode,
  onClick,
}) => {
  const { t } = useTranslation();
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  return (
    <St.SelectCard
      className="click-gtm"
      id={mode === 'CONSECUTIVE' ? 'button_select_chain_mode' : 'button_select_independent_mode'}
      isSelected={isSelected}
      onClick={onClick}
      data-testid={mode === 'CONSECUTIVE' ? 'button_consecutive_mode' : 'button_separate_mode'}
      data-cy={mode === 'CONSECUTIVE' ? 'button_consecutive_mode' : 'button_separate_mode'}
    >
      {isSelected && (
        <St.CheckContainer>
          <Check size={20} />
        </St.CheckContainer>
      )}
      <St.ImageContainer>
        <Image
          src={mode === 'CONSECUTIVE' ? '/assets/Consecutive.webp' : '/assets/Separate.webp'}
          blurDataURL={mode === 'CONSECUTIVE' ? '/assets/Consecutive.webp' : '/assets/Separate.webp'}
          height={isXLarge ? 160 : 100}
          width={isXLarge ? 220 : 130}
          quality={100}
          placeholder="blur"
        />
      </St.ImageContainer>
      <div>
        {mode === 'CONSECUTIVE' ? (
          <>
            <St.DescriptionTitle>{t('MODE_SELECTION.CONSECUTIVE_TITLE')}</St.DescriptionTitle>
            <St.Description weight="light">
              {t('MODE_SELECTION.CONSECUTIVE_DESCRIPTION_1')}
              <b>{` ${t('MODE_SELECTION.CONSECUTIVE_DESCRIPTION_2')}`}</b>
            </St.Description>
          </>
        ) : (
          <>
            <St.DescriptionTitle>{t('MODE_SELECTION.SEPARATE_TITLE')}</St.DescriptionTitle>
            <St.Description weight="light">
              {t('MODE_SELECTION.SEPARATE_DESCRIPTION_1')}
              <b>{` ${t('MODE_SELECTION.SEPARATE_DESCRIPTION_2')}`}</b>
            </St.Description>
          </>
        )}
      </div>
    </St.SelectCard>
  );
};

export const ModeSelection: FC = () => {
  const { reservations, dispatch } = useReservation();
  const { t } = useTranslation();
  const { bookingMode, servicesToReserve, providerPreSelected, canChooseProvider, serviceBeingReserved } = reservations;
  const { companyOverview } = usePageContext();
  const classesLength = servicesToReserve.filter((serv) => serv.classService)?.length;

  const handleMobileConfirm = () => {
    const uncompletedServiceIndex = servicesToReserve.findIndex(
      (service) => !service.hourSelected || !service.serviceProvider,
    );

    if (bookingMode === 'separate' && !serviceBeingReserved) {
      dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: servicesToReserve[uncompletedServiceIndex] });
      return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
    }
    if (bookingMode === 'separate' && canChooseProvider === 'CANT') {
      return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
    }
    return dispatch({ type: 'SET_STEP', payload: 'providerSelection' });
  };

  const handleSelectConsecutive = () => {
    const newServicesWithProviders = servicesToReserve.reduce(
      (acc, service) => ({
        ...acc,
        [service.internalId as string]: providerPreSelected
          ? { providerId: providerPreSelected.id, publicName: providerPreSelected.publicName }
          : { providerId: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') },
      }),
      {},
    );

    if (canChooseProvider !== 'MUST') {
      dispatch({
        type: 'SET_SERVICES_WITH_PROVIDERS',
        payload: newServicesWithProviders,
      });
    }
    dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: servicesToReserve[0] });
    dispatch({ type: 'SET_SERVICES_IDS', payload: servicesToReserve.map((service) => service.internalId!) });
    dispatch({ type: 'SET_BOOKING_MODE', payload: 'consecutive' });

    return dispatch({ type: 'SET_STEP', payload: 'providerSelection' });
  };

  const handleSelectSeparate = () => {
    const newServicesWithProviders = servicesToReserve.reduce(
      (acc, service) => ({
        ...acc,
        [service.internalId as string]:
          canChooseProvider !== 'CANT' || companyOverview?.isPlanSolo
            ? { providerId: providerPreSelected?.id, publicName: providerPreSelected?.publicName }
            : { providerId: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') },
      }),
      {},
    );

    if (providerPreSelected || canChooseProvider === 'CANT') {
      dispatch({
        type: 'SET_SERVICES_WITH_PROVIDERS',
        payload: newServicesWithProviders,
      });
    }
    if (!providerPreSelected && canChooseProvider !== 'CANT') {
      dispatch({ type: 'SET_SERVICES_WITH_PROVIDERS', payload: {} });
    }
    dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: null });
    dispatch({ type: 'SET_SERVICES_IDS', payload: undefined });
    dispatch({ type: 'SET_BOOKING_MODE', payload: 'separate' });

    const uncompletedServiceIndex = servicesToReserve.findIndex(
      (service) => !service.hourSelected || !service.serviceProvider,
    );

    dispatch({ type: 'SET_SERVICE_BEING_RESERVED', payload: servicesToReserve[uncompletedServiceIndex] });
    return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
  };

  useEffect(() => {
    if (classesLength && classesLength >= 1) {
      handleSelectSeparate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesLength]);

  return (
    <St.Wrapper>
      <St.Header>
        <Paragraph size="subHeadline">{`${t('MODE_SELECTION.TITLE_1')}`}</Paragraph>
      </St.Header>
      <St.Title size="subHeadline" weight="bold">
        {t('MODE_SELECTION.TITLE_2')}
      </St.Title>
      <St.Content>
        <SelectCard
          mode="CONSECUTIVE"
          isSelected={bookingMode === 'consecutive'}
          onClick={() => handleSelectConsecutive()}
        />
        <SelectCard mode="SEPARATE" isSelected={bookingMode === 'separate'} onClick={() => handleSelectSeparate()} />
      </St.Content>
      <Drawer>
        <St.DrawerContainer>
          <Button
            className="modeSelectionButtonNext"
            onClick={handleMobileConfirm}
            fullWidth
            disabled={!bookingMode}
            data-testid="mode_selection_button_next"
            data-cy="mode_selection_button_next"
          >
            {t('BUTTON_NEXT')}
          </Button>
        </St.DrawerContainer>
      </Drawer>
    </St.Wrapper>
  );
};
