import { useTranslation } from 'react-i18next';
import usePageContext from './usePageContext';

const useFormatCurrency = () => {
  const { t } = useTranslation();
  const { companyOverview } = usePageContext();
  const { unit, delimiter, separator } = companyOverview;
  const formatCurrency = (toFormat) => {
    if (toFormat === t('TO_DETERMINE')) {
      return t('TO_DETERMINE');
    }

    if (toFormat === 0 || typeof toFormat === 'string') {
      return `${unit}0`;
    }
    if (!toFormat) {
      return '';
    }

    const integerPart = toFormat
      .toString()
      .split('.')[0]
      .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    const decimalPart = toFormat.toString().split('.')[1]?.substring(0, 2);

    return `${unit}${integerPart}${decimalPart ? `${separator}${decimalPart}` : ''}`;
  };

  return formatCurrency;
};

export default useFormatCurrency;
