import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  text-align: center;
  margin-top: ${({ theme: { spacing } }) => spacing && spacing['8']};

  svg {
    margin-left: ${({ theme: { spacing } }) => spacing && spacing['4']};
  }
`;
