import { useQuery } from 'react-query';

import * as Repo from './services.repo';

export const useServices = (locationId, charlyToken) =>
  useQuery(['getServices', locationId, charlyToken], () => Repo.getServices(locationId, charlyToken), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const useCharlyInfo = (token: string | null) =>
  useQuery(['getCharlyInfo', token], () => Repo.getCharlyInfo(token), {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!token,
  });
