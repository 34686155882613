export const applyEllipsisByNumOfLines = (numOfLines = 1) => `
  display: -webkit-box;
  -webkit-line-clamp: ${numOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const applyFlexAndCenter = () => `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const applyBoxShadowSm = () => `
  box-shadow: 0px 4px 4px 0px #0000000a
`;

export const getCustomScrollBar = (color: string) => `
  ::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    height: 20px;
    background-color: ${color};
  }
`;

export const hideScrollBar = () => `
-ms-overflow-style: none;
scrollbar-width: none;
overflow-y: scroll;
::-webkit-scrollbar {
  display: none;
}
`;
