import { FC } from 'react';
import notEmpty from '@/utils/notEmpty';
import { Paragraph } from '../..';
import * as St from './InitialsAvatar.styles';

const getNameInitials = (name: string | undefined) => {
  if (!name) {
    return '';
  }

  const parsedName = name.replace(/[&/\\#,+()$~%'":*?<>{}]/g, '').trim();

  const stringWithoutDots = parsedName.split('.');

  const removeStringWithDotPrevious = parsedName.split('.').splice(1);

  let splitName = [''];

  if (stringWithoutDots[0].trim().length >= 5 && /^[A-Za-z]+/.test(stringWithoutDots[0])) {
    splitName = parsedName.split(' ').filter(notEmpty);
  } else if (removeStringWithDotPrevious.length > 0 && !!removeStringWithDotPrevious[0].length) {
    const nameDotRemoved = removeStringWithDotPrevious[0].trim();

    splitName = nameDotRemoved.split(' ').filter(notEmpty);
  } else {
    splitName = parsedName.split(' ').filter(notEmpty);
  }
  if (splitName.length === 1) {
    return splitName[0][0]?.toUpperCase();
  }
  if (splitName.length >= 2) {
    return `${splitName[0][0]?.toUpperCase()}${splitName[1][0]?.toUpperCase()}`;
  }

  return '';
};

export const InitialsAvatar: FC<{ className?: string; name: string; small?: boolean }> = ({
  className,
  name,
  small,
}) => (
  <St.Wrapper className={className} data-testid="initialsAvatar" small={small}>
    <Paragraph size="paragraph">{getNameInitials(name)}</Paragraph>
  </St.Wrapper>
);
