import styled from 'styled-components';
import { devices, fontFamily, fontSize } from '../../../Styles';

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  .errorMsg {
    color: ${({ theme }) => theme.palette.alert100};
    margin-top: 8px;
    margin-bottom: 0px;
  }

  .labelContainer {
    display: flex;
  }
  .required-span {
    margin-left: 4px;
    color: ${({ theme }) => theme.migrateColors('text.danger', 'alert')};
  }
  .inputLabel {
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  }
`;

export const InputContentContainer = styled.div<{ isError?: boolean }>`
  box-sizing: border-box;
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 100%;
  position: relative;
  i {
    margin-right: 8px;
  }
  @media ${devices['no-mobile']} {
    justify-content: flex-start;
    i {
      margin-right: 4px;
    }
  }
  .custom_select__control {
    border: ${({ isError, theme }) =>
      isError
        ? `1px solid ${theme.migrateColors('border.inputError', 'alert')}`
        : `1px solid ${theme.migrateColors('border.inputDefault', 'gray')}`};
    min-height: auto;
    height: auto;
    font-size: ${fontSize['inputs-text-mobile']};
    font-family: ${fontFamily};
    @media ${devices['no-mobile']} {
      font-size: ${fontSize['inputs-text-desktop']};
    }
  }
  .custom_select__menu {
    font-size: ${fontSize['inputs-text-mobile']};
    font-family: ${fontFamily};
    @media ${devices['no-mobile']} {
      font-size: ${fontSize['inputs-text-desktop']};
    }
  }
  .custom_select__indicator-separator {
    display: none;
  }
  .custom_select__placeholder {
    color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')} !important;
  }
`;
