import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { Button } from '@agendapro/emerald/v2';
import { devices } from '@/UI';
import * as St from './DemandDrivenSupply.styles';

const DemandDriven: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { countryCode } = router.query as { countryCode: string };
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });

  return (
    <St.Wrapper>
      <St.Content>
        <St.Title>{t('DEMAND_DRIVEN_SUPPLY.TITLE')}</St.Title>
        <St.Text>{t('DEMAND_DRIVEN_SUPPLY.TEXT')}</St.Text>
        <St.Link href="https://hubs.li/Q02zm0mJ0" target="_blank" rel="noreferrer">
          <Button className="createAccountButton" variant="primary">
            {t('DEMAND_DRIVEN_SUPPLY.BUTTON_TEXT')}
          </Button>
        </St.Link>
      </St.Content>
      <St.ImageContainer>
        <Image
          src={['cl', 'mx'].includes(countryCode) ? '/assets/phone-desktop-pos.png' : '/assets/phone-desktop.png'}
          alt="agendaPro"
          width={isXLarge ? 500 : 330}
          height={isXLarge ? 340 : 220}
          layout="fill"
          objectFit="fill"
        />
      </St.ImageContainer>
    </St.Wrapper>
  );
};

export default DemandDriven;
