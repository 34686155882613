import styled from 'styled-components';

import { applyBoxShadowSm, devices } from '@/UI/Styles';

export const SocialLoginContainer = styled.div`
  margin-top: 17px;
  justify-content: space-between;
  display: flex;
  width: 100%;

  @media ${devices['no-mobile']} {
    margin-top: 17px;
  }

  .socialText {
    display: none;
    @media ${devices['no-mobile']} {
      display: flex;
    }
  }
`;

export const SocialButton = styled.button<{ facebook?: boolean; whiteText?: boolean }>`
  width: 100%;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  background-color: ${({ facebook, theme }) => (facebook ? theme.palette.facebookBlue : theme.palette.white)};
  padding: 8px 0px;
  ${applyBoxShadowSm};
  .socialIcon {
    font-size: 25px;
  }
  p {
    color: ${({ whiteText, theme }) => whiteText && theme.palette.white};
  }
  @media ${devices['no-mobile']} {
    .socialIcon {
      margin-right: 8px;
    }
  }
`;

export const SocialIcon = styled.img`
  width: 25px;
  @media ${devices['no-mobile']} {
    margin-right: 8px;
  }
`;
