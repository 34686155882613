export const selectOptions = [
  {
    label: 'Sí',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
