import { ENV } from '@/config/env';

const getEvent = (actionType) => (actionType === 'reserve' ? 'reserve' : 'begin_checkout');

export const Gtag = {
  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  pageview(url) {
    window.gtag('config', ENV.GA_TRACKING_ID, {
      page_path: url,
    });
  },
  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event({ action, options = {} }) {
    window.gtag('event', getEvent(action), options);
  },
};
