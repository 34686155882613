import styled from 'styled-components';

import { fadeIn, keyFramesFadeIn } from '../../../utils';

type StyledNotificationModalProps = {
  type?: string;
  justifyButtons?: string;
};

export const NotificationModalContainer = styled.div<StyledNotificationModalProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.grays20};
  z-index: 5;
  ${keyFramesFadeIn}
  ${fadeIn()}
  .modal {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 8px;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white};
  }
  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    width: 95px;
    border-radius: 50%;
    margin-bottom: 16px;
    background-color: ${({ theme, type }) =>
      // eslint-disable-next-line no-nested-ternary
      type === 'success'
        ? theme.palette.primary100
        : type === 'error'
        ? theme.palette.alert100
        : theme.palette.accent100};
    .icon {
      svg {
        height: 60px;
        width: 60px;
        path {
          fill: ${({ theme }) => theme.palette.white};
        }
      }
    }
  }
  .title {
    color: ${({ theme }) => theme.palette.grays100};
    margin-bottom: 6px;
  }
  .text {
    color: ${({ theme }) => theme.palette.grays80};
    margin-bottom: 25px;
  }
  .messageContainer {
    width: 315px;
  }
  .buttonContainer {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: ${({ justifyButtons }) => justifyButtons || 'space-between'};
    .buttonCancel {
      padding: 12px 16px;
      color: ${({ theme }) => theme.palette.white};
      background-color: ${({ theme }) => theme.palette.grays50};
    }
    .buttonConfirm {
      padding: 12px 16px;
      color: ${({ theme }) => theme.palette.white};
      background-color: ${({ theme }) => theme.palette.primary100};
    }
  }
`;
