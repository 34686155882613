export const slideInLeft = {
  '-webkit-animation': 'slide-in-left 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  animation: 'slide-in-left 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
};

export const keyFramesSlideInLeft = `
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
`;
export const slideIn = {
  '-webkit-animation': 'slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
  animation: 'slide-in-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
};

export const keyFramesSlideIn = `
  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }`;

export const slideBottom = {
  '-webkit-animation': 'slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  animation: 'slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
};

export const keyFramesSlideBottom = `
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}

`;

export const fadeIn = (
  duration = 300,
) => `-webkit-animation: fade-in ${duration}ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
animation: fade-in ${duration}ms cubic-bezier(0.39, 0.575, 0.565, 1) both;`;

export const keyFramesFadeIn = `
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    bottom: -100%;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

`;
