import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@agendapro/emerald/v2';
import { Paragraph } from '../..';
import { useLockBodyScroll } from '../../../hooks';
import { ErrorModalContainer } from './ErrorModal.styles';

export const ErrorModal: FC<{ hide: () => void; service?: string; isCharly: boolean }> = ({
  hide,
  service,
  isCharly,
}) => {
  const { t } = useTranslation();

  useLockBodyScroll();

  return (
    <ErrorModalContainer>
      <div className="errorContainer">
        <Paragraph className="title">{`${t('OOPS')}`}</Paragraph>
        {isCharly ? (
          <>
            <Paragraph weight="bold" className="errorText">{`${t('ERROR_MODAL.CHARLY_FIRST')}`}</Paragraph>{' '}
            {/* <Paragraph weight="bold" className="endText">{`${t('ERROR_MODAL.CHARLY_SECOND')}`}</Paragraph> */}
          </>
        ) : (
          <>
            <Trans>
              <Paragraph weight="light" className="errorText" data-testid="serviceText">
                {t('ERROR_MODAL.NOT_POSSIBLE_TO_SELECT', {
                  service,
                })}
              </Paragraph>
            </Trans>
            <Paragraph weight="light" className="endText">{`${t('ERROR_MODAL.IF_YOU_WISH')}`}</Paragraph>
          </>
        )}
        <div>
          <Button className="button" onClick={() => hide()}>{`${t('I_UNDERSTAND')}`}</Button>
        </div>
      </div>
    </ErrorModalContainer>
  );
};
