import styled, { css } from 'styled-components';
import { devices } from '@/UI/Styles';
import { fadeIn, keyFramesFadeIn, keyFramesSlideIn } from '@/utils/animationModal';
import { applyFlexAndCenter } from '@/styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const NonDesktopContainer = styled.div`
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const ServiceProviderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 17px;
  .providerData {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    .providerSchedule {
      display: none;
    }
    @media ${devices.XLarge} {
      margin-top: 0;
      margin-left: 25px;
      .providerSchedule {
        display: flex;
      }
    }
  }
  @media ${devices.XLarge} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  @keyframes scaleUp {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .progressBar {
    @media ${devices.XLarge} {
      div {
        width: 100%;
      }
    }
  }
  ${keyFramesFadeIn}
  ${keyFramesSlideIn}

  .desktopDrawer {
    display: none;
    @media ${devices.XLarge} {
      z-index: 5;
      display: flex;
      box-shadow: 0px 0px 15px rgba(57, 65, 73, 0.08);
      position: absolute;
      bottom: 0%;
      .hidden {
        visibility: hidden;
      }
    }
    .button {
      width: 120px;
    }
  }
`;
export const MobileModalsContainer = styled.div`
  @media ${devices['no-mobile']} {
    padding: 40px;
    position: relative;
    min-height: calc(100vh - 69px);
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
  }
  @media ${devices.XLarge} {
    display: none;
  }
`;

export const DesktopModalsContainer = styled.div<{ postPay?: boolean }>`
  min-height: 100vh;
  display: none;
  background-color: ${({ theme, postPay }) =>
    postPay
      ? theme.migrateColors('background.primary', 'white')
      : theme.migrateColors('background.tertiary', 'background')};
  @media ${devices.XLarge} {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  .modalsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
    padding-top: 40px;
  }
`;

export const Spacer = styled.div`
  @media ${devices.XLarge} {
    min-height: 120px;
  }
`;

export const ModalsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'background')};
  padding-top: 16px;
`;

export const NoServiceSelectedContainer = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  width: 876px;
  height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
`;

export const NoServiceSelectedTop = styled.div`
  width: 100%;
  margin-top: 25px;
  .provider {
    margin-left: 50px;
  }
  .informationText {
    margin-left: 25px;
    margin-bottom: 12px;
  }
  .colorBar {
    margin-top: 12px;
  }
`;

export const ImageHeader = styled.div`
  margin-top: 90px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  margin-top: 12px;
`;

export const ReservationButtonsWrap = styled.div<{ centered: boolean }>`
  column-gap: 15px;
  display: flex;
  width: 100%;

  button {
    width: 100%;
  }

  .buttonPayAndReserve {
    background-color: ${({ theme }) => theme.brandTheme.brand100};
  }

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
`;

export const ContinueBtnWrapper = styled.div`
  display: block;
`;

export const BookingLoaderTitle = styled(Paragraph)`
  font-size: 30px;
`;

export const BookingLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10;
  ${applyFlexAndCenter}
  ${fadeIn(300)}
  ${keyFramesFadeIn}
`;

export const BookingLoaderContent = styled.div`
  width: 370px;
  height: 370px;
  z-index: 100000;
  padding: ${({ theme }) => `${theme.spacing[36]} ${theme.spacing[32]}`};
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  p {
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  button {
    background-color: ${({ theme }) => theme.brandTheme.brand100};
    &:hover {
      background-color: ${({ theme }) => theme.brandTheme.brand100};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.brandTheme.brand80};
    }
  }
`;
