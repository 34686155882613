const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));

  return `rgba(${r},${g},${b},${alpha})`;
};

export const getBrandColors = (hex) => {
  const brand100 = hexToRgba(hex, 1);
  const brand80 = hexToRgba(hex, 0.8);
  const brand50 = hexToRgba(hex, 0.5);
  const brand20 = hexToRgba(hex, 0.2);
  const brand02 = hexToRgba(hex, 0.02);

  return { brand100, brand80, brand50, brand20, brand02 };
};

export const getRGBColor = (hex, type) => {
  if (hex) {
    const color = hex.replace(/#/g, '');
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    return `--color-${type}: ${r}, ${g}, ${b};`;
  }
  return '';
};
