import { FC } from 'react';
import { BannerLogoProps } from './BannerLogo.types';
import * as St from './BannerLogo.styles';

export const BannerLogo: FC<BannerLogoProps> = ({ banner, collapsed }) => (
  <St.Wrapper collapsed={collapsed}>
    {!collapsed && banner ? (
      <St.NextImage data-testid="banner" src={banner} layout="responsive" alt="company banner" />
    ) : (
      <St.BannerGradient />
    )}
  </St.Wrapper>
);
