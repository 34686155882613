import { createContext, useReducer, useContext, Reducer } from 'react';

import { reservationsReducer } from './ReservationsReducer';

import {
  ReservationsProviderProps,
  ReservationStateProps,
  ReservationContextActions,
  ReservationContext,
} from './ReservationContext.types';

export const ReservationsContext = createContext<ReservationContext>({} as ReservationContext);

ReservationsContext.displayName = 'Reservations';

export const ReservationsProvider = ({ children, initialState }: ReservationsProviderProps) => {
  const [reservations, dispatch] = useReducer<Reducer<ReservationStateProps, ReservationContextActions>>(
    reservationsReducer,
    initialState,
  );

  return <ReservationsContext.Provider value={{ reservations, dispatch }}>{children}</ReservationsContext.Provider>;
};

export const useReservation = () => useContext(ReservationsContext);
