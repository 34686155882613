import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '@/UI';
import { ClientSummaryProps } from './types';
import * as St from './ClientSummary.styles';

export const ClientSummary: FC<ClientSummaryProps> = ({ name, email, phone }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <St.Wrapper>
      <St.CalendarIcon size={100} />
      <St.UserData>
        <St.DataTitle>{`${t('RESERVATION_DATA')}`}</St.DataTitle>
        <Paragraph weight="bold">{name}</Paragraph>
        <Paragraph weight="light" color={palette.grays80}>
          {email}
        </Paragraph>
        <Paragraph weight="light" color={palette.grays80}>
          {phone}
        </Paragraph>
      </St.UserData>
    </St.Wrapper>
  );
};
