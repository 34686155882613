import { useCallback, useState } from 'react';

export const useShowComponent = (isShownDefault = false) => {
  const [isShown, setIsShown] = useState(isShownDefault);

  const hide = useCallback(() => setIsShown(false), []);
  const show = useCallback(() => setIsShown(true), []);
  const toggle = useCallback(() => setIsShown((shown) => !shown), []);

  return { isShown, show, hide, toggle };
};
