import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palette.white};
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: -17px -15px 0px -15px;
  box-shadow: 0px 15px 30px -32px rgba(0, 0, 0, 0.7) inset;
  @media ${devices['no-mobile']} {
    margin: 0px;
  }
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  .notificationBackground {
    background-color: ${({ theme }) => theme.palette.white};
  }
  .checkListContainer {
  }
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;
export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  @media ${devices['no-mobile']} {
    width: 900px;
    padding: 60px 0px;
  }
  > div:not(:last-child) {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
  }
  .checkListTitle {
    margin-bottom: 40px;
    @media ${devices['no-mobile']} {
      margin-bottom: 12px;
    }
  }
`;

export const Item = styled.div`
  padding: 15px 0px;
  align-items: center;
  display: flex;
  .itemText {
    color: ${({ theme }) => theme.palette.grays80};
    margin-left: 6px;
  }
`;
