import { Menu } from '@agendapro/emerald-icons';
import styled from 'styled-components';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { devices } from '@/UI/Styles';

export const Wrapper = styled.div<{ isDragging: boolean }>`
  &:first-child {
    margin-top: 0;
  }

  margin-top: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  padding: 11px 11px 11px 16px;
  border: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray')};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: ${({ isDragging }) => (isDragging ? 0.6 : 1)};
  @media ${devices.XLarge} {
    margin-top: 16px;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray')};
  padding-bottom: 8px;
`;

export const MenuIcon = styled(Menu)`
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.migrateColors('border.primary', 'gray')};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  gap: ${({ theme }) => theme.spacing[8]};

  .moreIcon {
    path {
      fill: ${({ theme }) => theme.migrateColors('icon.subtle', 'gray30')};
    }
  }
`;

export const MenuAction = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  text-align: right;
  cursor: pointer;
  width: 35%;
`;

export const SelectedProviderName = styled(Paragraph)`
  margin-top: 8px;
  margin-left: 30px;
`;
