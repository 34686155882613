import React, { FC, forwardRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { devices } from '@/UI/Styles';
import * as St from './StepDialog.styles';
import { TopBar } from './TopBar/TopBar';
import { useLockBodyScroll } from '@/hooks';

export const StepDialog: FC<{
  handleClose?: () => void;
  handleCloseNow?: () => void;
  handlePrevious: () => void;
  mainDialog?: React.ReactElement;
  previousDisabled: boolean;
  shouldCloseNow?: boolean;
  sideDialog?: React.ReactElement;
  ref: React.Ref<HTMLDivElement>;
}> = forwardRef(
  (
    { children, handleClose, handleCloseNow, handlePrevious, mainDialog, previousDisabled, shouldCloseNow, sideDialog },
    ref,
  ) => {
    const isXLarge = useMediaQuery({
      query: devices.XLarge,
    });

    useLockBodyScroll();
    return (
      <St.Wrapper>
        <TopBar
          handleClose={handleClose}
          handleCloseNow={handleCloseNow}
          shouldCloseNow={shouldCloseNow}
          handlePrevious={handlePrevious}
          previousDisabled={previousDisabled}
        />
        <St.DialogContainer postPay={false} ref={ref}>
          <St.MainDialog>{mainDialog}</St.MainDialog>
          {isXLarge && <St.SideDialog>{sideDialog}</St.SideDialog>}
        </St.DialogContainer>
        {children}
      </St.Wrapper>
    );
  },
);
