import styled from 'styled-components';
import { Collapse } from '@agendapro/emerald/v2';
import { devices } from '@/UI/Styles';

export const Wrapper = styled.div`
  width: 100%;
`;

export const ReservationSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${devices['no-mobile']} {
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  padding: 20px 0px;
`;

export const TotalWrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  padding: ${({ theme }) => theme.spacing[16]};
  border: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;

  p {
    align-items: center;
  }

  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
`;

export const Accordion = styled(Collapse)`
  width: 100%;
  padding: ${({ theme: { spacing } }) => `${spacing[8]} ${spacing[16]}`};
  gap: ${({ theme: { spacing } }) => spacing[8]};
  margin-bottom: ${({ theme: { spacing } }) => spacing[8]};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.md};
  background-color: ${({
    theme: {
      tokens: { color },
    },
  }) => color.background.primary};
  border: ${({
    theme: {
      tokens: { color },
    },
  }) => `1px solid ${color.border.primary}`};
`;

export const AccordionButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const OnlineDiscountOffer = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  width: ${({ isOpen }) => (isOpen ? '100%' : '135%')};
  height: 24px;
  padding: ${({ theme }) => `${theme.spacing[4]} ${theme.spacing[8]}`};
  gap: ${({ theme }) => theme.spacing[4]};
  border-radius: 2px 6px 2px 6px;
  background-color: #2c39411a;
`;
