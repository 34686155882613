export const screenBreakpoints = {
  xs: 0,
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xll: '1400px',
  Desktop: '1920px',
};

export const devices = {
  XSmall: `(min-width: ${screenBreakpoints.xs})`,
  'no-mobile': `(min-width: ${screenBreakpoints.sm})`,
  Medium: `(min-width: ${screenBreakpoints.md})`,
  Large: `(min-width: ${screenBreakpoints.lg})`,
  XLarge: `(min-width: ${screenBreakpoints.xl})`,
  XXLarge: `(min-width: ${screenBreakpoints.xll})`,
  Desktop: `(min-width: ${screenBreakpoints.Desktop})`,
};

export const devicesPx = {
  'no-mobile': 576,
  Medium: 768,
  Large: 992,
  XLarge: 1200,
  XXLarge: 1400,
  Desktop: 1920,
};
