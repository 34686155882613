import { ENV } from '@/config/env';

const getFaviconElement = () => document.getElementById('favicon');

export const setTitleAndFavicon = (company) => {
  document.title = company.name;
  const favicon = getFaviconElement();

  favicon.href = company.logo;
};

export const setCookie = (cookieName, cookieValue, expireDays) => {
  const date = new Date();
  const expiresIn = expireDays * 24 * 60 * 60 * 1000;

  date.setTime(date.getTime() + expiresIn);
  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${cookieName}=${cookieValue};${expires};path=/; domain=${ENV.COOKIE_DOMAIN}`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; path=/; domain=${ENV.COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');

  for (let i = 0; i < cookiesArray.length; i += 1) {
    let cookie = cookiesArray[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

export const redirectToLocation = (history, countryCode, id) => {
  window.scrollTo(0, 0);
  history.push(`/${countryCode}/sucursal/${id}`);
};
