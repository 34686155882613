import { useRouter } from 'next/router';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, Text } from '@agendapro/emerald/v2';
import { useTheme } from 'styled-components';
import { useIframeRouter } from '@/hooks';
import { RequestErrorModalProps } from './RequestErrorModal.types';

export const RequestErrorModal: FC<RequestErrorModalProps> = ({
  errorDetail,
  redirectTo,
  onConfirm,
  onClose,
  locationPhone,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { migrateColors } = useTheme();
  const { routerReplace } = useIframeRouter();
  const { countryCode, locationId } = router.query as { countryCode: string; locationId: string };

  const redirect = () => {
    switch (redirectTo) {
      case 'home':
        return routerReplace(`/${countryCode}`);
      case 'branchOffice':
        return routerReplace(`/${countryCode}/sucursal/${locationId}`);
      case 'reload':
        return router.reload();
      case 'previous':
        return router.back();
      default:
        return undefined;
    }
  };

  const close = () => onClose?.();
  const confirm = () => {
    if (redirectTo) {
      redirect();
    } else {
      onConfirm?.();
    }
    onClose?.();
  };

  return (
    <div className="[&>*:first-child]:flex [&>*:first-child]:flex-col [&>*:first-child]:justify-center">
      <Modal
        className="[&>div:first-child]:mb-4 [&>div:last-child]:justify-center min-w-[300px]"
        showSaveBtn
        saveBtnText={t('ERRORS.SAVE_MODAL')}
        width="25%"
        iconClose={false}
        onSave={confirm}
        data-testid="alert-modal"
      >
        <div className="flex flex-col">
          <Text
            type="h3"
            color={migrateColors('text.default', 'gray')}
            textAlign="center"
            className="flex justify-center mb-5"
          >
            <Trans>{t('ERRORS.TITLE_MODAL')}</Trans>
          </Text>
          <Text
            textAlign="center"
            color={migrateColors('text.subtle', 'gray80')}
            className={`w-3/4 mx-auto ${locationPhone ? 'mb-3' : 'mb-7'}`}
          >
            {errorDetail}
          </Text>
          {locationPhone && (
            <Text textAlign="center" color={migrateColors('text.subtle', 'gray80')} className="w-3/4 mx-auto mb-7">
              {t('TRY_AGAIN')}
              <a href={`tel:${locationPhone}`} className="underline text-primary" data-testid="phoneLinkAlert">
                {t('PHONE_LINK_CONTACT')}
              </a>
            </Text>
          )}
        </div>
      </Modal>
    </div>
  );
};
