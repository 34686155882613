import { FC } from 'react';
import { Profile } from '@agendapro/emerald-icons';

import { Icon, Paragraph } from '@/UI';

import { ServiceProviderButtonProps } from './ServiceProviderButton.types';
import * as St from './ServiceProviderButton.styles';

export const ServiceProviderButton: FC<ServiceProviderButtonProps> = ({
  image,
  name,
  schedule,
  onClick,
  isSelected,
  noPreference,
}) => (
  <St.Wrapper
    type="button"
    onClick={onClick}
    isSelected={isSelected}
    data-testid={`${name}_button`}
    data-cy="provider_select_button"
  >
    {!image && noPreference && (
      <St.DefaultAvatar>
        <Icon icon={<Profile />} size="xSmall" />
      </St.DefaultAvatar>
    )}
    {!image && !noPreference && <St.CustomInitialsAvatar name={name} />}
    {image && <St.ProviderImage src={image} alt="ProviderImage" height={38} width={38} />}
    <St.ProviderTextContainer>
      <Paragraph size="paragraph" weight="regular">
        {name}
      </Paragraph>
      <Paragraph weight="light" size="small" className="schedule">
        {schedule}
      </Paragraph>
    </St.ProviderTextContainer>
  </St.Wrapper>
);
