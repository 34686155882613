import React from 'react';
import { StyledSkeleton } from './ServiceSkeletons.styles';

const ServiceSkeletons = () => (
  <>
    {[...Array(4).keys()].map((el) => (
      <StyledSkeleton key={el} />
    ))}
  </>
);

export default ServiceSkeletons;
