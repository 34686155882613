import styled from 'styled-components';

export const FormAlertContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  p {
    margin-left: 8px;
    align-self: center;
  }
  padding: 12px;
  border-radius: 8px;

  &.success {
    background: ${({ theme }) => theme.palette.primary100};
    color: ${({ theme }) => theme.palette.white};
  }

  &.danger {
    background: ${({ theme }) => theme.palette.alert100};
    color: ${({ theme }) => theme.palette.white};
  }
`;
