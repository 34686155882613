import styled from 'styled-components';

import { applyBoxShadowSm, devices, fontSize, fontWeight } from '@/UI/Styles';
import { getCustomScrollBar } from '@/styles/mixins';
import { Drawer } from '@/UI/Atoms/Drawer';

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing[20]};

  @media ${devices.XLarge} {
    margin-bottom: 0px;
  }

  .labelContainer {
    display: flex;
  }
  .required-span {
    margin-left: 4px;
    color: ${({ theme }) => theme.migrateColors('text.danger', 'alert')};
  }

  .labelInput {
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  }

  .dropdown {
    border-radius: 8px;

    .country {
      :hover {
        background-color: ${({ theme }) => theme.brandTheme.brand20};
      }
    }

    .highlight {
      background-color: ${({ theme }) => theme.brandTheme.brand20};
    }
    ${({ theme }) => getCustomScrollBar(theme.migrateColors('background.tertiary', 'gray20'))}
  }

  .phoneContainer {
    display: flex;
    align-items: center;
  }

  .phoneInput {
    border: 1px solid ${({ theme }) => theme.migrateColors('border.inputDefault', 'gray')};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    width: 100%;

    &:focus-visible {
      border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
    }

    @media ${devices.XLarge} {
      font-size: ${fontSize['inputs-text-desktop']};
    }
  }

  .phoneButton {
    top: auto;
    bottom: auto;
    background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    padding-left: 4px;
    border-radius: 4px 0 0 4px;
    border: none;
  }

  .errorMsg {
    margin-top: 8px;
    color: ${({ theme }) => theme.migrateColors('text.inputError', 'alert')};
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 500px;
  width: 100%;

  .button-continue {
    background-color: ${({ theme }) => theme.brandTheme.brand100};
  }

  @media ${devices.Medium} {
    max-width: 500px;
  }
`;

export const SalfaDataContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const SalfaFormContainer = styled.div`
  padding: ${({ theme }) => theme.spacing[24]};
`;

export const ParagraphSubtitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
`;

export const FormContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing[24]} ${theme.spacing[24]} 0px`};
  width: 100%;
  @media ${devices.XLarge} {
    max-height: 50vh;
    overflow: scroll;
  }
`;

export const SelectContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[20]};
`;

export const CheckboxContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[12]};
  display: flex;
  align-items: center;
`;

export const BackgroundCheckContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const FormClientData = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 20px; */

  @media ${devices.XLarge} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-template-areas:
      '. .'
      '. .'
      '. .'
      'textarea textarea';
  }
`;

export const BookingPolicy = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${fontWeight.light};
  color: ${({ theme }) => theme.migrateColors('text.subtler', 'gray80')};
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[20]} 80px`};
  @media ${devices['no-mobile']} {
    padding-bottom: ${({ theme }) => theme.spacing[12]};
  }
`;

export const PolicyLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.migrateColors('text.subtler', 'gray80')};
`;

export const ColorSpan = styled.span`
  color: ${({ theme }) => theme.brandTheme.brand100};
`;

export const SubtitleBox = styled.div`
  padding: 0 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  @media ${devices['no-mobile']} {
    border-radius: 0;
  }
  @media ${devices.XLarge} {
    border-radius: 8px;
  }

  .modalTitle {
    display: flex;
    padding: ${({ theme }) => theme.spacing[20]};
    border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray')};
  }

  .progressBar {
    display: none;
    @media ${devices.XLarge} {
      display: flex;
      width: 100%;
    }
  }

  .spacing {
    display: none;
    @media ${devices.XLarge} {
      display: flex;
      margin-top: 16px;
    }
  }

  .subtitleClient {
    color: ${({ theme }) => theme.migrateColors('text.subtler', 'gray80')};
    margin-top: 24px;
  }

  .subtitle {
    color: ${({ theme }) => theme.brandTheme.brand100};
  }

  .policyLink {
    text-decoration: none;
    color: ${({ theme }) => theme.migrateColors('text.subtler', 'gray80')};

    span {
      color: ${({ theme }) => theme.brandTheme.brand100};
    }
  }

  .bookingPolicy {
    display: flex;
    align-items: center;
    padding: 24px;
    padding-top: ${({ theme }) => theme.spacing[16]};
    font-weight: ${fontWeight.light};
    color: ${({ theme }) => theme.migrateColors('text.subtler', 'gray80')};
    ${applyBoxShadowSm}
    @media ${devices.XLarge} {
      padding-bottom: ${({ theme }) => theme.spacing[12]};
    }
  }

  .continueBtn {
    margin-top: 30px;
    padding: 17px;

    @media ${devices.XLarge} {
      display: none;
    }
  }
`;

export const CustomDrawer = styled(Drawer)<{ showDrawer: boolean }>`
  display: ${({ showDrawer }) => (showDrawer ? 'flex' : 'none')};
  @media ${devices.XLarge} {
    display: none;
  }
`;
