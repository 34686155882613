import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

export const useDayLocale = (date: string, locale: string = 'es', format: string = 'LLLL a') => {
  const [localeDate, setLocaleDate] = useState<string>();

  useEffect(() => {
    const getLocale = async () => {
      if (locale === 'es') {
        const module = await import('dayjs/locale/es');

        dayjs.locale(module);
        setLocaleDate(dayjs(date).utcOffset(0).format(format));
      }
    };

    getLocale();
    return () => {
      setLocaleDate(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return localeDate;
};
