import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const Content = styled.div`
  color: ${({ theme }) => theme.palette.grays80};
  .headerText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: ${({ theme }) => theme.brandTheme.brand100};
      margin-bottom: 8px;
    }
  }

  form {
    text-align: left;
    margin-top: 17px;
    @media ${devices['no-mobile']} {
      margin-top: 25px;
    }

    .emailField {
      margin-bottom: 17px;
      @media ${devices['no-mobile']} {
        margin-bottom: 25px;
      }
    }
  }

  .link {
    display: block;
    text-align: left;
    color: ${({ theme }) => theme.brandTheme.brand100};
    margin: 8px 0 17px 0;
    p {
      font-size: 14px;
    }
    @media ${devices['no-mobile']} {
      margin: 12px 0 25px 0;
    }
  }

  .rememberField {
    margin-bottom: 17px;
    @media ${devices['no-mobile']} {
      margin-bottom: 25px;
    }
  }

  .registerContainer {
    display: flex;
    justify-content: center;
    p:last-child {
      color: ${({ theme }) => theme.brandTheme.brand100};
      margin-left: 5px;
    }
    margin-top: 17px;
    @media ${devices['no-mobile']} {
      margin-top: 12px;
    }
  }
`;
