import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.tertiary', 'white')};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 8px;

  @media ${devices['no-mobile']} {
    padding: 0;
  }

  .continueBtn {
    padding: ${({ theme }) => theme.spacing[8]};
    @media ${devices.XLarge} {
      width: 100%;
    }
  }
`;

export const Title = styled(Paragraph)`
  font-size: 14px;
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray')};
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};
  border-radius: 8px 8px 0 0;
  padding: ${({ theme }) => theme.spacing[20]};
  z-index: 1;
  @media ${devices['no-mobile']} {
    text-align: center;
    border-radius: 0;
  }
  @media ${devices.XLarge} {
    border-radius: 8px 8px 0 0;
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  width: 100%;
`;

export const SessionName = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px 15px 16px;
  @media ${devices['no-mobile']} {
    display: none;
  }
`;

export const ServiceButtonWrapper = styled.div<{ noBorderRadius: boolean }>`
  background-color: ${({ theme }) => theme.migrateColors('background.secondary', 'background50')};
  height: 100%;
  border-radius: 0 0 8px 8px;
  @media ${devices.XLarge} {
    max-height: 500px;
    overflow-y: auto;
  }
`;

export const AddSession = styled.div<{ isEnabled: boolean }>`
  cursor: ${({ isEnabled }) => (isEnabled ? 'pointer' : 'default')};
  margin: ${({ theme }) => theme.spacing[12]} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  path {
    fill: ${({ theme, isEnabled }) => (isEnabled ? theme.tokens.color.icon.default : theme.tokens.color.icon.subtle)};
  }
  p {
    font-size: 14px;
    color: ${({ theme, isEnabled }) => (isEnabled ? theme.tokens.color.text.default : theme.tokens.color.text.subtler)};
    text-decoration: underline;
    margin-left: 5px;
    transition: 200ms ease-in-out;
  }
`;

export const SeeAllDates = styled.div`
  display: none;
  padding: 6px;
  padding-top: ${({ theme }) => theme.spacing[24]};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.subtle', 'gray')};
  }
  @media ${devices['no-mobile']} {
    display: flex;
  }
`;
export const RemoveProviderText = styled(Paragraph)`
  width: 343px;
  text-align: center;
  color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
`;

export const RemoveProvider = styled.button`
  padding-top: ${({ theme }) => theme.spacing[16]};
  cursor: pointer;
  p {
    text-decoration: underline;
    color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  }
`;

export const Spacer = styled.div`
  height: 150px;
  @media ${devices['no-mobile']} {
    height: 0px;
  }
`;

export const ChangeBookingMode = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: ${({ theme }) => theme.migrateColors('text.default', 'gray80')};
  padding-top: ${({ theme }) => theme.spacing[20]};
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
`;
