import styled, { css } from 'styled-components';

import { devices } from '@/UI/Styles/Queries';

export const Container = styled.div<{ isActive?: boolean }>`
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  opacity: ${({ isActive }) => (!isActive ? 0.5 : 1)};
  width: 100%;
  overflow-y: hidden;
  min-height: calc(100dvh - 72px);
`;

export const AppContent = styled.div<{ isActive?: boolean; noPadding?: boolean }>`
  pointer-events: ${({ isActive }) => (!isActive ? 'none' : 'auto')};
  padding: 0px;

  ${({ noPadding }) =>
    noPadding &&
    `padding: 0 !important;
    display: flex !important;
    flex-grow: 1 !important;
    `}
`;
export const LoaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div<{ selectedServices?: boolean }>`
  transition: 0.35s all;
  ${({ selectedServices }) =>
    selectedServices &&
    css`
      margin-bottom: 60px;
    `}
  footer {
    ${({ selectedServices }) =>
      selectedServices &&
      css`
        display: none;
      `}
  }
`;
