import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@agendapro/emerald-icons';
import { useRouter } from 'next/router';
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/style.css';

import { Paragraph } from '@/UI';
import useLockBodyScroll from '@/hooks/lockBodyScroll';
import { emailPatternValidation, formatIdCl } from '@/utils';
import usePageContext from '@/hooks/usePageContext';
import { useExistentClient } from '@/services/reservations';
import * as St from './ExistentClientModal.styles';

const ExistentClientModal: FC<{
  handleClose: () => void;
  showReservationModal: () => void;
  setReservationsInitialState: (value) => void;
  identificadorType: number;
}> = ({ handleClose, showReservationModal, setReservationsInitialState, identificadorType }) => {
  useLockBodyScroll();
  const router = useRouter();
  const { countryCode } = router.query as { countryCode: string };
  const { companyOverview, companySlug, currentLocation } = usePageContext();
  const { mutateAsync: existingClientMutation, isLoading } = useExistentClient();
  const [clientId, setClientId] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'id') {
      const formattedId = formatIdCl(value);

      setClientId(countryCode === 'cl' ? formattedId : value);
      setError(null);
    } else {
      const isEmailValid = emailPatternValidation.test(value);

      setError(isEmailValid ? null : t('ERRORS.VALIDATION_EMAIL_INVALID'));
      setClientId(value);
    }
  };

  const identificadorTypes = {
    0: 'identificationNumber',
    1: 'email',
    2: 'phone',
  };

  const handleGetExistingClient = () => {
    setError(null);
    if (!clientId) {
      setError(`${t('ERRORS.ID_REQUIRED')}`);
    } else if (clientId.length < 2) {
      setError(`${t('ERRORS.ID_TOO_SHORT')}`);
    } else {
      existingClientMutation(
        { clientId, identificadorType, locationId: currentLocation?.id },
        {
          onSuccess: (data) => {
            if (data.validId && data.canBook) {
              setReservationsInitialState((prevState) => ({
                ...prevState,
                client: { ...prevState.client, [identificadorTypes[identificadorType]]: clientId },
              }));
              showReservationModal();
              handleClose();
            }
            if (!data.canBook) {
              setError(`${t(`ERRORS.CLIENT_NOT_ALLOWED_TO_BOOK`)} ${companyOverview?.name}`);
            }
            if (!data.validId) {
              const idError = identificadorType === 0 ? t('ERRORS.ID_ENTERED_NOT_FOUND') : null;
              const emailError = identificadorType === 1 ? t('ERRORS.EMAIL_ENTERED_NOT_FOUND') : null;
              const phoneError = identificadorType === 2 ? t('ERRORS.PHONE_ENTERED_NOT_FOUND') : null;

              setError(idError || emailError || phoneError);
            }
          },
          onError: (data) => {
            setError(`${t('ERRORS.SERVER_RESPONSE')}`);
          },
        },
      );
    }
  };

  const handlePhoneChange = (value) => {
    setError(value.length <= 8 ? t('ERRORS.VALIDATION_PHONE_TOO_SHORT') : null);
    setClientId(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !(isLoading || !clientId || !!error)) {
      handleGetExistingClient();
    }
  };

  const Input = () => {
    switch (identificadorType) {
      case 0:
        return (
          <>
            <Paragraph weight="bold">{t('LABEL_ID')}</Paragraph>
            <St.IdInput
              data-testid="idInput"
              data-cy="id_input"
              showError={error}
              placeholder={countryCode === 'cl' ? `${t('INPUT_ID_PLACEHOLDER')}` : undefined}
              value={clientId}
              onChange={handleIdChange}
              name="id"
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </>
        );
      case 1:
        return (
          <>
            <Paragraph weight="bold">{t('LABEL_EMAIL')}</Paragraph>
            <St.IdInput
              data-testid="emailInput"
              data-cy="email_input"
              showError={error}
              placeholder="juan@mail.com"
              value={clientId}
              onChange={handleIdChange}
              name="email"
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </>
        );
      case 2:
        return (
          <>
            <Paragraph weight="bold">{t('LABEL_PHONE')}</Paragraph>
            <PhoneInput
              localization={es}
              country={countryCode}
              value={clientId}
              buttonClass="phoneButton"
              inputClass="phoneInput"
              containerClass="phoneContainer"
              dropdownClass="dropdown"
              onChange={handlePhoneChange}
              placeholder="+56 912 332 347"
              inputProps={{ autoFocus: true }}
              onKeyDown={handleKeyDown}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <St.Wrapper>
      <St.CustomCard>
        <St.CloseIconContainer onClick={handleClose}>
          <Close size={30} />
        </St.CloseIconContainer>
        <St.Title size="headline">{`${t('EXISTENT_CLIENT.TITLE')}`}</St.Title>
        <St.Text weight="light">
          {`${t('EXISTENT_CLIENT.FIRST')} `}
          <b>{companyOverview?.name}</b>
          {` ${t('EXISTENT_CLIENT.SECOND')}.`}
        </St.Text>
        <St.InputContainer>
          {Input()}
          {error ? (
            <St.ErrorMsg weight="light" role="alert">
              {error}
            </St.ErrorMsg>
          ) : null}
        </St.InputContainer>
        <div>
          <St.CustomButton
            role="button"
            disabled={isLoading || !clientId || !!error}
            loading={isLoading}
            onClick={handleGetExistingClient}
            data-cy="get_existent_client_button"
          >{`${t('BUTTON_ENTER')}`}</St.CustomButton>
        </div>
        <St.NotAClient weight="light">{t('EXISTENT_CLIENT.QUESTION')}</St.NotAClient>
        <St.GrayParagraph weight="bold">{`${currentLocation?.phone} - ${currentLocation?.email}`}</St.GrayParagraph>
      </St.CustomCard>
    </St.Wrapper>
  );
};

export default ExistentClientModal;
