const es = require('./es/translations.json');
const esCl = require('./es/cl/translation.json');
const esAr = require('./es/ar/translation.json');
const esCo = require('./es/co/translation.json');
const esPe = require('./es/pe/translation.json');
const esMx = require('./es/mx/translation.json');
const esEc = require('./es/ec/translation.json');
const esCr = require('./es/cr/translation.json');
const esPa = require('./es/pa/translation.json');
const esVe = require('./es/ve/translation.json');
const esUy = require('./es/uy/translation.json');
const en = require('./en/translations.json');
const esEs = require('./es/es/translation.json');

const translations = { es, en, esCl, esAr, esCo, esPe, esMx, esEc, esCr, esPa, esVe, esUy, esEs };

export default translations;
