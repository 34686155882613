/* eslint-disable no-console */
import React from 'react';
import { getAuth, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';

import { Icon, Paragraph } from '@/UI';
import * as St from './SocialLogin.styles';
import { loginWithSocialAccount } from '@/services/user/user.repo';
import { useAuthContext } from '@/hooks';

export const SocialLogin = () => {
  const provider = new GoogleAuthProvider();

  // const facebookProvider = new FacebookAuthProvider();

  const firebaseAuth = getAuth();
  const { auth, dispatch } = useAuthContext();

  provider.setCustomParameters({
    display: 'popup',
  });

  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(firebaseAuth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const response = await loginWithSocialAccount({
        device: 'google',
        accessToken: credential?.accessToken as string,
      });

      dispatch({ type: 'SET_USER_DATA', payload: response });
    } catch (error) {
      console.warn('Error');
    }
  };

  // const facebookLogin = async () => {
  //   try {
  //     const result = await signInWithPopup(firebaseAuth, facebookProvider);
  //     const credential = FacebookAuthProvider.credentialFromResult(result);
  //     const response = await loginWithSocialAccount({
  //       device: 'facebook',
  //       accessToken: credential?.accessToken as string,
  //     });

  //     dispatch({ type: 'SET_USER_DATA', payload: response });
  //   } catch (error) {
  //     console.warn('Error');
  //   }
  // };

  return (
    <St.SocialLoginContainer>
      {/* <St.SocialButton facebook whiteText onClick={facebookLogin}>
        <Icon className="aproicon-FacebookSVG socialIcon" />
        <Paragraph size="small" weight="light" className="socialText">
          Facebook
        </Paragraph>
      </St.SocialButton> */}
      <St.SocialButton onClick={googleLogin}>
        <St.SocialIcon src="/assets/googleIcon.png" />
        <Paragraph size="small" weight="light" className="socialText">
          Google
        </Paragraph>
      </St.SocialButton>
    </St.SocialLoginContainer>
  );
};
