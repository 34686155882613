import Image from 'next/image';
import styled from 'styled-components';
import { InitialsAvatar } from '@/UI/Molecules/InitialsAvatar';

export const Wrapper = styled.button<{ isSelected?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.brandTheme.brand20 : theme.palette.white)};
  padding: 12px 16px;
  cursor: pointer;
  transition: 200ms ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.brandTheme.brand20};
  }
`;

export const DefaultAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  path {
    fill: ${({ theme }) => theme.palette.white};
  }
`;

export const CustomInitialsAvatar = styled(InitialsAvatar)`
  min-height: 38px;
  min-width: 38px;
  height: 38px;
  width: 38px;
`;

export const ProviderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 17px;
  .schedule {
    margin-top: 4px;
    color: ${({ theme }) => theme.palette.grays80};
  }
`;
export const ProviderImage = styled(Image)`
  border-radius: 50%;
`;
