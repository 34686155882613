export default {
  name: 'countryCodeLocale',
  lookup() {
    let lang;
    let locale;

    if (typeof window !== 'undefined' && !!window.location.href.split('/')[3]) {
      const countryCode = window.location.href.split('/')[3];

      lang = 'es';
      locale = `${countryCode[0]?.toUpperCase()}${countryCode.slice(1)}`;
      if (['en'].includes(countryCode)) {
        lang = 'en';
        locale = '';
      }
    }
    return lang && locale ? `${lang}${locale}` : undefined;
  },
};
