import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const SeeSchedule = styled.div`
  display: flex;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.brandTheme.brand100};
  i {
    margin-right: 1px;
  }
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  &:hover {
    cursor: pointer;
  }
  @media ${devices['no-mobile']} {
    display: none;
  }
`;

export const DayMapWrapper = styled.div`
  display: flex;
  p {
    text-transform: lowercase;
  }
`;

export const DayContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  p {
    text-transform: lowercase;
    margin-top: 3px;
    margin-left: 32px;
    text-align: start;
  }
  @media ${devices['no-mobile']} {
    p {
      margin-top: 0px;
      margin-left: 0px;
      text-align: start;
    }
  }
`;

export const MobileDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  color: ${({ theme }) => theme.palette.grays80};
  @media ${devices['no-mobile']} {
    display: none;
  }
`;

export const DesktopDiv = styled.div`
  display: none;
  margin-top: 5px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.palette.grays80};
  @media ${devices['no-mobile']} {
    display: flex;
    div:not(:first-child) {
      margin-left: 6px;
    }
    div:not(:last-child):after {
      content: ',';
    }
  }
`;
