import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { More } from '@agendapro/emerald-icons';

import { ServiceModel } from '@/services/services';
import { Paragraph } from '@/UI';
import { ServiceProviderButton } from '../ServiceProviderButton';
import { useReservation } from '@/context/ReservationsContext';
import * as St from './ProviderConsecutiveSelector.styles';

export const ProviderConsecutiveSelector: FC<{
  service: ServiceModel;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}> = ({ service, index, moveCard }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<ServiceModel, void, { handlerId: Identifier | null }>({
    accept: 'PROVIDER_CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: ServiceModel, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex! < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex! > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex!, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'PROVIDER_CARD',
    item: () => ({ id: service.internalId, index }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const { t } = useTranslation();
  const { reservations, dispatch } = useReservation();
  const { servicesWithProviders, providerPreSelected, canChooseProvider } = reservations;
  const getProvider = () => {
    if (providerPreSelected) {
      return providerPreSelected.publicName;
    }
    if (service.internalId && !!servicesWithProviders[service.internalId]) {
      return servicesWithProviders[service.internalId].publicName;
    }
    if (canChooseProvider === 'MUST') {
      return t('TO_SELECT');
    }
    return t('FIRST_PROVIDER_AVAILABLE');
  };

  const [selectedProvider, setSelectedProvider] = useState<string>(getProvider());
  const [isOpen, setIsOpen] = useState(false);
  const handleSelectProvider = (provider) => {
    const newServicesWithProviders = {
      ...servicesWithProviders,
      [service.internalId as string]: { providerId: provider.id, publicName: provider.publicName },
    };

    dispatch({
      type: 'SET_SERVICES_WITH_PROVIDERS',
      payload: newServicesWithProviders,
    });
    setSelectedProvider(provider.publicName);
    setIsOpen(false);
  };

  drag(drop(ref));

  return (
    <St.Wrapper
      ref={ref}
      isDragging={isDragging}
      data-handler-id={handlerId}
      data-cy="provider_consecutive_select_container"
    >
      <St.MenuBar>
        <St.TitleContainer>
          <More className="moreIcon" size={20} />
          <Paragraph size="subHeadline">{service.name}</Paragraph>
        </St.TitleContainer>
        {canChooseProvider !== 'CANT' && !providerPreSelected && !isOpen && (
          <St.MenuAction
            size="small"
            weight="light"
            onClick={() => setIsOpen(!isOpen)}
            data-cy="switch_consecutive_professional"
          >
            {t('PROVIDER_CONSECUTIVE_SELECTOR.SWITCH_PROFESSIONAL')}
          </St.MenuAction>
        )}
      </St.MenuBar>
      {!isOpen && (
        <St.SelectedProviderName size="small" weight="light">
          {selectedProvider}
        </St.SelectedProviderName>
      )}
      {isOpen && (
        <>
          {canChooseProvider !== 'MUST' && (
            <ServiceProviderButton
              image={null}
              name={t('FIRST_PROVIDER_AVAILABLE')}
              noPreference
              onClick={() => handleSelectProvider({ id: 0, publicName: t('FIRST_PROVIDER_AVAILABLE') })}
            />
          )}
          {service.serviceProviders.map((provider) => (
            <ServiceProviderButton
              key={provider.id}
              image={provider.image || null}
              name={provider.publicName}
              onClick={() => handleSelectProvider(provider)}
            />
          ))}
        </>
      )}
    </St.Wrapper>
  );
};
