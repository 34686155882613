import i18next from 'i18next';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { useReservation } from '@/context/ReservationsContext';
import { useFormatCurrency } from '@/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.XLarge} {
    flex-direction: column;
  }
`;

const RegularText = styled(Paragraph)`
  margin-left: ${({ theme }) => theme.spacing[8]};
  color: ${({ theme }) => theme.palette.grays80};
  @media ${devices.XLarge} {
    margin-left: 0;
  }
`;

const ServicePriceDiscount = () => {
  const fc = useFormatCurrency();
  const { reservations } = useReservation();
  const { servicesToReserve, currentIndex, daySelected } = reservations;

  const currentService = servicesToReserve[currentIndex];

  const promotionStart = currentService.charlyPromotionStartDate;
  const promotionEnd = currentService.charlyPromotionEndDate;
  const charlyDiscountedPrice = fc(servicesToReserve[currentIndex].payingPriceCharly);
  const regularPrice = fc(servicesToReserve[currentIndex].price);
  const discountedPrice = fc(servicesToReserve[currentIndex].payingPrice);

  const getDiscount = () => {
    if (dayjs(daySelected).isBetween(promotionStart, promotionEnd, 'day', '[]') && !!charlyDiscountedPrice) {
      return charlyDiscountedPrice;
    }
    if (discountedPrice) {
      return discountedPrice;
    }
    return regularPrice;
  };

  return (
    <Container>
      <Paragraph size="subHeadline" weight="regular">
        {getDiscount()}
      </Paragraph>
      {dayjs(daySelected).isBetween(promotionStart, promotionEnd, 'day', '[]') && charlyDiscountedPrice && (
        <RegularText weight="light">{`(${i18next.t('REGULAR_PRICE')})${regularPrice}`}</RegularText>
      )}
      {regularPrice !== discountedPrice && !dayjs(daySelected).isBetween(promotionStart, promotionEnd, 'day', '[]') && (
        <RegularText weight="light">{`(${i18next.t('REGULAR_PRICE')})${regularPrice}`}</RegularText>
      )}
    </Container>
  );
};

export default ServicePriceDiscount;
