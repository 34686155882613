import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useMediaQuery } from 'react-responsive';
import { useReservation } from '@/context/ReservationsContext/ReservationsProvider';
import { devices, Paragraph, EmeraldSpinner } from '@/UI';
import { formatAMPMHour } from '@/utils/formatAMPMHour';
import { StepTracker } from '../StepTracker/StepTracker';
import { ServiceProviderButton } from './ServiceProviderButton';
import * as St from './ServiceProviderSelector.styles';
import { ServiceProviderDataPropsExtended } from './ServiceProviderSelector.types';
import { useAvailableProviders } from '@/services/serviceProviders';
import { ProviderBundleSelector } from './ProviderBundleSelector/ProviderBundleSelector';
import { ProviderConsecutiveSelector } from './ProviderConsecutiveSelector/ProviderConsecutiveSelector';
import { usePageContext } from '@/hooks';
import PoweredBy from '@/components/PoweredBy/PoweredBy';

export const ServiceProviderSelector: FC<{ handleNext?: () => void }> = ({ handleNext }) => {
  const { reservations, dispatch } = useReservation();
  const isDesktop = useMediaQuery({ query: devices.XLarge });
  const theme = useTheme();
  const [selectedProvider, setSelectedProvider] = useState<number>();
  const { t } = useTranslation();
  const { isSalfa } = usePageContext();

  const {
    bookingMode,
    canChooseProvider,
    currentIndex,
    isEditing,
    isSession,
    providerPreSelected,
    servicesIds,
    servicesToReserve,
    servicesWithProviders,
    companyBookingInfo,
  } = reservations;
  const currentService = servicesToReserve[currentIndex];
  const isBundle = currentService?.bundled;
  const nonStandardBooking = isBundle || isSession || bookingMode === 'consecutive';

  const moveCard = (dragIndex: number, hoverIndex: number): void => {
    const move = (from, to, arr) => {
      const newOrderArray = [...arr];
      const item = newOrderArray.splice(from, 1)[0];

      newOrderArray.splice(to, 0, item);
      return newOrderArray;
    };
    const newServices = move(dragIndex, hoverIndex, servicesToReserve);
    const newServicesIds = newServices.map((service) => service.internalId);

    dispatch({ type: 'SET_SERVICES_IDS', payload: newServicesIds });
    dispatch({ type: 'SET_SERVICES_TO_RESERVE', payload: newServices });
  };

  const getEndsAt = () => {
    if (nonStandardBooking) {
      return null;
    }
    const dateObj = new Date(0, 0, 0, 0, 0);
    const [hour, minutes] = currentService.hourSelected?.split(':') || '';

    dateObj.setHours(parseInt(hour, 10));
    dateObj.setMinutes(parseInt(minutes, 10) + currentService.duration);
    return `${currentService?.daySelected} ${dateObj.getHours()}:${dateObj.getMinutes()}:00`;
  };

  const datesAndProviders = servicesToReserve.map((service) =>
    servicesIds?.findIndex((id) => id === service.internalId) !== 0 &&
    service.start &&
    service.end &&
    service.providerId
      ? [service.start, service.end, service.providerId]
      : null,
  );

  const { data } = useAvailableProviders(
    currentService?.id,
    reservations.locationId,
    nonStandardBooking ? null : `${currentService?.daySelected} ${currentService?.hourSelected}:00`,
    getEndsAt(),
    !nonStandardBooking,
    JSON.stringify(datesAndProviders.filter((item) => item !== null)),
  );

  const updateProviderInBooking = (serviceProvider: ServiceProviderDataPropsExtended | null) => {
    const services = servicesToReserve;
    const currentService = services[currentIndex];

    services[currentIndex] = {
      ...currentService,
      provider: serviceProvider && serviceProvider.id,
      providerId: serviceProvider && serviceProvider.id,
      providerName: serviceProvider && serviceProvider.publicName,
    };

    dispatch({ type: 'SET_SERVICES_TO_RESERVE', payload: services });
  };

  const redirectAfterSelectionMobile = () => {
    if ((!!servicesToReserve && servicesToReserve.length > 1) || isEditing) {
      return dispatch({ type: 'SET_STEP', payload: 'serviceSelection' });
    }

    if (companyBookingInfo.clientExclusive) {
      return dispatch({ type: 'SET_STEP', payload: 'reservationSummary' });
    }

    return dispatch({ type: 'SET_STEP', payload: 'clientForm' });
  };

  const redirectAfterSelectionDesktop = () => {
    if (handleNext) {
      handleNext();
    }
  };

  const handleSelectNoPreference = (serviceProvider) => {
    const newServicesWithProviders = {
      ...servicesWithProviders,
      [currentService.internalId as string]: { providerId: serviceProvider.id, publicName: serviceProvider.publicName },
    };

    if (isSession) {
      dispatch({ type: 'SET_PROVIDER_PRE_SELECTED', payload: serviceProvider });
      if (!isDesktop) {
        return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
      }
    }
    dispatch({ type: 'ADD_DATA_TO_CURRENT_SERVICE', payload: { serviceProvider } });
    setSelectedProvider(0);
    dispatch({ type: 'SET_SERVICES_WITH_PROVIDERS', payload: newServicesWithProviders });

    if (!isDesktop) {
      return redirectAfterSelectionMobile();
    }
    return redirectAfterSelectionDesktop();
  };

  const handleSelectProvider = (serviceProvider: ServiceProviderDataPropsExtended | null) => {
    const newServicesWithProviders = {
      ...servicesWithProviders,
      [currentService.internalId as string]: {
        providerId: serviceProvider?.id!,
        publicName: serviceProvider?.publicName!,
      },
    };

    if (serviceProvider && serviceProvider.id) {
      setSelectedProvider(serviceProvider.id);
      updateProviderInBooking(serviceProvider);
    }
    dispatch({ type: 'ADD_DATA_TO_CURRENT_SERVICE', payload: { serviceProvider } });
    dispatch({ type: 'SET_SERVICES_WITH_PROVIDERS', payload: newServicesWithProviders });

    if (isSession) {
      dispatch({ type: 'SET_PROVIDER_PRE_SELECTED', payload: serviceProvider });
      if (!isDesktop) {
        return dispatch({ type: 'SET_STEP', payload: 'dateSelection' });
      }
    }
    if (!isDesktop) {
      return redirectAfterSelectionMobile();
    }

    return redirectAfterSelectionDesktop();
  };

  const getProviderSelection = () => {
    if (isSession) {
      return currentService.serviceProviders.map((provider: any) => (
        <ServiceProviderButton
          key={provider.id}
          schedule=""
          name={provider.publicName}
          image={provider.image}
          onClick={() => handleSelectProvider(provider)}
          isSelected={!!(selectedProvider === provider.id)}
        />
      ));
    }

    if (bookingMode === 'consecutive') {
      return servicesToReserve.map((service, index) => (
        <ProviderConsecutiveSelector service={service} key={service.internalId} index={index} moveCard={moveCard} />
      ));
    }

    if (isBundle) {
      return currentService.bundle.map((service, index) => (
        <ProviderBundleSelector
          bundledService={service}
          index={index}
          length={currentService.bundle.length}
          key={service.id}
        />
      ));
    }

    return data?.map((provider: ServiceProviderDataPropsExtended) => (
      <ServiceProviderButton
        key={provider.id}
        schedule=""
        name={provider.publicName}
        image={provider.image}
        onClick={() => handleSelectProvider(provider)}
        isSelected={!!(selectedProvider === provider.id)}
      />
    ));
  };

  const shouldShowNoPreferredProvider = () => {
    if (canChooseProvider === 'MUST' || isBundle) {
      return false;
    }
    if (isSession && currentService.serviceProviders.length > 1) {
      return true;
    }
    if (data && data.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
      <St.Wrapper>
        <>
          <Paragraph
            size="subHeadline"
            weight="bold"
            className="title"
            color={theme.migrateColors('text.subtle', 'gray80')}
          >
            {(canChooseProvider === 'CANT' || providerPreSelected) && bookingMode === 'consecutive'
              ? t('SERVICE_PROVIDER_SELECTOR.TITLE_CANT_CHOOSE')
              : t('SERVICE_PROVIDER_SELECTOR.TITLE')}
          </Paragraph>
        </>
        <St.StepWrapper>
          <StepTracker isEditing={isEditing} />
        </St.StepWrapper>
        <St.Content consecutiveMode={bookingMode === 'consecutive'}>
          {!data && !nonStandardBooking ? (
            <St.LoadingContainer>
              <EmeraldSpinner width="100px" />
            </St.LoadingContainer>
          ) : (
            <St.ButtonsContainer consecutiveMode={bookingMode === 'consecutive'} data-cy="provider_buttons_container">
              {shouldShowNoPreferredProvider() && (
                <ServiceProviderButton
                  schedule={
                    isSession ? '' : `${currentService.dateFormatted} ${formatAMPMHour(currentService.hourSelected)}`
                  }
                  name={t('FIRST_PROVIDER_AVAILABLE')}
                  onClick={() =>
                    handleSelectNoPreference({
                      id: 0,
                      publicName: t('FIRST_PROVIDER_AVAILABLE'),
                    })
                  }
                  noPreference
                  isSelected={!!(selectedProvider === 0)}
                  image={null}
                />
              )}
              {getProviderSelection()}
            </St.ButtonsContainer>
          )}
        </St.Content>
      </St.Wrapper>
      {bookingMode === 'consecutive' && !isDesktop && (
        <St.ConsecutiveButtonContainer isConsecutive={bookingMode === 'consecutive'}>
          <St.ConsecutiveButton
            disabled={
              canChooseProvider === 'MUST' &&
              !(Object.entries(servicesWithProviders).length === servicesToReserve.length)
            }
            fullWidth
            onClick={() => dispatch({ type: 'SET_STEP', payload: 'dateSelection' })}
            data-testid="service_provider_selector_button_next"
            data-cy="service_provider_selector_button_next"
          >
            {t('BUTTON_NEXT')}
          </St.ConsecutiveButton>
        </St.ConsecutiveButtonContainer>
      )}
      {isBundle && !isDesktop && (
        <St.MobileDrawer>
          <St.ConfirmButton
            id="button_mobile_confirm_provider"
            disabled={
              Object.keys(reservations.servicesWithProviders).length !== reservations.servicesToReserve[0].bundle.length
            }
            onClick={() => dispatch({ type: 'SET_STEP', payload: 'dateSelection' })}
            data-testid="button_bundle_confirm"
            data-cy="bundle_confirm_button"
          >
            {t('BUTTON_NEXT')}
          </St.ConfirmButton>
          <PoweredBy />
        </St.MobileDrawer>
      )}
    </DndProvider>
  );
};
