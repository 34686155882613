import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { Service, ServicesResponse } from '@/services/services';
import { UseServiceActions, UseServicesState } from './useServicesState.types';

const getCurrentFilterIds = (state: UseServicesState, categoryId: number, isMultiple = false) => {
  if (categoryId === 0) {
    return [0];
  }

  if (state.currentFilterIds.includes(categoryId)) {
    const filtered = state.currentFilterIds.filter((id) => id !== categoryId);

    if (filtered.length === 0) {
      return [0];
    }
    return filtered;
  }

  if (isMultiple) {
    return [...state.currentFilterIds.filter((number) => number !== 0), categoryId];
  }

  return [categoryId];
};
const getDataFiltered = (state: UseServicesState, payload: number, isMultiple = false) => {
  if (payload === 0) {
    return state.data;
  }
  const filtered = state.data.filter((service) => getCurrentFilterIds(state, payload, isMultiple).includes(service.id));

  if (filtered.length === 0) {
    return state.data;
  }
  return filtered;
};

const servicesReducer = (state: UseServicesState, action: UseServiceActions): UseServicesState => {
  switch (action.type) {
    case 'INITIALIZE_STATE': {
      return {
        ...action.payload,
      };
    }
    case 'SEARCH': {
      const currentFilterIds = action.payload
        .filter((category) => category.services.length > 0)
        .map((category) => category.id);

      return {
        ...state,
        dataFiltered: action.payload,
        currentFilterIds,
        expandedCategories: action.payload.map((category) => category.category),
      };
    }
    case 'SEARCH_RESET': {
      return {
        ...state,
        dataFiltered: state.data,
        currentFilterIds: [0],
        expandedCategories: [],
      };
    }
    case 'SELECT_SINGLE_CATEGORY': {
      const dataFiltered = getDataFiltered(state, action.payload);

      return {
        ...state,
        dataFiltered,
        currentFilterIds: getCurrentFilterIds(state, action.payload),
        expandedCategories: action.payload === 0 ? [] : [dataFiltered[0].category],
      };
    }
    case 'SELECT_MULTIPLE_CATEGORIES': {
      const dataFiltered = getDataFiltered(state, action.payload, true);
      const expandedCategories = dataFiltered.map((service) => service.category);

      return {
        ...state,
        dataFiltered,
        currentFilterIds: getCurrentFilterIds(state, action.payload, true),
        expandedCategories,
      };
    }

    case 'EXPAND_CATEGORY': {
      if (state.expandedCategories.includes(action.payload)) {
        return {
          ...state,
          expandedCategories: state.expandedCategories.filter((category) => category !== action.payload),
        };
      }
      return {
        ...state,
        expandedCategories: [...state.expandedCategories, action.payload],
      };
    }
    default:
      return state;
  }
};

const useServices = (servicesData?: Array<Service>) => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(servicesReducer, {
    data: [],
    dataFiltered: [],
    currentFilterIds: [0],
    listOfCategories: [],
    expandedCategories: [],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location.search);
    const charlyToken = urlParams.get('token');
    const getCategories = (allServices: ServicesResponse['services']) => {
      const categories = [{ id: 0, category: t('CATEGORIES.ALL') }, ...allServices]
        .map((s) => ({
          id: s?.id,
          name: s?.category,
        }))
        .sort((a, b) => {
          if (typeof a.id === 'string') {
            return -1;
          }
          if (typeof b.id === 'number' && a.id > b.id) {
            return 1;
          }
          return 0;
        });

      if (categories.length === 2) {
        return [categories[1]];
      }
      if (categories[1]?.name === 'Otros') {
        return categories.filter((c) => c.name !== 'Otros');
      }
      return categories;
    };

    const checkExpandedCategories = (servicesData: ServicesResponse['services']) => {
      if (servicesData.length === 1) {
        return [servicesData[0].category];
      }

      if (servicesData.length <= 3 && servicesData.every((category) => category.services.length <= 2)) {
        return servicesData.map((category) => category.category);
      }
      return [...(charlyToken ? [t('CATEGORIES.DISCOUNT')] : [])];
    };

    if (servicesData) {
      const allCategories = charlyToken
        ? [
            {
              id: 'DISCOUNT',
              category: t('CATEGORIES.DISCOUNT'),
              services: servicesData
                .flatMap((service) => service.services)
                .filter((service) => !!service.charlyDiscount),
            },
            ...servicesData,
          ]
        : servicesData;

      dispatch({
        type: 'INITIALIZE_STATE',
        payload: {
          data: allCategories,
          dataFiltered: allCategories,
          currentFilterIds: [0],
          listOfCategories: getCategories(allCategories),
          expandedCategories: checkExpandedCategories(allCategories),
        },
      });
    }
  }, [servicesData, t]);

  return { state, dispatch };
};

export default useServices;
