import { FC } from 'react';
import { LabelContainer } from './Label.styles';
import { LabelProps } from './types';

export const Label: FC<LabelProps> = ({
  children,
  size = 'inputs-label',
  weight = 'regular',
  caps,
  className,
  onClick,
  align = 'left',
}) => (
  <LabelContainer size={size} weight={weight} caps={caps} className={className} onClick={onClick} align={align}>
    {children}
  </LabelContainer>
);
