import React from 'react';
import { ClientInput, ClientRadio, ClientSelect, ClientTextArea } from '../UI';
import { selectValueFormat } from './formatters';
import { ClientDateSelector } from '../UI/Molecules/ClientInputs/DateSelector';
import { validationByField } from './validationByField';

const InputType = (item, register, errors, control, t) => {
  const { showOnWorkflow, mandatoryOnWorkflow, name, datatype, slug, categories } = item;

  if (!showOnWorkflow) {
    return null;
  }

  const itemLabel = `${name}${mandatoryOnWorkflow ? '*' : ''}`;
  const validation = mandatoryOnWorkflow ? validationByField('simpleRequired', t) : {};

  switch (datatype) {
    case 'datetime': {
      return (
        <ClientDateSelector
          control={control}
          item={{ ...item, required: mandatoryOnWorkflow }}
          enableTime
          rules={validation}
        />
      );
    }
    case 'date': {
      return (
        <ClientDateSelector control={control} item={{ ...item, required: mandatoryOnWorkflow }} rules={validation} />
      );
    }
    case 'boolean': {
      return (
        <ClientRadio
          label={itemLabel}
          placeholder={name}
          register={register(`custom_${slug}`, validation)}
          errorMsg={errors[`custom_${slug}`]?.message}
          name={`custom_${slug}`}
        />
      );
    }
    case 'integer': {
      return (
        <ClientInput
          label={itemLabel}
          placeholder={name}
          register={register(`custom_${slug}`, validationByField('integer', t, mandatoryOnWorkflow))}
          errorMsg={errors[`custom_${slug}`]?.message}
          name={`custom_${slug}`}
          type="number"
        />
      );
    }

    case 'float': {
      return (
        <ClientInput
          label={itemLabel}
          placeholder={name}
          register={register(`custom_${slug}`, validation)}
          errorMsg={errors[`custom_${slug}`]?.message}
          name={`custom_${slug}`}
          type="number"
          step="any"
        />
      );
    }
    case 'textarea': {
      return (
        <ClientTextArea
          label={itemLabel}
          placeholder={name}
          register={register(`custom_${slug}`, validation)}
          errorMsg={errors[`custom_${slug}`]?.message}
          name={`custom_${slug}`}
        />
      );
    }
    case 'categoric': {
      return (
        <ClientSelect
          label={itemLabel}
          errorMsg={errors[`custom_${slug}`]?.message}
          options={selectValueFormat(categories, 'id', 'category')}
          control={control}
          rules={validation}
          name={`custom_${slug}`}
        />
      );
    }
    case 'text': {
      return (
        <ClientInput
          label={itemLabel}
          placeholder={name}
          register={register(`custom_${slug}`, validation)}
          errorMsg={errors[`custom_${slug}`]?.message}
          name={`custom_${slug}`}
        />
      );
    }
    default:
      return null;
  }
};

export default InputType;
