const getEvent = (actionType) => (actionType === 'reserve' ? 'Schedule' : 'InitiateCheckout');

export const FbPixel = {
  pageview() {
    window.fbq('track', 'PageView');
  },
  event(name, options = {}) {
    window.fbq('track', getEvent(name), options);
  },
};
