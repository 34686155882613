import styled from 'styled-components';
import { devices } from '@/UI/Styles';

export const Wrapper = styled.div<{ small?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.brandTheme.brand100};
  background-color: ${({ theme }) => theme.brandTheme.brand50};
  border-radius: 50%;
  height: ${({ small }) => (small ? '26px' : '38px')};
  width: ${({ small }) => (small ? '26px' : '38px')};
  min-height: ${({ small }) => (small ? '26px' : '38px')};
  min-width: ${({ small }) => (small ? '26px' : '38px')};
  p {
    color: ${({ theme }) => theme.brandTheme.brand100};
  }
  @media ${devices.XLarge} {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
  }
`;
