import { FC, useState, useEffect } from 'react';
import Image from 'next/image';

import { Paragraph } from '../../UI';
import { MessageContainer } from './NoResultsMessage.styles';

import { INoResultsMessageProps } from './types';

export const NoResultsMessage: FC<INoResultsMessageProps> = ({
  image,
  message,
  children,
  width = 120,
  height = 120,
}) => {
  const [currentMessage, setCurrentMessage] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (Array.isArray(message)) {
      setCurrentMessage(message[0]);
      const interval = setInterval(() => {
        setCurrentMessage((prev) => {
          const index = message.findIndex((e) => e === prev);

          return message[index === message.length - 1 ? 0 : index + 1];
        });
      }, 3000);

      return () => clearInterval(interval);
    }

    setCurrentMessage(message);
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessageContainer>
      <Image
        src={image || require('../../assets/no-results.png')}
        alt={currentMessage}
        arial-label="noResults"
        data-testid="noResultsImage"
        height={height}
        width={width}
      />
      <Paragraph size="subHeadline" arial-label="message">
        {currentMessage}
      </Paragraph>
      {children}
    </MessageContainer>
  );
};
