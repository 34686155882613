/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { fadeIn, keyFramesFadeIn } from '../../../utils';

interface AlertModalContainerProps {
  type?: string;
}

export const AlertModalContainer = styled.div<AlertModalContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: rgba(26, 26, 26, 0.5);
  ${fadeIn(200)}
  ${keyFramesFadeIn}
  .cardAlert {
    min-width: 250px;
    max-width: 373px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0;
    padding: 30px;
    background-color: ${({ theme }) => theme.palette.white};
    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 95px;
      height: 95px;
      margin-bottom: 16px;
      background-color: ${({ theme, type }) =>
        type === 'success'
          ? theme.palette.primary100
          : type === 'warning'
          ? theme.palette.accent100
          : type === 'email'
          ? theme.palette.email
          : type === 'delete'
          ? theme.palette.alert100
          : theme.palette.grays50};
      .icon {
        width: 60px;
        height: 60px;
        path {
          fill: ${({ theme }) => theme.palette.white};
        }
      }
    }
    .titleAlert {
      white-space: pre-line;
      margin-bottom: 16px;
      text-align: center;
    }
    .descriptionAlert {
      white-space: pre-wrap;
      margin-bottom: 16px;
      text-align: center;
    }
    .errorDetailContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      .errorDetail {
        overflow-y: scroll;
        max-height: 30vh;
        margin-top: 5px;
        margin-bottom: 16px;
        background-color: ${({ theme }) => theme.tokens.color.background.tertiary};
        padding: 15px;
        border-radius: 8px;
        p {
          color: ${({ theme }) => theme.palette.grays50};
        }
      }
    }
    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .cancelBtn {
        background-color: ${({ theme }) => theme.migrateColors('background.btnSecondary', 'gray')};
        color: ${({ theme }) => theme.brandTheme.brand100};
        border: 1px solid ${({ theme }) => theme.migrateColors('border.btnSecondary', 'background')};
      }

      .confirmBtn {
        background-color: ${({ theme, type }) =>
          type === 'delete' ? theme.migrateColors('background.danger', 'alert') : theme.brandTheme.brand100};
      }
    }
  }
`;
