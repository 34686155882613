import { Delete, Minus, Plus } from '@agendapro/emerald-icons';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph } from '@/UI/Atoms';
import * as St from './Counter.styles';

const Counter: FC<{ handleMinus: () => void; handlePlus: () => void; amount: number }> = ({
  handleMinus,
  handlePlus,
  amount,
}) => {
  const theme = useTheme();

  return (
    <St.Wrapper data-testid="counter-wrapper">
      <St.ActionBtn onClick={handleMinus} data-testid="counter-minus-button">
        {amount > 1 ? (
          <Minus size={20} color={theme.brandTheme.brand100} data-testid="minus-icon" />
        ) : (
          <Delete className="deleteIcon" size={20} data-testid="delete-icon" />
        )}
      </St.ActionBtn>
      <Paragraph data-cy="cart_dialog_amount" data-testid="counter-amount">
        {amount}
      </Paragraph>
      <St.ActionBtn onClick={handlePlus} data-cy="cart_dialog_plus_button" data-testid="counter-plus-button">
        <Plus color={theme.brandTheme.brand100} size={20} />
      </St.ActionBtn>
    </St.Wrapper>
  );
};

export default Counter;
