import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 12px 0;
  white-space: pre-wrap;
  border-bottom: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray20')};

  :last-child {
    border-bottom: 0;
  }

  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  i {
    align-self: flex-start;
    margin-right: 8px;
    color: ${({ theme }) => theme.brandTheme.brand100};
    position: relative;
    top: -5px;
  }
  p {
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

export const ItemNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
