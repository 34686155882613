export const iconSize = {
  'xxSmall-mobile': '15px',
  'xxSmall-desktop': '15px',

  'xSmall-mobile': '15px',
  'xSmall-desktop': '20px',

  'small-mobile': '20px',
  'small-desktop': '24px',

  'medium-mobile': '25px',
  'medium-desktop': '30px',

  'xMedium-mobile': '31px',
  'xMedium-desktop': '31px',

  'large-mobile': '30px',
  'large-desktop': '35px',
};
