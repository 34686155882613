import styled from 'styled-components';

import { devices } from '@/UI/Styles';

type WrapperProps = keyof MediaDevices;

export const Wrapper = styled.div<{ untilTo: WrapperProps }>`
  display: unset;

  ${({ untilTo }) =>
    ` @media ${(devices as any)[untilTo]} {
        display: none;
      }`}
`;
