import styled from 'styled-components';
import { devices } from '../../../Styles';

export const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  .errorMsg {
    color: ${({ theme }) => theme.palette.alert100};
    margin-top: 8px;
    margin-bottom: 0px;
  }
  div {
    &:first-of-type {
      margin-top: ${({ theme }) => theme.spacing[8]};
    }
  }
`;

export const InputContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding: 5px 5px; */
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  &:last-child {
    margin-bottom: 0px;
  }
  width: 100%;
  i {
    margin-right: 8px;
  }
  label {
    margin-left: 5px;
  }
  @media ${devices['no-mobile']} {
    justify-content: flex-start;
    i {
      margin-right: 4px;
    }
  }
`;
export const CustomInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: ${({ theme }) => theme.palette.graysPlaceholder};
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.palette.graysPlaceholder};
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:checked {
    border: 1px solid ${({ theme }) => theme.brandTheme.brand100};
  }
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: ${({ theme }) => theme.brandTheme.brand100};
  }
  &:checked::before {
    transform: scale(1);
  }
`;
