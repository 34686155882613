import { useContext } from 'react';

import { DialogsContext } from './DialogsContext';

export const useDialogsContext = () => {
  const context = useContext(DialogsContext);

  if (context === undefined) {
    throw new Error('useDialogsContext must be used within a DialogsContextProvider');
  }

  return context;
};
