import { useState, useRef, useEffect, useCallback, Fragment } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useTheme } from 'styled-components';
import { useFloating, flip as flipMiddleware, Middleware } from '@floating-ui/react-dom';
import { Spinners } from '@agendapro/emerald';
import { MobileArrowTop, MobileArrowBottom, Search } from '@agendapro/emerald-icons';
import { RecursiveOption } from '@agendapro/emerald/v2/dist/v2/src/organisms/Selects/types';
import { NoResultsMessage, TextField, Text, EmeraldThemeProvider } from '@agendapro/emerald/v2';
import { useInView } from 'react-intersection-observer';

import { isSearchTermSimilar, notEmpty } from '../../Utils';
import { useClickAway } from '../../../hooks/useClickAway';

import * as St from './Selects.styles';
import { SelectProps, BaseSelectOption } from './types';

function Select<T extends BaseSelectOption>({
  label,
  options,
  selected,
  onChange,
  onBlur,
  search,
  placeholder,
  color,
  error,
  errorMsg,
  disabled,
  full,
  flip,
  isTouched = true,
  infiniteScrollArgs,
  displayOptionsNumber = 4,
  type = 'BUTTON',
  children,
  hint,
  'data-testid': dataTestId,
}: SelectProps<T>) {
  const theme: any = useTheme();
  const selectRef = useRef<HTMLDivElement | null>(null);
  const selectContainerRef = useRef<HTMLButtonElement | null>(null);
  const [searchKey, setSearchKey] = useState('');
  const [matchingOptions, setMatchingOptions] = useState(options);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<BaseSelectOption | undefined>(undefined);
  const { y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [flip ? flipMiddleware() : null].filter(notEmpty) as Middleware[],
  });

  const [ref] = useInfiniteScroll({
    loading: false,
    hasNextPage: false,
    onLoadMore: () => {},
    rootMargin: '0px 0px 37px 0px',
    ...infiniteScrollArgs,
  });
  const { ref: inViewRef, inView } = useInView({
    threshold: 0,
  });
  const getSelectedValues = useCallback(
    (optionValues: BaseSelectOption[]) => {
      if (!infiniteScrollArgs) {
        const result = optionValues.find((el) => el.value === selected?.value);

        if (result) {
          return setSelectedValue(result);
        }

        return optionValues.forEach((option) => {
          if (option.options) {
            getSelectedValues(option.options);
          }
        });
      }
      return setSelectedValue(selected);
    },
    [infiniteScrollArgs, selected],
  );

  useEffect(() => {
    getSelectedValues(options);
  }, [getSelectedValues, options]);

  useEffect(() => {
    if (!inView) {
      setOpen(false);
    }
  }, [inView]);

  useEffect(() => {
    update();
  }, [update, selected]);

  useClickAway({
    ref: selectRef,
    onClickAway: () => {
      setOpen(false);
    },
    mountListenersWhen: open,
  });

  useEffect(() => {
    if (!refs.reference.current || !open) {
      return;
    }

    document.addEventListener('scroll', update, true);
    document.addEventListener('resize', update, true);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', update, true);
      document.removeEventListener('resize', update, true);
    };
  }, [refs.reference, refs.floating, update, open]);

  useEffect(() => {
    if (infiniteScrollArgs) {
      setMatchingOptions(options);
    } else {
      const searchTerm = searchKey ? searchKey.toString() : '';

      if (searchTerm) {
        setMatchingOptions(options.filter((option) => isSearchTermSimilar(JSON.stringify(option), searchTerm)));
      } else {
        setMatchingOptions(options);
      }
    }
  }, [searchKey, options, infiniteScrollArgs]);

  const createTree = ({ option, depth = 1 }: { option: BaseSelectOption; depth?: number }) => {
    let depthLevel = depth;

    if (option.options) {
      depthLevel += 1;
    } else {
      depthLevel = 1;
    }

    return (
      option && (
        <St.StyledOptionContainer key={option.label || option.value}>
          <St.StyledOption
            type="button"
            isSelected={selected?.value === option.value}
            onClick={
              !option.options
                ? () => {
                    if (onChange) {
                      onChange(option as RecursiveOption<T>);
                    }
                    setOpen(false);
                  }
                : undefined
            }
            depth={depth}
            hasLeftIcon={!!option.leftAdornment}
            data-testid={dataTestId ? `${dataTestId}-Select-Option-${option.value}` : undefined}
          >
            {option.leftAdornment && (
              <St.IconContainer left>
                <option.leftAdornment.component size={option.leftAdornment.size} color={option.leftAdornment.color} />
              </St.IconContainer>
            )}
            <Text type={option.options ? 'body-2' : 'body'}>{option.label}</Text>
            {option.rightAdorment && (
              <St.IconContainer>
                <option.rightAdorment.component size={option.rightAdorment.size} color={option.rightAdorment.color} />
              </St.IconContainer>
            )}
          </St.StyledOption>
          {(searchKey
            ? option.options?.filter((option) => isSearchTermSimilar(JSON.stringify(option), searchKey))
            : option.options
          )?.map((subOption) => createTree({ option: subOption, depth: depthLevel }))}
        </St.StyledOptionContainer>
      )
    );
  };

  return (
    <EmeraldThemeProvider>
      {label && (
        <St.StyledLabel type="body-2" color={disabled ? theme.palette.grays50 : undefined}>
          {label}
        </St.StyledLabel>
      )}
      <div ref={selectRef}>
        <St.SelectContainer ref={inViewRef}>
          <div ref={reference}>
            {type === 'BUTTON' && (
              <St.StyledSelect
                type="button"
                onClick={() => {
                  setOpen(!open);
                }}
                onBlur={onBlur}
                isPrefilled={!isTouched && !!selected}
                error={error}
                disabled={disabled}
                full={full}
                hasLeftIcon={!!selectedValue?.leftAdornment}
                ref={selectContainerRef}
                data-testid={dataTestId ? `${dataTestId}-Select` : undefined}
              >
                {selectedValue ? (
                  <Fragment>
                    {selectedValue.leftAdornment && (
                      <St.IconContainer left>
                        <selectedValue.leftAdornment.component
                          size={selectedValue.leftAdornment.size}
                          color={selectedValue.leftAdornment.color}
                        />
                      </St.IconContainer>
                    )}
                    <St.StyledPlaceholder
                      type="body"
                      clamp={1}
                      color={color}
                      data-testid={dataTestId ? `${dataTestId}-Select-placeholder` : undefined}
                    >
                      {hint || selectedValue?.label}
                    </St.StyledPlaceholder>
                    {selectedValue.rightAdorment && (
                      <St.IconContainer left>
                        <selectedValue.rightAdorment.component
                          size={selectedValue.rightAdorment.size}
                          color={selectedValue.rightAdorment.color}
                        />
                      </St.IconContainer>
                    )}
                  </Fragment>
                ) : (
                  <St.StyledPlaceholder
                    type="body"
                    clamp={1}
                    color={color}
                    data-testid={dataTestId ? `${dataTestId}-Select-placeholder` : undefined}
                  >
                    {placeholder}
                  </St.StyledPlaceholder>
                )}
                <St.Arrow>
                  {open ? <MobileArrowTop size={14} color={color} /> : <MobileArrowBottom size={14} color={color} />}
                </St.Arrow>
              </St.StyledSelect>
            )}
            {type === 'LINK' && (
              <St.StyledButtonLink
                type="button"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {children}
              </St.StyledButtonLink>
            )}
            <St.StyledErrorMessage
              type="p-small"
              color={theme.palette.alert100}
              data-testid={dataTestId ? `${dataTestId}-Select-errorMsg` : undefined}
            >
              {errorMsg}
            </St.StyledErrorMessage>
          </div>

          {open && (
            <Fragment>
              <St.OptionsContainer
                withSearch={!!search}
                offsetY={y}
                position={strategy}
                ref={floating}
                width={selectContainerRef.current?.clientWidth}
                displayOptionsNumber={displayOptionsNumber}
                data-testid={dataTestId ? `${dataTestId}-Select-options` : undefined}
              >
                <St.FixedOptionContainer>
                  {search && (
                    <St.SearchContainer>
                      <TextField
                        leftAdornment={{ component: Search, size: 20 }}
                        value={searchKey}
                        onChange={({ target }) => setSearchKey(target.value)}
                        placeholder="Buscar"
                        {...(typeof search !== 'boolean' ? search : undefined)}
                        noBorder
                        full
                        data-testid={dataTestId ? `${dataTestId}-Select-search` : undefined}
                      />
                    </St.SearchContainer>
                  )}
                </St.FixedOptionContainer>
                {matchingOptions && matchingOptions.length > 0 ? (
                  matchingOptions.map((option) => createTree({ option }))
                ) : (
                  <NoResultsMessage size={56} padding={24} />
                )}
                {(infiniteScrollArgs?.hasNextPage || infiniteScrollArgs?.loading) && (
                  <St.LoadingContainer ref={ref}>
                    <Spinners width="34px" />
                  </St.LoadingContainer>
                )}
              </St.OptionsContainer>
            </Fragment>
          )}
        </St.SelectContainer>
      </div>
    </EmeraldThemeProvider>
  );
}

export default Select;
