import { FC } from 'react';
import { Close } from '@agendapro/emerald-icons';
import { FormAlert } from '../../Molecules';
import * as St from './Modal.styles';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = ({ children, className, handleClose, alertMessage }) => (
  <St.Wrapper className={className}>
    <St.Content>
      {alertMessage && (
        <FormAlert className="formAlert" role="alert">
          {alertMessage}
        </FormAlert>
      )}
      <St.Card>
        {handleClose && (
          <St.CloseContainer>
            <St.Close role="img" icon={<Close />} size="large" onClick={handleClose} />
          </St.CloseContainer>
        )}
        {children}
      </St.Card>
    </St.Content>
  </St.Wrapper>
);
