import React from 'react';
import { CategorySkeleton, CategorySkeletonContainer, FilterSkeletonContainer } from './FilterSkeletons.styles';

const FilterSkeletons = () => (
  <FilterSkeletonContainer>
    {[...Array(4).keys()].map((el) => (
      <CategorySkeletonContainer key={el}>
        <CategorySkeleton />
      </CategorySkeletonContainer>
    ))}
  </FilterSkeletonContainer>
);

export default FilterSkeletons;
