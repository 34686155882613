import { FC } from 'react';

import { ParagraphContainer } from './Paragraph.styles';
import { ParagraphProps } from './types';

export const Paragraph: FC<ParagraphProps> = ({
  children,
  size = 'paragraph',
  weight = 'regular',
  caps = false,
  className,
  onClick,
  align = 'left',
  as = 'p',
  clamp = undefined,
  ...props
}) => (
  <ParagraphContainer
    size={size}
    weight={weight}
    caps={caps}
    className={className}
    onClick={onClick}
    align={align}
    as={as}
    clamp={clamp}
    {...props}
  >
    {children}
  </ParagraphContainer>
);
