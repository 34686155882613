import styled from 'styled-components';

import { devices, fontWeight } from '@/UI/Styles';
import { fadeIn, keyFramesFadeIn } from '@/utils';

export const ErrorModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(26, 26, 26, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  ${fadeIn(100)}
  ${keyFramesFadeIn}

  .errorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
    padding: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    width: 300px;

    @media ${devices['no-mobile']} {
      width: 310px;
    }

    p {
      text-align: center;
      color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray80')};
    }

    .title {
      font-size: 18px;
      color: ${({ theme }) => theme.brandTheme.brand100};
      margin-bottom: 25px;
    }

    .errorText {
      color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray80')};
    }

    .endText {
      color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray80')};
      margin-top: ${({ theme }) => theme.spacing[16]};
    }

    .button {
      background-color: ${({ theme }) => theme.brandTheme.brand100};
      margin-top: ${({ theme }) => theme.spacing[32]};

      p {
        color: ${({ theme }) => theme.migrateColors('text.btnConfirmPrimary', 'white')};
      }
    }
  }
`;
