import { Check, Close, Delete, Email, TriangleWarning } from '@agendapro/emerald-icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@agendapro/emerald/v2';
import { useLockBodyScroll } from '../../../hooks';
import { Card, Icon, Paragraph } from '../../Atoms';
import { AlertModalContainer } from './AlertModal.styles';
import { AlertModalProps } from './types';

export const AlertModal: FC<AlertModalProps> = ({
  type,
  className,
  title,
  description,
  confirmButtonText,
  titleSize,
  cancelButtonText,
  showCancelButton,
  errorDetail,
  hide,
  onConfirm,
  onCancel,
  phoneLink,
}) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  const getIconByType = () => {
    if (type === 'success') {
      return <Check size={60} />;
    }
    if (type === 'warning') {
      return <TriangleWarning size={60} />;
    }
    if (type === 'email') {
      return <Email size={60} />;
    }
    if (type === 'delete') {
      return <Delete size={60} />;
    }
    return <Close size={60} />;
  };

  return (
    <AlertModalContainer className={className} type={type} data-testid="alert-modal">
      <Card className="cardAlert">
        {type && (
          <div className="iconContainer">
            <Icon className="icon" size={60} icon={getIconByType()} />
          </div>
        )}
        <Paragraph
          weight="bold"
          size={titleSize || 'headline'}
          className="titleAlert"
          data-testid="titleAlert"
          data-cy="alert_title"
        >
          {title}
        </Paragraph>
        <Paragraph
          className="descriptionAlert"
          size="small"
          data-testid="descriptionAlert"
          weight="light"
          data-cy="alert_description"
        >
          {description}
        </Paragraph>
        {errorDetail && (
          <div className="errorDetailContainer">
            <Paragraph weight="bold" size="small">{`${t('ERRORS.DETAIL')}`}</Paragraph>
            <div className="errorDetail">
              <Paragraph weight="light" size="small" data-testid="errorDetailAlert">
                {errorDetail}
              </Paragraph>
              {phoneLink && (
                <Paragraph weight="light" size="small">
                  {t('TRY_AGAIN')}{' '}
                  <a href={phoneLink} className="underline text-primary" data-testid="phoneLinkAlert">
                    {t('PHONE_LINK_CONTACT')}
                  </a>
                </Paragraph>
              )}
            </div>
          </div>
        )}
        <div className="buttonsContainer">
          {showCancelButton && (
            <Button className="cancelBtn" data-testid="alertCancelBtn" onClick={onCancel || hide}>
              {cancelButtonText || t('BUTTON_CANCEL')}
            </Button>
          )}
          <Button
            className="confirmBtn"
            data-testid="alertConfirmBtn"
            data-cy="alert_confirm_button"
            onClick={onConfirm || hide}
            fullWidth={!showCancelButton && true}
          >
            {confirmButtonText || t('BUTTON_ACCEPT')}
          </Button>
        </div>
      </Card>
    </AlertModalContainer>
  );
};
