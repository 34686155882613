import React, { FC, Fragment, useState, useRef, useEffect } from 'react';
import { MobileArrowBottom, MobileArrowTop } from '@agendapro/emerald-icons';
import { useTheme } from 'styled-components';
import * as st from './Accordion.styles';
import { AccordionProps } from './types';

const Accordion: FC<AccordionProps> = ({ children, margin, title, width, className, isOpen, setIsOpen }) => {
  const { tokens } = useTheme();
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<string>('0px');

  useEffect(() => {
    setMaxHeight(isOpen ? `${contentRef.current?.scrollHeight}px` : '0px');
  }, [isOpen]);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <st.Wrapper openAccordion={isOpen} margin={margin} width={width} className={className} maxHeight={maxHeight}>
      <div
        className="accordionTitleContainer"
        onClick={toggleAccordion}
        onKeyDown={toggleAccordion}
        role="button"
        tabIndex={0}
      >
        <div>{title}</div>

        {isOpen ? (
          <MobileArrowTop size={20} color={tokens.color.icon.default} />
        ) : (
          <MobileArrowBottom size={20} color={tokens.color.icon.default} />
        )}
      </div>
      <div ref={contentRef} className="accordionContent">
        {children}
      </div>
    </st.Wrapper>
  );
};

export default Accordion;
