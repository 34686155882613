import styled, { css } from 'styled-components';
import { applyFlexAndCenter } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { applyEllipsisByNumOfLines } from '@/styles';

export const Wrapper = styled.div<{ isSelected: boolean; disabled: boolean }>`
  ${({ theme, isSelected, disabled }) => css`
    background-color: ${isSelected ? theme.brandTheme.brand20 : theme.migrateColors('background.primary', 'white')};
    border: 1px solid;
    border-color: ${isSelected ? theme.brandTheme.brand80 : theme.migrateColors('border.primary', 'gray30')};
    margin: ${({ theme }) => theme.spacing[16]};
    padding: ${({ theme }) => theme.spacing[16]};
    border-radius: ${({ theme }) => theme.spacing[8]};
    cursor: ${disabled ? 'default' : 'pointer'};
    transition: 200ms ease-in-out;
    :hover {
      background-color: ${!disabled && theme.brandTheme.brand20};
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Title = styled(Paragraph)`
  font-size: 14px;
  ${applyEllipsisByNumOfLines(2)}
`;

export const Main = styled.div`
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  .deleteIcon {
    path {
      fill: ${({ theme }) => theme.migrateColors('icon.btnDestructiveSecondary', 'alert')};
    }
  }
`;

export const Delete = styled.button`
  padding: 0;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  border: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'gray30')};
  box-shadow: 0 2px 2px 0 rgba(57, 65, 73, 0.08);
  display: flex;
  justify-content: center;
  svg {
    height: 100%;
  }
`;

export const Completed = styled.div`
  ${applyFlexAndCenter}
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing[8]};
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  path {
    fill: ${({ theme }) => theme.migrateColors('icon.btnPrimary', 'white')};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.md};
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing[4]};
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  p {
    margin-left: ${({ theme }) => theme.spacing[4]};
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
  }
`;

export const EditText = styled(Paragraph)`
  text-decoration: underline;
`;

export const Crossed = styled.span`
  text-decoration: line-through;
`;
