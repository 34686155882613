import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Paragraph } from '../../../Atoms';

import * as St from './Radio.styles';
import { ClientRadioProps } from './Radio.types';

export const ClientRadio: FC<ClientRadioProps> = ({ register, errorMsg, label, className, ...props }) => {
  const { t } = useTranslation();

  return (
    <St.Wrapper className={className}>
      <Label size="small" weight="light">
        {label}
      </Label>

      <St.InputContentContainer>
        <St.CustomInput {...register} {...props} type="radio" autoComplete="off" data-testid="radio-yes" />
        <Label size="small" weight="light">
          {t('YES')}
        </Label>
      </St.InputContentContainer>

      <St.InputContentContainer>
        <St.CustomInput {...register} {...props} type="radio" autoComplete="off" data-testid="radio-no" />
        <Label size="small" weight="light">
          {t('NO')}
        </Label>
      </St.InputContentContainer>
      {errorMsg && (
        <Paragraph size="small" weight="light" className="errorMsg">
          {errorMsg}
        </Paragraph>
      )}
    </St.Wrapper>
  );
};
