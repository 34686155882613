import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, ViewLocked } from '@agendapro/emerald-icons';
import uniqBy from 'lodash/uniqBy';

import { Paragraph, Icon } from '@/UI';
import * as St from './ScheduleMap.styles';
import { ScheduleMapProps } from './ScheduleMap.types';

export const ScheduleMap: FC<ScheduleMapProps> = ({ schedule, className }) => {
  const { t } = useTranslation();
  const [showSchedule, setShowSchedule] = useState(false);
  const workingSchedule = uniqBy(
    schedule.filter((day) => day.isOpen === true),
    (day) => day.longDay,
  );

  return (
    <div className={className}>
      <St.DesktopDiv>
        <Paragraph weight="light">{`${t('ATTENDING_DAYS')}:`}</Paragraph>
        <St.DayMapWrapper>
          <St.DayContainer>
            <Paragraph weight="light">{workingSchedule.map((d) => d.longDay).join(', ')}</Paragraph>
          </St.DayContainer>
        </St.DayMapWrapper>
      </St.DesktopDiv>
      <St.MobileDiv>
        <div className="scheduleDiv">
          {showSchedule &&
            workingSchedule.map((day) => (
              <St.DayContainer key={day.dayId}>
                <Paragraph weight="light">{day.longDay}</Paragraph>
              </St.DayContainer>
            ))}
        </div>

        <St.SeeSchedule onClick={() => setShowSchedule(!showSchedule)}>
          <Icon icon={showSchedule ? <ViewLocked /> : <View />} size="medium" />
          <Paragraph weight="light">{showSchedule ? `${t('SEE_LESS')}` : `${t('SEE_SCHEDULE_DAYS')}`}</Paragraph>
        </St.SeeSchedule>
      </St.MobileDiv>
    </div>
  );
};
