import styled from 'styled-components';

import { NotificationModal } from '@/UI/Molecules/NotificationModal';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Container = styled(NotificationModal)`
  z-index: 10;
`;

export const Content = styled.div`
  text-align: center;
  font-size: 13px;
  margin: 1rem 0;
`;

export const ListWrapper = styled.ul`
  text-align: left;
  margin-left: 30px'
`;

export const Item = styled.li`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grays80};
  line-height: 17.77px;
  margin-bottom: 1.2rem;
`;

export const ParagraphContent = styled(Paragraph)`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.grays80};
  line-height: 17.77px;
  text-align: justify;
  margin-bottom: 1.2rem;
`;
