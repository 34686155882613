import styled from 'styled-components';

import { CalendarFull } from '@agendapro/emerald-icons';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div`
  display: flex;
  padding-left: 20px;
`;

export const CalendarIcon = styled(CalendarFull)`
  width: 100px;
  height: 100px;
  margin-right: 12px;
  display: none;
  @media ${devices['no-mobile']} {
    display: flex;
  }
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
`;

export const UserData = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const DataTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.brandTheme.brand100};
  margin-bottom: 12px;
`;
