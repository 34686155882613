import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/es';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

export const format = (date: Date | string, locale: string, dateFormat = 'LLLL a') =>
  dayjs(date).locale(locale).utcOffset(0).format(dateFormat);
