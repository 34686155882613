import { FC } from 'react';
import { Check } from '@agendapro/emerald-icons';

import { Icon, Paragraph } from '@/UI';

import * as St from './Step.styles';
import { StepProps } from './Step.types';

export const Step: FC<StepProps> = ({ completed, number, selected }) => (
  <St.Wrapper>
    {completed ? (
      <St.Checked>
        <Icon icon={<Check size={20} />} size="xxSmall" />
      </St.Checked>
    ) : (
      <St.StepNumber isSelected={selected}>
        <Paragraph>{number}</Paragraph>
      </St.StepNumber>
    )}
  </St.Wrapper>
);
