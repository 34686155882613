import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[4]};
  white-space: pre-wrap;
  path {
    fill: ${({ theme }) => theme.brandTheme.brand100};
  }
  & .widthDiscount {
    text-decoration: line-through;
  }
  svg {
    vertical-align: sub;
  }
`;
