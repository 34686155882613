import { FC, ReactElement, ReactNode } from 'react';
import * as St from './HeaderNotification.styles';

export const HeaderNotification: FC<{
  children: ReactNode;
  icon: ReactElement;
  className?: string;
}> = ({ children, icon, className }) => (
  <St.Wrapper className={className} data-cy="header_notification">
    <St.IconContainer>{icon}</St.IconContainer>
    <St.Content>{children}</St.Content>
  </St.Wrapper>
);
