export const fontFamily = 'Roboto, sans-serif';

export const fontSize = {
  'headline-desktop': '28px',
  'headline-mobile': '20px',

  'sectionHeadline-desktop': '23px',
  'sectionHeadline-mobile': '18px',

  'subHeadline-desktop': '16px',
  'subHeadline-mobile': '14px',

  'paragraph-desktop': '14px',
  'paragraph-mobile': '14px',

  'inputs-text-desktop': '14px',
  'inputs-text-mobile': '14px',

  'inputs-label-desktop': '14px',
  'inputs-label-mobile': '12px',

  'small-desktop': '14px',
  'small-mobile': '12px',

  'mdSmall-desktop': '13px',
  'mdSmall-mobile': '12px',

  'xsmall-desktop': '12px',
  'xsmall-mobile': '10px',
};

export const fontWeight = {
  bold: '700',
  regular: '500',
  light: '400',
};
