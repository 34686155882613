import styled, { css } from 'styled-components';
import { devices } from '../../Styles';

interface ButtonContainerProps {
  fullWidth?: boolean;
  color?: string;
  disabledColor?: string;
  minWidth?: string;
  outline?: boolean;
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: block;
  border: ${({ theme, color }) => `1px solid ${color || theme.brandTheme.brand100}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  cursor: pointer;
  background: ${({ theme, color, outline }) => (outline ? theme.palette.white : color || theme.brandTheme.brand100)};
  color: ${({ theme, outline, color }) => (outline ? color || theme.brandTheme.brand100 : theme.palette.white)};
  padding: 11px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
  background-blend-mode: lighten;
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}
  &:hover {
    background-blend-mode: darken;
  }
  @media ${devices['no-mobile']} {
    padding: 9px;
  }
  p {
    color: ${({ theme, outline, color }) => (outline ? color || theme.brandTheme.brand100 : theme.palette.white)};
  }
  &:disabled {
    background: ${({ theme, disabledColor }) => disabledColor || theme.brandTheme.brand50};
    border-color: ${({ theme, disabledColor }) => disabledColor || theme.brandTheme.brand02};
    cursor: not-allowed;
  }
`;
