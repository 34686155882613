import styled from 'styled-components';

export const Wrapper = styled.div<{
  openAccordion: boolean;
  margin: string;
  width?: string;
  maxHeight: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center;
  border-radius: 4px;
  padding: 0 15px;
  padding-bottom: ${({ openAccordion }) => (openAccordion ? '15px' : '0px')};
  cursor: pointer;
  border-radius: 8px;
  background: ${({ theme }) => theme.tokens.color.background.primary};
  margin: ${({ margin }) => margin || '0'};
  border: ${({ theme }) => `1px solid ${theme.tokens.color.border.primary}`};
  width: ${({ width }) => width || '100%'};
  transition: max-height 0.3s ease;

  h4 {
    font-size: 14px;
    color: ${({ theme }) => theme.tokens.color.text.default};
    font-weight: 500;
  }

  .accordionTitleContainer {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    width: 100%;
    padding: 12px 0;
  }

  .accordionContent {
    width: 100%;
    overflow: hidden;
    max-height: ${({ maxHeight }) => maxHeight};
    transition: max-height 0.3s ease;
  }
`;
