import React, { FC } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../../../Atoms';
import { fontFamily } from '../../../Styles';

import * as St from './Select.styles';
import { ClientSelectProps } from './Select.types';

export const ClientSelect: FC<ClientSelectProps> = ({
  errorMsg,
  label,
  options,
  control,
  name,
  rules,
  required,
  ...props
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <St.Wrapper>
      <div className="labelContainer">
        <Paragraph className="inputLabel">{label}</Paragraph>
        {required && <span className="required-span">*</span>}
      </div>
      <St.InputContentContainer isError={!!errorMsg}>
        <Controller
          name={`${name}`}
          control={control}
          rules={rules}
          render={({ field: { onChange, onBlur, value, name, ref }, formState }) => (
            <Select
              id="react-select-container"
              ref={ref}
              placeholder={t('SELECT_AN_OPTION')}
              classNamePrefix="custom_select"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? theme.brandTheme.brand100 : '#FFF',
                  color: state.isSelected ? 'white' : '#19181A',
                  fontFamily,
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontFamily,
                }),
                control: (base) => ({
                  ...base,
                  boxShadow: 'none',
                }),
              }}
              options={options}
              onChange={(val: any) => onChange(val.value)}
            />
          )}
        />
      </St.InputContentContainer>
      {errorMsg && (
        <Paragraph size="small" weight="light" className="errorMsg">
          {errorMsg}
        </Paragraph>
      )}
    </St.Wrapper>
  );
};
