import styled from 'styled-components';
import { devices } from '@/UI/Styles';

export const SocialIconsContainer = styled.div`
  display: flex;
  align-self: flex-end;

  @media ${devices.XLarge} {
    align-self: unset;
  }

  a:not(:first-child) {
    margin-left: 8px;
  }
  .socialIcon {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }
`;
