import styled, { css } from 'styled-components';
import { Skeleton } from '@/UI/Atoms/Skeleton';

export const FilterSkeletonContainer = styled.div<{ scrolled?: boolean }>`
  ${({ theme }) => css`
    display: none;
    width: 100%;
    padding-bottom: ${theme.spacing[32]};
    flex-direction: column;
    @media (min-width: ${theme.breakpoints.lg}) {
      display: flex;
      width: 315px;
    }
  `}
`;

export const CategorySkeletonContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing[16]};
    display: flex;
    align-items: center;
  `}
`;

export const CategorySkeleton = styled(Skeleton)`
  ${({ theme }) => css`
    border-radius: ${theme.spacing[8]};
    @media (min-width: ${theme.breakpoints.lg}) {
      width: 100%;
      height: ${theme.spacing[48]};
    }
  `}
`;
