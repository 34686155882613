import { Fragment, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@agendapro/emerald-icons';

import { Icon } from '@/UI';
import { ListContainer, ListItem, ServicesList, Content, DeleteElement, DeleteText } from './styles';
import { FilterElementProps } from './types';

export const FilterElement: FC<FilterElementProps> = ({
  servicesToReserve,
  bookingServices,
  deleteService,
  dispatch,
}) => {
  const { t } = useTranslation();

  return (
    <ListContainer aria-label="filterElement">
      {servicesToReserve.map((itemService) => (
        <Fragment key={itemService.id}>
          {bookingServices.map(({ serviceId }) => {
            let element;

            if (itemService.id === serviceId) {
              element = (
                <ListItem key={serviceId}>
                  <ServicesList>
                    <Content>{itemService.name}</Content>
                    {!!deleteService && (
                      <DeleteElement onClick={() => dispatch({ type: 'REMOVE_SERVICE', payload: itemService })}>
                        <Icon icon={<Delete />} className="deleteIcon" />
                        <DeleteText>{t('DELETE_SERVICE')}</DeleteText>
                      </DeleteElement>
                    )}
                  </ServicesList>
                </ListItem>
              );
            }
            return element;
          })}
        </Fragment>
      ))}
    </ListContainer>
  );
};
