import styled from 'styled-components';
import { applyEllipsisByNumOfLines } from '@/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[16]};
`;

export const VideoConferenceContainer = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 4px;
    color: ${({ theme }) => theme.brandTheme.brand100};
  }
`;

export const CollapseButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing[16]};
  .serviceNumber {
    font-size: ${({ theme }) => theme.fontSize['xsmall-desktop']};
  }
  .serviceName {
    font-weight: 500;
    color: ${({ theme }) => theme.migrateColors('text.subtle', 'gray')};
    ${applyEllipsisByNumOfLines(1)};
  }
`;

export const CollapseContent = styled.div``;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ theme }) => theme.spacing[8]} 0;
  background-color: ${({
    theme: {
      tokens: { color },
    },
  }) => color.border.primary};
`;
