import { FC, useState } from 'react';
import { View, ViewLocked } from '@agendapro/emerald-icons';
import { Icon, Paragraph } from '../../Atoms';
import { InputContainer, InputContentContainer, CustomInput, ViewPasswordContainer } from './Input.styles';
import { InputProps, ViewPasswordProps } from './types';

const ViewPassword: FC<ViewPasswordProps> = ({ handleClick, showPassword }) => (
  <ViewPasswordContainer onClick={() => handleClick()} role="button">
    <Icon icon={showPassword ? <ViewLocked /> : <View />} size="small" />
  </ViewPasswordContainer>
);

export const Input: FC<InputProps> = ({ register, icon, errorMsg, label, className, type, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer className={className}>
      <label htmlFor={label}>
        <Paragraph size="small" weight="light">
          {label}
        </Paragraph>
      </label>
      <InputContentContainer error={errorMsg}>
        {icon && <Icon icon={icon} data-testid="inputIcon" size="medium" />}
        {showPassword ? (
          <>
            <CustomInput id={label} {...register} {...props} type="text" autoComplete="off" data-testid="customInput" />
            <ViewPassword handleClick={handleClick} showPassword={showPassword} />
          </>
        ) : (
          <>
            <CustomInput id={label} {...register} {...props} data-testid="customInput" type={type} />
            {type === 'password' && <ViewPassword handleClick={handleClick} showPassword={showPassword} />}
          </>
        )}
      </InputContentContainer>
      {errorMsg && (
        <Paragraph size="small" weight="light" className="errorMsg" role="alert">
          {errorMsg}
        </Paragraph>
      )}
    </InputContainer>
  );
};
