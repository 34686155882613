import styled from 'styled-components';
import { devices, fontWeight } from '@/UI/Styles';
import { SizeType } from './types';

interface LabelProps {
  weight: keyof typeof fontWeight;
  caps?: boolean;
  align: string;
  size: SizeType;
}

export const LabelContainer = styled.label<LabelProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  margin: 0;
  font-weight: ${({ weight, theme }) => theme.fontWeight[weight]};
  text-transform: ${({ caps }) => (caps ? 'uppercase' : '')};
  text-align: ${({ align }) => align};
  font-size: ${({ size, theme }) => theme.fontSize[`${size}-mobile`]};
  @media ${devices['no-mobile']} {
    font-size: ${({ size, theme }) => theme.fontSize[`${size}-desktop`]};
  }
`;
