import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AgendaProRebranding, MobileArrowBottom } from '@agendapro/emerald-icons';
import { useRouter } from 'next/router';

import { Button, Text } from '@agendapro/emerald/v2';
import { useMediaQuery } from 'react-responsive';
import { useReservation } from '@/context/ReservationsContext';
import { Icon, Paragraph, devices } from '@/UI';
import { formatAMPMHour, showSeeAllHoursAvailable } from '@/utils';
import { isEllipsisApplied } from '@/UI/Utils';
import { ScheduleMap } from '../../ProviderDetails/ScheduleMap';
import { usePageContext, useFormatCurrency } from '@/hooks';

import { ServiceDateSelectorProps } from './ServicesDateSelector.types';
import { useServiceProviders } from '@/services/serviceProviders';
import DateSelector from './DateSelector/DateSelector';
import { StepTracker } from '../StepTracker';
import * as St from './ServicesDateSelector.styles';
import ServicePriceDiscount from '@/components/ServicePriceDiscount';
import { handleNextStepMobile } from '../Helpers';
import PoweredBy from '@/components/PoweredBy/PoweredBy';

export const ServicesDateSelector: FC<ServiceDateSelectorProps> = ({ handleNext }) => {
  const router = useRouter();
  const { companyOverview, isSalfa } = usePageContext();
  const { locationId } = router.query as { locationId: string };
  const [showBundledServices, setShowBundledServices] = useState(false);
  const { reservations, dispatch } = useReservation();
  const isDesktop = useMediaQuery({ query: devices.XLarge });

  const {
    servicesToReserve,
    isEditing,
    providerPreSelected,
    serviceBeingReserved,
    isSession,
    currentIndex,
    bookingMode,
    daySelected,
  } = reservations;
  const { data: providersByLocation } = useServiceProviders(locationId);
  const { showPrice } = serviceBeingReserved!;
  const { t } = useTranslation();
  const fc = useFormatCurrency();
  const isXLarge = useMediaQuery({
    query: devices.XLarge,
  });
  const descriptionRef = useRef<any>();

  const handleNextStep = () => handleNextStepMobile({ reservations, isSalfa, dispatch });

  const getDateFormatted = () => {
    let dateFormatted = '';

    if (servicesToReserve.length >= 1) {
      if (servicesToReserve[currentIndex]?.hourSelected) {
        if (servicesToReserve[currentIndex].showDuration) {
          dateFormatted = `${servicesToReserve[currentIndex]?.dateFormatted} - ${formatAMPMHour(
            servicesToReserve[currentIndex]?.hourSelected,
          )} - ${formatAMPMHour(servicesToReserve[currentIndex]?.hourEnd)}`;
        } else {
          dateFormatted = `${servicesToReserve[currentIndex]?.dateFormatted} - ${formatAMPMHour(
            servicesToReserve[currentIndex]?.hourSelected,
          )}`;
        }
      }
      if (servicesToReserve[currentIndex]?.dateFormatted && !servicesToReserve[currentIndex].hourSelected) {
        dateFormatted = `${servicesToReserve[currentIndex]?.dateFormatted} - --:-- ${t('HRS')}`;
      }
    }
    return dateFormatted;
  };

  const checkButtonEnabled = () => !!servicesToReserve[currentIndex]?.hourSelected;
  const dateFormatted = getDateFormatted();

  // eslint-disable-next-line no-console
  return (
    <>
      <St.ServicesDateSelectorContainer showBundledServices={showBundledServices}>
        <Paragraph size="subHeadline" className="dateSelectorTitle" weight="bold">
          {`${t('SELECT_DATE_AND_TIME_OF_PREFERENCE')}`}
        </Paragraph>
        <St.ServiceReminder>
          {providerPreSelected && !isSession && !isSalfa && (
            <St.ServiceProviderContainer data-testid="service-provider-container">
              <St.ProviderAvatar>
                {providerPreSelected.image ? (
                  <St.ImgAvatar
                    src={providerPreSelected.image}
                    alt="Img Avatar"
                    width={60}
                    height={60}
                    objectFit="contain"
                  />
                ) : (
                  <St.CustomInitialsAvatar
                    name={
                      providerPreSelected
                        ? providerPreSelected.publicName
                        : servicesToReserve[currentIndex].serviceProvider?.publicName || ''
                    }
                  />
                )}
              </St.ProviderAvatar>
              <St.ServiceProviderData>
                <Paragraph size="subHeadline">
                  {providerPreSelected
                    ? providerPreSelected.publicName
                    : servicesToReserve[currentIndex].serviceProvider?.publicName}
                </Paragraph>
                {providerPreSelected && (
                  <ScheduleMap className="providerSchedule" schedule={providerPreSelected.providerTimes} />
                )}
              </St.ServiceProviderData>
            </St.ServiceProviderContainer>
          )}
          <St.Wrapper>
            {serviceBeingReserved?.bundled && (
              <St.AccordionTitle onClick={() => setShowBundledServices(!showBundledServices)}>
                <Icon icon={<MobileArrowBottom />} className="serviceBottomArrow" size="xxSmall" />
                <St.BundledServicesTitle>{`${t('ASSOCIATED_SERVICES')}`}</St.BundledServicesTitle>
              </St.AccordionTitle>
            )}
          </St.Wrapper>
          {showBundledServices && (
            <St.BundledServicesWrapper>
              <St.BundledServiceList>
                {serviceBeingReserved?.bundle?.map((bundleService: any) => (
                  <Paragraph weight="light" key={bundleService.id}>
                    <li>{`${bundleService.name}  (${bundleService.duration}${t('ABBREVIATED_MINUTES')})`}</li>
                  </Paragraph>
                ))}
              </St.BundledServiceList>
            </St.BundledServicesWrapper>
          )}
        </St.ServiceReminder>
        <StepTracker isEditing={isEditing} />
        <DateSelector handleNext={handleNext} />
        {showSeeAllHoursAvailable(reservations, providersByLocation, companyOverview?.isPlanSolo) && (
          <St.SeeAllDatesContainer>
            <Paragraph className="removeProviderText" weight="light">{`${t("DIDN'T_FIND_SERVICES_TEXT")}`}</Paragraph>
            <Paragraph
              className="removeProvider"
              weight="light"
              onClick={() => dispatch({ type: 'REMOVE_PRE_SELECTED_PROVIDER' })}
            >
              {t('SEE_ALL_DATES_AVAILABLE')}
            </Paragraph>
          </St.SeeAllDatesContainer>
        )}

        <St.Spacer />
      </St.ServicesDateSelectorContainer>
      {!isDesktop && (
        <St.MobileDrawer>
          <St.DrawerContainer>
            {isSession ? (
              <St.DrawerTextContainer>
                <St.ServiceText weight="light">{`${t('SESSION')} ${currentIndex + 1} ${t('OF')} ${
                  servicesToReserve[0].sessionsAmount
                } (${servicesToReserve[0].duration} ${t('MINUTES')})`}</St.ServiceText>
                <Paragraph weight="bold" size="subHeadline">
                  {servicesToReserve[0].name}
                </Paragraph>
                <St.DateText size="small">{dateFormatted}</St.DateText>
              </St.DrawerTextContainer>
            ) : (
              <St.DrawerTextContainer>
                <div ref={descriptionRef}>
                  <St.ServiceText clamp={servicesToReserve.length > 4 ? 1 : 2}>
                    {bookingMode === 'consecutive'
                      ? servicesToReserve.map((service) => service.name).join(', ')
                      : serviceBeingReserved?.name}
                  </St.ServiceText>
                  {bookingMode === 'consecutive' &&
                    servicesToReserve.length > 4 &&
                    descriptionRef.current &&
                    isEllipsisApplied(descriptionRef.current.firstChild) && (
                      <St.ServiceText>{t('AND_OTHER_SERVICES')}</St.ServiceText>
                    )}
                </div>
                {showPrice && <ServicePriceDiscount />}
                <St.DateText size="small">{dateFormatted}</St.DateText>
              </St.DrawerTextContainer>
            )}

            <St.ButtonContainer>
              <Button
                id="button_mobile_date_selection"
                className="button_mobile_date_selection"
                disabled={!checkButtonEnabled()}
                onClick={handleNextStep}
                data-testid="button_next_date_selector"
                data-cy="button_next_date_selector"
              >
                {t('BUTTON_NEXT')}
              </Button>
            </St.ButtonContainer>
          </St.DrawerContainer>
          <PoweredBy />
        </St.MobileDrawer>
      )}
    </>
  );
};
