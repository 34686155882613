import React from 'react';
import { Skeleton } from '../../Skeleton';

const ViewMoreSkeleton = () => (
  <div style={{ marginBottom: 24 }}>
    <Skeleton style={{ width: '50%', height: 18, marginBottom: 24 }} />
    <Skeleton style={{ width: '100%', height: 18 }} />
    <Skeleton style={{ width: '100%', height: 18 }} />
    <Skeleton style={{ width: '100%', height: 18 }} />
    <Skeleton style={{ width: '100%', height: 18 }} />
  </div>
);

export default ViewMoreSkeleton;
