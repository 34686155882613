import { EmeraldThemeProvider, Spinners } from '@agendapro/emerald';
import { SpinnerProps } from '@agendapro/emerald/dist/src/atoms/Spinners/types';
import React from 'react';

function EmeraldSpinner(props: SpinnerProps) {
  return (
    <EmeraldThemeProvider>
      <Spinners {...props} />
    </EmeraldThemeProvider>
  );
}

export default EmeraldSpinner;
