import styled, { css } from 'styled-components';

import { Clock, Gift } from '@agendapro/emerald-icons';
import { devices } from '@/UI/Styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { Button } from '@/UI/Atoms/Button';
import { applyFlexAndCenter } from '@/styles';

export const Wrapper = styled.div`
  padding: 0px ${({ theme }) => theme.spacing[16]};
  min-height: 300px;
`;

export const DaySelectContainer = styled.div<{ charlyEndDate: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding-bottom: ${({ theme, charlyEndDate }) => (charlyEndDate ? 0 : theme.spacing[32])};
  .arrowIcon {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.brandTheme.brand100};
    }
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const OtherDatesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .otherDatesText {
    color: ${({ theme }) => theme.palette.grays80};
    margin: 0px 18px;
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px auto 20px;
  flex-direction: column-reverse;
  row-gap: 15px;

  @media ${devices.XLarge} {
    margin: 16px auto 20px;
    flex-direction: row;
    row-gap: 0px;
  }
`;

export const MonthsContainer = styled.div`
  display: flex;
`;

export const SelectorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 90px;
`;

export const ArrowButton = styled.button`
  ${applyFlexAndCenter}
  svg {
    width: 15px;
    height: 15px;
    padding: 0;
    path {
      fill: ${({ theme, disabled }) => (!disabled ? theme.palette.grays100 : theme.palette.grays20)};
    }
    @media ${devices.XLarge} {
      width: 20px;
      height: 20px;
    }
  }
  padding: 0;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'unset')};
`;

export const Month = styled.div<{ isSelected: boolean }>`
  margin-right: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${({ theme, isSelected }) => (isSelected ? theme.brandTheme.brand100 : theme.brandTheme.brand50)};
    font-size: 18px;
    transition: 150ms ease-in-out;
    text-transform: capitalize;
  }
  @media ${devices['no-mobile']} {
    margin-right: 20px;
  }
`;

const checkBorder = (isFirstDate, isLastDate, theme) => {
  if (isFirstDate && isLastDate) {
    return `${theme.spacing[8]} ${theme.spacing[8]} 0px ${theme.spacing[8]}`;
  }

  if (isFirstDate) {
    return `${theme.spacing[8]} 0px 0px ${theme.spacing[8]}`;
  }
  if (isLastDate) {
    return `0px ${theme.spacing[8]} 0px 0px`;
  }
  return '0px';
};

export const DiscountContainer = styled.div<{ isFirstDate: boolean; isLastDate: boolean; isInRange: boolean }>`
  ${({ theme, isFirstDate, isLastDate, isInRange }) => css`
    position: relative;
    padding: ${`${theme.spacing[12]} 0px`};
    border-radius: ${checkBorder(isFirstDate, isLastDate, theme)};
    border-left: ${isFirstDate ? `1px solid ${theme.palette.orange400}` : 'none'};
    border-right: ${isLastDate ? `1px solid ${theme.palette.orange400}` : 'none'};
    border-top: ${isInRange ? `1px solid ${theme.palette.orange400}` : 'none'};
    border-bottom: ${isInRange ? `1px solid ${theme.palette.orange400}` : 'none'};
    @media ${devices.XLarge} {
      padding: ${`${theme.spacing[12]} ${theme.spacing[8]}`};
    }
  `}
`;

export const GiftTab = styled(Gift)`
  position: absolute;
  bottom: -33px;
  right: -1px;
  background-color: ${({ theme }) => theme.palette.orange400};
  padding: ${({ theme }) => theme.spacing[4]};
  border-radius: 0px 0px 8px 8px;
  path {
    fill: ${({ theme }) => theme.palette.white};
  }
`;

export const DayContainer = styled.button<{ isSelected: boolean; inRange: boolean }>`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'unset')};
  border-radius: 25px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.brandTheme.brand100 : theme.palette.white)};
  width: 36px;
  height: 65px;
  transition: 300ms ease-in-out;
  p {
    text-transform: capitalize;
    color: ${({ isSelected, disabled, theme, inRange }) => {
      if (disabled) {
        return theme.palette.grays20;
      }
      if (isSelected) {
        return theme.palette.white;
      }
      if (inRange) {
        return theme.palette.orange400;
      }
      return theme.palette.grays100;
    }};
  }
  @media ${devices.XLarge} {
    width: 38px;
  }
`;

export const Day = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  height: 65px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Dot = styled.div`
  padding: 0px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.brandTheme.brand100};
  border-radius: 50%;
  bottom: -10px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[40]};
`;

export const CharlyText = styled(Paragraph)`
  padding-top: ${({ theme }) => theme.spacing[32]};
`;

export const HoursContainer = styled.div`
  margin-bottom: 25px;
  min-height: 200px;
  position: relative;

  @media ${devices.XLarge} {
    height: 300px;
    padding-bottom: 0px;
  }
`;

export const ScrollWrapper = styled.div`
  position: relative;
  height: 100%;

  @media ${devices.XLarge} {
    overflow-y: auto;
    padding-bottom: 100px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HourLoaderContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const TurnContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px;
`;

export const TurnDivision = styled.div`
  margin-left: 12px;
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
`;

export const NoResultsContainer = styled.div<{ noDaysAvailable: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ noDaysAvailable }) => (noDaysAvailable ? '400px' : '200px')};
`;

export const StyledClock = styled(Clock)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
  path {
    fill: ${({ theme }) => theme.palette.grays50};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing[12]};
  padding: ${({ theme }) => theme.spacing[12]} ${({ theme }) => theme.spacing[24]};
  p {
    font-size: 16px;
  }
`;

export const RemoveBtn = styled.button<{ noDaysAvailable: boolean }>`
  margin: 0px !important;
  padding-top: ${({ theme, noDaysAvailable }) => (noDaysAvailable ? theme.spacing[16] : theme.spacing[32])};
  p {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.grays50};
  }
`;

export const DaysLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  p {
    font-size: ${({ theme }) => theme.fontSize['paragraph-desktop']};
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: #84a0a0;
    text-align: center;
  }
`;
