import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalPlansProps } from './types';

import { ModalPlansContainer, ModalPlansContent, ModalPlansSection, ParagraphContent } from './styles';

import { FilterElement } from './FilterElement';
import { useReservation } from '@/context/ReservationsContext';

export const ModalPlans: FC<ModalPlansProps> = ({
  actionType,
  bookingServices,
  handleClose,
  handleConfirm,
  hideModalPlans,
  noneIncluded,
  showModal,
}) => {
  const { t } = useTranslation();
  const { reservations, dispatch } = useReservation();
  const { servicesToReserve } = reservations;

  const isRenderElement = (text, bookingObject, marginBottom, deleteService) => (
    <ModalPlansSection>
      <ParagraphContent marginBottom={marginBottom} size="mdSmall">
        {t(text)}
      </ParagraphContent>
      <FilterElement
        servicesToReserve={servicesToReserve}
        bookingServices={bookingObject}
        deleteService={deleteService}
        dispatch={dispatch}
      />
    </ModalPlansSection>
  );

  const checkType = () => {
    if (allIncludedInPlan) {
      return 'success';
    }
    return 'warning';
  };

  const bookingsMembership = bookingServices && bookingServices.bookingsMembership;
  const bookingsData = bookingServices && bookingServices.bookingsData;
  const allIncludedInPlan = bookingsData?.available.length === bookingsMembership?.available.length;
  const mismatchedServices = bookingsData?.available.filter(
    (available) =>
      !bookingsMembership?.available.map((membership) => membership.serviceId).includes(available.serviceId),
  );
  const isPartialSelection = !allIncludedInPlan && !noneIncluded;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (allIncludedInPlan) {
        hideModalPlans();
        handleConfirm();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [hideModalPlans, handleConfirm, allIncludedInPlan]);

  if (!showModal || !bookingServices) {
    return null;
  }

  const unavailableKey =
    actionType === 'reserve'
      ? 'MODAL_PLANS.BOOKING_MEMBERSHIP_UNAVAILABLE_BY_RESERVE'
      : 'MODAL_PLANS.BOOKING_MEMBERSHIP_UNAVAILABLE_BY_PAY_AND_RESERVE';
  const noneIncludedKey =
    actionType === 'reserve'
      ? 'MODAL_PLANS.BOOKING_PLANS_FULL_BY_RESERVE'
      : 'MODAL_PLANS.BOOKING_PLANS_FULL_BY_PAY_AND_RESERVE';

  return (
    <ModalPlansContainer
      type={checkType()}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      hideCancelButton={allIncludedInPlan}
      hideConfirmButton={allIncludedInPlan}
    >
      <ModalPlansContent>
        {bookingsMembership && (
          <>
            {bookingsMembership.available.length === bookingsData?.available.length && (
              <ParagraphContent size="mdSmall" marginBottom>
                {t('MODAL_PLANS.BOOKING_MEMBERSHIP_SUCCESS')}
              </ParagraphContent>
            )}
            {noneIncluded && (
              <ParagraphContent size="mdSmall" marginBottom>
                {t(noneIncludedKey)}
              </ParagraphContent>
            )}
            {isPartialSelection &&
              !!bookingsMembership.available.length &&
              isRenderElement('MODAL_PLANS.BOOKING_MEMBERSHIP_AVAILABLE', bookingsMembership.available, false, false)}
            {isPartialSelection &&
              !!mismatchedServices?.length &&
              isRenderElement(unavailableKey, mismatchedServices, true, true)}
          </>
        )}
      </ModalPlansContent>
    </ModalPlansContainer>
  );
};
