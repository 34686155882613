import axios from 'axios';
import { API_V1 } from '@/infra/http';
import * as Types from './user.types';
import { deleteCookie, setCookie } from '@/utils';
import { ENV } from '@/config/env';

export const getUser = () =>
  API_V1.get<Types.UserResponse>(`api/views/admin/v1/users/get_user`).then((res) => res.data);

export const login = (credentials: Types.LoginRequest) =>
  axios
    .post(
      `${ENV.BACK_URL}/users/sign_in`,
      { user: credentials },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'Authorization',
          actionLoader: 'false',
        },
      },
    )
    .then((res) => {
      setCookie('ap_authorization', res.headers.authorization, 5000);

      return res.data;
    });

export const logout = () =>
  API_V1.delete(`users/sign_out`).then((res) => {
    deleteCookie('ap_authorization');

    return res.data;
  });

export const loginWithSocialAccount = ({ device, accessToken }) =>
  API_V1.post(`/users/oauth`, { device, accessToken }).then((res) => res.data);

export const getUserByCode = ({ userCode }) =>
  API_V1.get<Types.UserResponse>(`api/views/admin/v1/users/user_with_user_code`, { params: { userCode } }).then(
    (res) => res.data,
  );
