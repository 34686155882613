import styled from 'styled-components';
import { fadeIn, keyFramesFadeIn } from '../../../utils';
import { devices } from '../../Styles';
import { Icon } from '../Icon';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: ${({ theme }) => theme.palette.grays50};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${fadeIn(200)}
  ${keyFramesFadeIn}
`;

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Close = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grays80};
  margin-bottom: 12px;
  @media ${devices['no-mobile']} {
    margin-bottom: 17px;
  }
`;

export const Content = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  @media ${devices['no-mobile']} {
    position: relative;
    width: 479px;
  }

  .formAlert {
    margin: 0 auto;
    width: 94.88%;
    margin-bottom: 19px;
    p {
      color: ${({ theme }) => theme.palette.white};
    }
    @media ${devices['no-mobile']} {
      width: 100%;
      margin-bottom: 17px;
    }
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.palette.white};
  padding: 50px;
  border-radius: 25px 25px 0 0;
  @media ${devices['no-mobile']} {
    padding: 32px;
    border-radius: 25px;
  }
`;
