import { EditBookingAdapterResponse, EditBookingResponse } from './bookings.types';

export const bookingsEditAdapter = (obj: EditBookingResponse): EditBookingAdapterResponse => ({
  ...obj,
  service: {
    ...obj.service,
    bundled: obj.booking.bundled,
    bundle: [],
    payingPrice: obj.service.price,
    promoActive: false,
    promoDays: '',
    promoHours: '',
  },
});
