/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { applyEllipsisByNumOfLines } from '@/styles';
import { applyBoxShadowSm, devices } from '../../Styles';

export const Wrapper = styled.button<{ isSelected: boolean; isCharly: boolean }>`
  ${({ theme, isSelected, isCharly }) => css`
    background-color: ${isCharly && isSelected
      ? theme.palette.orange400
      : isSelected
      ? theme.palette.background
      : theme.palette.white};
    cursor: pointer;
    transition: 200ms ease-in-out;
    &:hover {
      background-color: ${isSelected ? '' : theme.palette.background};
    }
    &:focus {
      background-color: ${isSelected ? theme.palette.background : theme.palette.white};
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      background-color: ${isCharly && isSelected
        ? theme.palette.orange400
        : isSelected
        ? theme.tokens.color.background.tertiary
        : theme.palette.white};
      &:hover {
        background-color: ${isCharly && !isSelected
          ? theme.palette.orange100
          : isSelected
          ? ''
          : theme.tokens.color.background.tertiary};
      }
      &:focus {
        background-color: ${isCharly && isSelected
          ? theme.palette.orange400
          : isSelected
          ? theme.tokens.color.background.tertiary
          : theme.palette.white};
      }
    }
  `}

  border: ${({ isSelected, theme }) =>
    isSelected ? `1px solid ${theme.palette.white}` : `1px solid ${theme.palette.background};`};
  border-radius: 6px;
  padding: 8px 16px;
  transition: 200ms ease-in-out;
  white-space: nowrap;
  ${applyBoxShadowSm};
  p {
    color: ${({ isSelected, theme, isCharly }) =>
      isCharly && isSelected ? theme.palette.white : isSelected ? theme.palette.grays100 : theme.palette.grays200};
    ${applyEllipsisByNumOfLines(2)};
  }
  @media ${devices.XLarge} {
    padding: 12px 20px;
    border: 0;
    white-space: normal;
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;
