export const applyEllipsisByNumOfLines = (numOfLines = 1) => `
    display: -webkit-box;
    -webkit-line-clamp: ${numOfLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
`;

export const applyFlexAndCenter = () => `
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const applyBoxShadowSm = () => `
    box-shadow: 0px 4px 4px 0px #0000000a;
`;
