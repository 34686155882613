import styled from 'styled-components';

export const WhatsAppLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .whatsAppIcon {
    path {
      fill: ${({ theme }) => theme.palette.grays80};
    }
  }
  .whatsAppLink {
    margin-left: 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
