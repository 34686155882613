import omit from 'lodash/omit';
import { BookingInfoResponse } from '@/services/company';
import { ReservationContextActions, ReservationStateProps } from './ReservationContext.types';
import i18n from '@/utils/i18n';

export const reservationsInitialState: ReservationStateProps = {
  handleClose: undefined,
  companyBookingInfo: {} as BookingInfoResponse,
  servicesToReserveUnmuted: [],
  servicesToReserve: [],
  serviceBeingReserved: null,
  locationId: null,
  daySelected: null,
  hourSelected: null,
  providerPreSelected: null,
  daysLoading: true,
  servicesIds: undefined,
  servicesWithProviders: {},
  client: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined,
    identificationNumber: undefined,
  },
  clientValidation: { isValid: false, acceptedTerms: false },
  step: 'modeSelection',
  bookingMode: null,
  isEditing: false,
  isSession: false,
  currentIndex: -1,
  entity: undefined,
  recordHistory: undefined,
  canChooseProvider: 'CAN',
  setSelected: () => {},
  skipStep: true,
};

export const reservationsReducer = (
  state: ReservationStateProps = reservationsInitialState,
  action: ReservationContextActions,
): ReservationStateProps => {
  switch (action.type) {
    case 'SET_SERVICES_IDS': {
      return {
        ...state,
        servicesIds: action.payload,
      };
    }
    case 'REMOVE_PRE_SELECTED_PROVIDER': {
      const newServicesToReserve = state.servicesToReserve;
      let newServicesWithProviders = {};

      if (state.bookingMode === 'consecutive' || state.serviceBeingReserved?.bundled) {
        Object.entries(state.servicesWithProviders).forEach((entry) => {
          newServicesWithProviders = {
            ...newServicesWithProviders,
            [entry[0]]: { providerId: 0, publicName: i18n.t('FIRST_PROVIDER_AVAILABLE') },
          };
        });
      } else {
        newServicesWithProviders = { ...state.servicesWithProviders };
        newServicesWithProviders = omit(newServicesWithProviders, state.serviceBeingReserved?.internalId!);
      }
      if (state.currentIndex !== -1) {
        newServicesToReserve[state.currentIndex] = {
          ...newServicesToReserve[state.currentIndex],
          daySelected: null,
          serviceProvider: undefined,
        };
      }
      return {
        ...state,
        providerPreSelected: null,
        daySelected: null,
        hourSelected: null,
        servicesWithProviders: newServicesWithProviders,
        servicesToReserve: newServicesToReserve,
        serviceBeingReserved: state.currentIndex !== -1 ? newServicesToReserve[state.currentIndex] : null,
      };
    }

    case 'SET_SERVICES_WITH_PROVIDERS': {
      return {
        ...state,
        servicesWithProviders: action.payload,
      };
    }
    case 'SET_LOCATION_ID': {
      return {
        ...state,
        locationId: action.payload,
      };
    }
    case 'SET_SERVICES_TO_RESERVE': {
      return {
        ...state,
        servicesToReserve: action.payload,
      };
    }
    case 'SET_SERVICE_BEING_RESERVED': {
      return {
        ...state,
        serviceBeingReserved: action.payload,
        currentIndex: state.servicesToReserve.findIndex((service) => service.internalId === action.payload?.internalId),
        servicesIds: action.payload ? [action.payload.internalId!] : state.servicesIds,
        hourSelected: null,
        daySelected: null,
      };
    }
    case 'SET_PROVIDER_PRE_SELECTED': {
      return {
        ...state,
        providerPreSelected: action.payload,
      };
    }
    case 'SET_DAY_SELECTED': {
      return {
        ...state,
        daySelected: action.payload,
      };
    }
    case 'SET_DAYS_LOADING': {
      return {
        ...state,
        daysLoading: action.payload,
      };
    }
    case 'SET_HOUR_SELECTED': {
      return {
        ...state,
        hourSelected: action.payload,
      };
    }
    case 'SET_STEP': {
      return {
        ...state,
        step: action.payload,
      };
    }
    case 'SET_RECORD_HISTORY': {
      return {
        ...state,
        recordHistory: action.payload,
      };
    }
    case 'SET_CLIENT': {
      return {
        ...state,
        client: { ...state.client, ...action.payload },
      };
    }
    case 'SET_CLIENT_VALIDATION': {
      return {
        ...state,
        clientValidation: action.payload,
      };
    }
    case 'SET_IS_EDITING': {
      return {
        ...state,
        isEditing: action.payload,
      };
    }

    case 'ADD_DATA_TO_CURRENT_SERVICE': {
      return {
        ...state,
        servicesToReserve: state.servicesToReserve.map((service) =>
          state.servicesToReserve.indexOf(service) === state.currentIndex ? { ...service, ...action.payload } : service,
        ),
      };
    }
    case 'REMOVE_SERVICE': {
      const { servicesToReserve, currentIndex, servicesWithProviders, serviceBeingReserved } = state;
      const serviceToDelete = action.payload;
      const selectedService = servicesToReserve[currentIndex];
      const servicesToReserveFiltered = servicesToReserve.filter(
        (service) => service.internalId !== serviceToDelete.internalId,
      );
      const uncompletedServiceIndex = servicesToReserveFiltered.findIndex((service) => !service.hourSelected);
      const omitedProvider = omit(servicesWithProviders, [serviceToDelete.internalId as string]);

      state.setSelected(servicesToReserve.filter((service) => service.internalId !== serviceToDelete.internalId));

      if (serviceToDelete.internalId === selectedService?.internalId) {
        return {
          ...state,
          servicesToReserve: servicesToReserveFiltered,
          daySelected: null,
          hourSelected: null,
          serviceBeingReserved: uncompletedServiceIndex !== -1 ? servicesToReserve[uncompletedServiceIndex] : null,
          step: uncompletedServiceIndex !== -1 ? 'dateSelection' : 'serviceSelection',
          currentIndex: uncompletedServiceIndex,
          servicesWithProviders: omitedProvider,
        };
      }
      return {
        ...state,
        servicesToReserve: servicesToReserveFiltered,
        servicesWithProviders: omitedProvider,
        currentIndex: servicesToReserveFiltered.findIndex(
          (service) => service.internalId === serviceBeingReserved?.internalId,
        ),
      };
    }
    case 'SET_BOOKING_MODE': {
      return {
        ...state,
        bookingMode: action.payload,
      };
    }
    case 'ADD_DATA_TO_SERVICE_BY_INDEX': {
      return {
        ...state,
        servicesToReserve: state.servicesToReserve.map((service, index) =>
          index === action.payload.index ? { ...service, ...action.payload.data } : service,
        ),
      };
    }
    default:
      return state;
  }
};
