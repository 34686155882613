import styled, { css } from 'styled-components';
import { fadeIn, keyFramesFadeIn } from '../../../utils';
import { devices } from '../../Styles/Queries';

interface SpinnerContainerProps {
  margin?: string;
  fullScreen?: boolean;
}

interface SpinnerLoaderProps {
  size?: string;
  desktopSize?: string;
}

export const SpinnerContainer = styled.div<SpinnerContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: ${({ fullScreen }) => (fullScreen ? 'absolute' : 'unset')};
  background-color: ${({ fullScreen, theme }) => fullScreen && theme.palette.white};
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
  top: 0;
  left: 0;
  z-index: 10;
  ${fadeIn(300)}
  ${keyFramesFadeIn}
`;

export const SpinnerLoader = styled.div<SpinnerLoaderProps>`
  border: ${({ theme }) => `1px solid ${theme.palette.grays20}`};
  border-left-color: ${({ theme, color }) => color || theme.brandTheme.brand100};
  animation: loader-spin 1.2s infinite linear;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: ${({ size }) => size || '60px'};
  height: ${({ size }) => size || '60px'};

  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${devices['no-mobile']} {
    width: ${({ desktopSize }) => desktopSize || '110px'};
    height: ${({ desktopSize }) => desktopSize || '110px'};
  }
`;
