import styled from 'styled-components';
import { devices, fontFamily, fontSize } from '../../../Styles';

export const InputContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 100%;
  i {
    margin-right: 8px;
  }
  @media ${devices['no-mobile']} {
    justify-content: flex-start;
    i {
      margin-right: 4px;
    }
  }
`;
export const CustomInput = styled.textarea<{ error?: string }>`
  width: 100%;
  border: ${({ error, theme }) =>
    error
      ? `1px solid ${theme.migrateColors('border.inputError', 'alert')}`
      : `1px solid ${theme.migrateColors('border.inputDefault', 'gray')}`};
  padding: ${({ theme }) => theme.spacing[8]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  resize: none;
  height: 80px;
  font-family: ${fontFamily};
  font-size: ${fontSize['inputs-text-mobile']};
  ::placeholder {
    color: ${({ theme }) => theme.migrateColors('text.subtlest', 'gray50')} !important;
  }
  &:focus {
    border: ${({ theme }) => `1px solid ${theme.brandTheme.brand100}`};
    outline: none;
  }
  @media ${devices['no-mobile']} {
    font-size: ${fontSize['inputs-text-desktop']};
  }
`;
