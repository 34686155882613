import { FC } from 'react';

import { Icon, Paragraph } from '../../../Atoms';
import { InputContainer } from '../Input/Input.styles';

import * as St from './TextArea.styles';
import { ClientTextAreaProps } from './TextArea.types';

export const ClientTextArea: FC<ClientTextAreaProps> = ({
  register,
  icon,
  errorMsg,
  label,
  className,
  gridItem = '',
  ...props
}) => (
  <InputContainer className={className} gridItem={gridItem}>
    <Paragraph className="labelTextArea">{label}</Paragraph>
    <St.InputContentContainer>
      {icon && <Icon icon={icon} size="small" />}
      <St.CustomInput
        {...register}
        {...props}
        autoComplete="off"
        data-cy={`${label?.toLowerCase()}_input`}
        error={errorMsg}
      />
    </St.InputContentContainer>
    {errorMsg && (
      <Paragraph size="small" weight="light" className="errorMsg">
        {errorMsg}
      </Paragraph>
    )}
  </InputContainer>
);
