import styled from 'styled-components';

import { devices } from '@/UI/Styles';

export const StepTrackerContainer = styled.div`
  display: none;
  @media ${devices.XLarge} {
    display: flex;
    justify-content: flex-start;
  }
`;
